Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.charterUserType = "charterer";
exports.formSubmitted = "Your form is submitted";
exports.signUpEndPoint = "account_block/accounts";
exports.loginEndPoint = "bx_block_login/logins";
exports.loginVerifyEndPoint = "bx_block_login/confirmations";
exports.loginSmsVerifyEndPoint = "bx_block_login/sms_confirmations";
exports.getCountryEndPoint = "account_block/accounts/get_country_list";
exports.getTermsAndConditions ="bx_block_privacy_settings/terms_and_conditions";
exports.getPrivacyPolicy ="bx_block_privacy_settings/privacy_policies";
exports.PhoneOtpEndPoint = "account_block/verify_phone";
exports.VerifyPhoneEndPoint = "account_block/accounts/sms_confirmations";
exports.EmailOtpEndPoint = "account_block/verify_email";
exports.VerifyEmailEndPoint = "account_block/accounts/verification";
// Customizable Area End
