import React from "react";

// Customizable Area Start
import { Container, Box, Button, Typography, Radio } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={{ marginTop: '119px' }}>
          <Grid item xs={8} style={{ margin: 'auto' }} className='setBackgroundTribeLogoSelectProfile'>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h4" style={webStyle.titleText}>
                {!this.state.selectMethod
                  ? configJSON.labelTitleTextSignUp
                  : configJSON.labelTitleTextLogin}
              </Typography>
              <Typography variant="h6" style={webStyle.bodyText}>
                {configJSON.labelBodyText}
              </Typography>

              <Box sx={webStyle.selectWrapperbox}>
                <Box sx={webStyle.selectWrapper}>
                  <Box
                    style={
                      this.state.radioButton === "Charterer"
                        ? webStyle.chartererActive
                        : webStyle.charterer
                    }
                    id="radio_btn"
                    onClick={() => this.handleChange("Charterer")}
                  >
                    <Radio
                      checked={this.state.radioButton === "Charterer"}
                      // onChange={this.handleChange}
                      value="Charterer"
                      style={
                        this.state.radioButton === "Charterer"
                          ? webStyle.chartererRadioChecked
                          : webStyle.chartererRadio
                      }
                      name="radio-buttons"
                    />
                    <Typography
                      style={
                        this.state.radioButton === "Charterer"
                          ? webStyle.chartererText
                          : webStyle.chartererTextInactive
                      }
                    >
                      I'm a Charterer
                    </Typography>
                  </Box>

                  <Box
                    style={
                      this.state.radioButton === "Operator"
                        ? webStyle.operatorActive
                        : webStyle.operator
                    }
                    onClick={() => this.handleChange("Operator")}
                  >
                    <Radio
                      checked={this.state.radioButton === "Operator"}
                      style={
                        this.state.radioButton === "Operator"
                          ? webStyle.operatorRadioChecked
                          : webStyle.operatorRadio
                      }
                      value="Operator"
                      name="radio-buttons"
                    />
                    <Typography
                      style={
                        this.state.radioButton === "Operator"
                          ? webStyle.operatorText
                          : webStyle.operatorTextInactive
                      }
                    >
                      I'm an Operator
                    </Typography>
                  </Box>
                </Box>

                <Box component="button" sx={webStyle.buttonStyle}>
                  <Button
                    id="submit_btn"
                    disableRipple={true}
                    disabled={this.state.radioButton === ""}
                    style={
                      this.state.radioButton === ""
                        ? webStyle.btnExampleTitleDisabled
                        : webStyle.btnExampleTitle
                    }
                    onClick={this.handleCreateAccount}
                  >
                    {!this.state.selectMethod ? (
                      <>
                        {configJSON.btnExampleTitleCreate}
                        {this.state.radioButton}
                        {configJSON.btnExampleTitleAccount}
                      </>
                    ) : (
                      <>
                        {configJSON.loginButtonText}
                        {this.state.radioButton !== "" ? "as  " : ""}
                        {this.state.radioButton}
                      </>
                    )}
                  </Button>
                </Box>
              </Box>

              <div style={{ cursor: "pointer" }}>
                <Typography style={webStyle.loginRoutetext}>
                  {this.state.selectMethod
                    ? configJSON.alreadyTextSignup
                    : configJSON.alreadyTextSigniin}
                  <span
                    style={webStyle.loginRoutelogin}
                    onClick={() =>
                      this.setState({ selectMethod: !this.state.selectMethod })
                    }
                  >
                    {this.state.selectMethod
                      ? configJSON.signUpButtonText
                      : configJSON.loginButtonText}
                  </span>
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  }
});

const webStyle: any = {
  mainWrapper: {
    display: "flex",
    // fontFamily: "PlayfairDisplay !important",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 30px",
    margin: "auto",
    color: "#fcf6f0",
    position: 'relative'
  },
  titleText: {
    fontFamily: "Playfair Display, serif",
    paddingTop: "80px",
    fontWeight: "bold",
    color: "#fff !important",
    letterSpacing: " 0.93px !important",
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: "normal !important"
  },
  bodyText: {
    color: "rgb(242, 220, 196)",
    fontFamily: "Libre Franklin, sans-serif",
    fontWeight: 400,
    marginTop: "8px"
  },
  buttonStyle: {
    width: "95%",
    height: "45px",
    marginTop: "32px",
    border: "none",
    backgroundColor: "rgb(242, 220, 196)"
  },
  btnExampleTitle: {
    textTransform: "none",
    color: "#150A1F",
    fontWeight: 600,
    fontFamily: "Libre Franklin, sans-serif",
    width: "100%"
  },
  btnExampleTitleDisabled: {
    textTransform: "none",
    cursor: "not-allowed",
    fontWeight: 600,
    fontFamily: "Libre Franklin, sans-serif"
  },
  selectWrapperbox: {
    display: "flex",
    fontFamily: "PlayfairDisplay !important",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginTop: "72px"
  },
  selectWrapper: {
    display: "flex",
    fontFamily: "PlayfairDisplay !important",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto"
  },

  charterer: {
    height: "150px",
    width: "270px",
    border: "1px solid rgb(242, 220, 196)",
    backgroundColor: "transparent",
    margin: "16px",
    position: "relative",
    textAlign: "center",
    cursor: "pointer"
  },

  chartererActive: {
    height: "150px",
    width: "270px",
    border: "1px solid rgb(242, 220, 196)",
    backgroundColor: "rgb(242, 220, 196)",
    margin: "16px",
    color: "black",
    position: "relative",
    textAlign: "center",
    cursor: "pointer"
  },
  chartererTextInactive: {
    position: "absolute",
    left: "23%",
    top: "40%",
    fontFamily: "Libre Franklin, sans-serif",
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.5782433px",
    color: "#f2dcc4"
  },
  chartererText: {
    position: "absolute",
    left: "23%",
    top: "40%",
    fontFamily: "Libre Franklin, sans-serif",
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.5782433px",
    color: "black"
  },
  operator: {
    height: "150px",
    width: "270px",
    border: "1px solid rgb(242, 220, 196)",
    backgroundColor: "transparent",
    margin: "16px",
    position: "relative",
    cursor: "pointer"
  },
  operatorActive: {
    height: "150px",
    width: "270px",
    border: "1px solid rgb(242, 220, 196)",
    backgroundColor: "rgb(242, 220, 196)",
    margin: "16px",
    color: "black",
    position: "relative",
    textAlign: "center",
    cursor: "pointer"
  },
  operatorTextInactive: {
    position: "absolute",
    left: "23%",
    top: "40%",
    fontFamily: "Libre Franklin, sans-serif",
    fontWeight: 600,
    fontSize: "20px",
    letterSpacing: " 0.5782433px",
    color: "#f2dcc4"
  },
  operatorText: {
    position: "absolute",
    left: "23%",
    top: "40%",
    fontFamily: "Libre Franklin, sans-serif",
    fontWeight: 600,
    fontSize: "20px",
    letterSpacing: " 0.5782433px",
    color: "black"
  },
  loginRoutelogin: {
    color: "rgb(242, 220, 196)",
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "Libre Franklin, sans-serif",
    fontSize: "16px",
    fontWeight: 900,
  },
  loginRoutetext: {
    marginTop: "88px",
    marginBottom: "88px",
  },
  chartererRadio: {
    color: "#F2DCC4",
    position: "absolute",
    right: "0%",
    backgroundColor: 'transparent'
  },
  operatorRadio: {
    color: "#F2DCC4",
    position: "absolute",
    right: "0%",
    backgroundColor: 'transparent'
  },
  chartererRadioChecked: {
    color: "#150A1F",
    position: "absolute",
    right: "0%",
    backgroundColor: 'transparent'
  },
  operatorRadioChecked: {
    color: "#150A1F",
    position: "absolute",
    right: "0%",
    backgroundColor: 'transparent'
  }
};
// Customizable Area End
