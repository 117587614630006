import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { calender_icons, From_icon, To_icon, iconSearch }
  from "./assets";
  import Button from "@material-ui/core/Button";
  import Typography from '@material-ui/core/Typography';
  import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
export default function HomePageoneWayBoxComponent() {
  return (
    <>
      <Grid container >
        <Grid xs={6} sm={6} md={3} lg={3} className="boxTabBlock1">
          <Grid container>
            <Grid lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={From_icon} style={{ height: '20px', width: '20px' }}></img>
            </Grid>
            <Grid lg={10} md={10} sm={10} xs={10}>
              <Box className="homePageboxTabHeading">From</Box>
              <Box className="homePageboxTabText">Enter departure city or Airport</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} md={3} lg={3} className="boxTabBlock1">
          <Grid container>
            <Grid lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={To_icon} style={{ height: '20px', width: '20px' }}></img>
            </Grid>
            <Grid lg={10} md={10} sm={10} xs={10}>
              <Box className="homePageboxTabHeading">To</Box>
              <Box className="homePageboxTabText">Enter destination city or Airport</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} md={3} lg={3} className="boxTabBlock1">
          <Grid container>
            <Grid lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={calender_icons} style={{ height: '20px', width: '20px' }}></img>
            </Grid>

            <Grid lg={10} md={10} sm={10} xs={10}>
              <Box className="homePageboxTabHeading">Departure</Box>
              <Box className="homePageboxTabText">Click to enter departure details</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} sm={6} md={3} lg={3} className="boxTabBlock1">
          <Grid container>
            <Grid lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={calender_icons} style={{ height: '20px', width: '20px' }}></img>
            </Grid>
            <Grid lg={10} md={10} sm={10} xs={10}>
              <Box className="homePageboxTabHeading">Return</Box>
              <Box className="homePageboxTabText">Click to add return flight details</Box>
            </Grid>
          </Grid>
        </Grid>
      <Grid xs={4} sm={4} md={2} lg={2} style={{ margin: 'auto' }}>
          <Button
            variant="contained"
            className="homePageSearchBtn"
            disableRipple
            style={{ marginTop: '40px', position: "relative", bottom: '-25px',minWidth:'200px' }}
          >
            <Box className="getSearchBtnBox">
              <Typography className="getSearchBtnTxt" style={{ fontFamily: 'Playfair Display' }}>
                Search
              </Typography>
              <img
                id="icon"
                src={iconSearch}
                alt="icon"
              />
            </Box>
          </Button>
        </Grid>
      </Grid>

    </>
  );
}
