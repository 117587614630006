import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Blog {
  id: number,
  type: string,
  attributes: {
    content: string,
    title: string,
    image: string
  }  
}


interface S {
  // Customizable Area Start
  radioButton: string;
  blogs : Array<Blog>
  boxTabValue: number;
  blogTitle_1: string;
  blogImage_1: string;
  blogTitle_2: string;
  blogImage_2: string;
  blogTitle_3: string;  
  blogImage_3: string;
  mainTabValue: number;
  blogTabValue: number;
  lightJetFirstImage: number;
  lightJetSecondImage: number;
  lightJetThirdImage: number;
  main_heading_1: string;
  main_heading_2: string;
  main_heading_sub_heading: string;
  explore_heading_1: string;
  explore_heading_2: string;
  explore_heading_sub_heading: string;
  reason_heading_1: string;
  reason_heading_2: string;
  reason_heading_sub_heading: string;
  reason_1_heading: string;
  reason_1_description: string;
  reason_2_heading: string;
  reason_2_description: string;
  reason_3_heading: string;
  reason_3_description: string;
  reason_4_heading: string;
  reason_4_description: string;
  image_heading: string;
  // Tabs state avriables
  tablet_1: string;
  tablet_1_section_1_heading: string;
  tablet_1_section_1_description: string;
  tablet_1_section_1_passengers: string;
  tablet_1_section_1_max_range: string;
  tablet_1_section_1_speed: string;
  tablet_1_section_2_heading: string;
  tablet_1_section_2_description: string;
  tablet_1_section_2_passengers: string;
  tablet_1_section_2_max_range: string;
  tablet_1_section_2_speed: string;  
  tablet_1_section_3_heading: string;
  tablet_1_section_3_description: string;
  tablet_1_section_3_passengers: string;
  tablet_1_section_3_max_range: string;
  tablet_1_section_3_speed: string;

  tablet_2: string;
  tablet_2_section_1_heading: string;
  tablet_2_section_1_description: string;
  tablet_2_section_1_passengers: string;
  tablet_2_section_1_max_range: string;
  tablet_2_section_1_speed: string;
  tablet_2_section_2_heading: string;
  tablet_2_section_2_description: string;
  tablet_2_section_2_passengers: string;
  tablet_2_section_2_max_range: string;
  tablet_2_section_2_speed: string;  
  tablet_2_section_3_heading: string;
  tablet_2_section_3_description: string;
  tablet_2_section_3_passengers: string;
  tablet_2_section_3_max_range: string;
  tablet_2_section_3_speed: string;
  
  tablet_3: string;
  tablet_3_section_1_heading: string;
  tablet_3_section_1_description: string;
  tablet_3_section_1_passengers: string;
  tablet_3_section_1_max_range: string;
  tablet_3_section_1_speed: string;
  tablet_3_section_2_heading: string;
  tablet_3_section_2_description: string;
  tablet_3_section_2_passengers: string;
  tablet_3_section_2_max_range: string;
  tablet_3_section_2_speed: string;  
  tablet_3_section_3_heading: string;
  tablet_3_section_3_description: string;
  tablet_3_section_3_passengers: string;
  tablet_3_section_3_max_range: string;
  tablet_3_section_3_speed: string;

  tablet_4: string;
  tablet_4_section_1_heading: string;
  tablet_4_section_1_description: string;
  tablet_4_section_1_passengers: string;
  tablet_4_section_1_max_range: string;
  tablet_4_section_1_speed: string;
  tablet_4_section_2_heading: string;
  tablet_4_section_2_description: string;
  tablet_4_section_2_passengers: string;
  tablet_4_section_2_max_range: string;
  tablet_4_section_2_speed: string;  
  tablet_4_section_3_heading: string;
  tablet_4_section_3_description: string;
  tablet_4_section_3_passengers: string;
  tablet_4_section_3_max_range: string;
  tablet_4_section_3_speed: string;
  
  tablet_5: string;
  tablet_5_section_1_heading: string;
  tablet_5_section_1_description: string;
  tablet_5_section_1_passengers: string;
  tablet_5_section_1_max_range: string;
  tablet_5_section_1_speed: string;
  tablet_5_section_2_heading: string;
  tablet_5_section_2_description: string;
  tablet_5_section_2_passengers: string;
  tablet_5_section_2_max_range: string;
  tablet_5_section_2_speed: string;  
  tablet_5_section_3_heading: string;
  tablet_5_section_3_description: string;
  tablet_5_section_3_passengers: string;
  tablet_5_section_3_max_range: string;
  tablet_5_section_3_speed: string;

  tablet_6: string;
  tablet_6_section_1_heading: string;
  tablet_6_section_1_description: string;
  tablet_6_section_1_passengers: string;
  tablet_6_section_1_max_range: string;
  tablet_6_section_1_speed: string;
  tablet_6_section_2_heading: string;
  tablet_6_section_2_description: string;
  tablet_6_section_2_passengers: string;
  tablet_6_section_2_max_range: string;
  tablet_6_section_2_speed: string;  
  tablet_6_section_3_heading: string;
  tablet_6_section_3_description: string;
  tablet_6_section_3_passengers: string;
  tablet_6_section_3_max_range: string;
  tablet_6_section_3_speed: string;
  
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomeScreenChatererController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  apiChartereHomeScreen : string = "";
  apiChartererHomeTablets : string = "";
  apiBlogsHomeScreen : string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      radioButton: "",
      blogs: [],
      boxTabValue: 0,
      blogTitle_1: "",
      blogImage_1: "",
      blogTitle_2: "",
      blogImage_2: "",
      blogTitle_3: "",
      blogImage_3: "",
      mainTabValue: 0,
      blogTabValue: 0,
      lightJetFirstImage: 0,
      lightJetSecondImage: 0,
      lightJetThirdImage: 0,
      main_heading_1: "",
      main_heading_2: "",
      main_heading_sub_heading: "",
      explore_heading_1: "",
      explore_heading_2: "",
      explore_heading_sub_heading: "",
      reason_heading_1: "",
      reason_heading_2: "",
      reason_heading_sub_heading: "",
      reason_1_heading: "",
      reason_1_description: "",
      reason_2_heading: "",
      reason_2_description: "",
      reason_3_heading: "",
      reason_3_description: "",
      reason_4_heading: "",
      reason_4_description: "",
      image_heading: "",

      tablet_1: "",
      tablet_1_section_1_heading: "",
      tablet_1_section_1_description: "",
      tablet_1_section_1_passengers: "",
      tablet_1_section_1_max_range: "",
      tablet_1_section_1_speed: "",
      tablet_1_section_2_heading: "",
      tablet_1_section_2_description: "",
      tablet_1_section_2_passengers: "",
      tablet_1_section_2_max_range: "",
      tablet_1_section_2_speed: "",  
      tablet_1_section_3_heading: "",
      tablet_1_section_3_description: "",
      tablet_1_section_3_passengers: "",
      tablet_1_section_3_max_range: "",
      tablet_1_section_3_speed: "",

      tablet_2: "",
      tablet_2_section_1_heading: "",
      tablet_2_section_1_description: "",
      tablet_2_section_1_passengers: "",
      tablet_2_section_1_max_range: "",
      tablet_2_section_1_speed: "",
      tablet_2_section_2_heading: "",
      tablet_2_section_2_description: "",
      tablet_2_section_2_passengers: "",
      tablet_2_section_2_max_range: "",
      tablet_2_section_2_speed: "",  
      tablet_2_section_3_heading: "",
      tablet_2_section_3_description: "",
      tablet_2_section_3_passengers: "",
      tablet_2_section_3_max_range: "",
      tablet_2_section_3_speed: "",
      
      tablet_3: "",
      tablet_3_section_1_heading: "",
      tablet_3_section_1_description: "",
      tablet_3_section_1_passengers: "",
      tablet_3_section_1_max_range: "",
      tablet_3_section_1_speed: "",
      tablet_3_section_2_heading: "",
      tablet_3_section_2_description: "",
      tablet_3_section_2_passengers: "",
      tablet_3_section_2_max_range: "",
      tablet_3_section_2_speed: "",  
      tablet_3_section_3_heading: "",
      tablet_3_section_3_description: "",
      tablet_3_section_3_passengers: "",
      tablet_3_section_3_max_range: "",
      tablet_3_section_3_speed: "",

      tablet_4: "",
      tablet_4_section_1_heading: "",
      tablet_4_section_1_description: "",
      tablet_4_section_1_passengers: "",
      tablet_4_section_1_max_range: "",
      tablet_4_section_1_speed: "",
      tablet_4_section_2_heading: "",
      tablet_4_section_2_description: "",
      tablet_4_section_2_passengers: "",
      tablet_4_section_2_max_range: "",
      tablet_4_section_2_speed: "",  
      tablet_4_section_3_heading: "",
      tablet_4_section_3_description: "",
      tablet_4_section_3_passengers: "",
      tablet_4_section_3_max_range: "",
      tablet_4_section_3_speed: "",
      
      tablet_5: "",
      tablet_5_section_1_heading: "",
      tablet_5_section_1_description: "",
      tablet_5_section_1_passengers: "",
      tablet_5_section_1_max_range: "",
      tablet_5_section_1_speed: "",
      tablet_5_section_2_heading: "",
      tablet_5_section_2_description: "",
      tablet_5_section_2_passengers: "",
      tablet_5_section_2_max_range: "",
      tablet_5_section_2_speed: "",  
      tablet_5_section_3_heading: "",
      tablet_5_section_3_description: "",
      tablet_5_section_3_passengers: "",
      tablet_5_section_3_max_range: "",
      tablet_5_section_3_speed: "",

      tablet_6: "",
      tablet_6_section_1_heading: "",
      tablet_6_section_1_description: "",
      tablet_6_section_1_passengers: "",
      tablet_6_section_1_max_range: "",
      tablet_6_section_1_speed: "",
      tablet_6_section_2_heading: "",
      tablet_6_section_2_description: "",
      tablet_6_section_2_passengers: "",
      tablet_6_section_2_max_range: "",
      tablet_6_section_2_speed: "",  
      tablet_6_section_3_heading: "",
      tablet_6_section_3_description: "",
      tablet_6_section_3_passengers: "",
      tablet_6_section_3_max_range: "",
      tablet_6_section_3_speed: ""
    };

    this.getChartererHomeScreenData = this.getChartererHomeScreenData.bind(this)
    this.getChartererHomeScreenTabletData = this.getChartererHomeScreenTabletData.bind(this)
    this.getBlogsInfo = this.getBlogsInfo.bind(this)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);

      if (apiRequestCallId === this.apiChartereHomeScreen) {        
        if (responseJson && responseJson.data) {
          const json_data = responseJson.data;
          for(let i = 0; i < json_data.length; i ++ ){
            if (json_data[i].id === "1"){
              this.setState({
                main_heading_1: json_data[i].attributes.title,
                main_heading_2: json_data[i].attributes.body,
                main_heading_sub_heading: json_data[i].attributes.description
              })
            }
            if (json_data[i].id === "2"){
              this.setState({
                explore_heading_1: json_data[i].attributes.title,
                explore_heading_2: json_data[i].attributes.body,
                explore_heading_sub_heading: json_data[i].attributes.description
              })
            }
            if (json_data[i].id === "3"){
              this.setState({
                reason_heading_1: json_data[i].attributes.title,
                reason_heading_2: json_data[i].attributes.body,
                reason_heading_sub_heading: json_data[i].attributes.description
              })
            }
            if (json_data[i].id === "4"){
              this.setState({
                reason_1_heading: json_data[i].attributes.title,
                reason_1_description: json_data[i].attributes.body                
              })
            }
            if (json_data[i].id === "5"){
              this.setState({
                reason_2_heading: json_data[i].attributes.title,
                reason_2_description: json_data[i].attributes.body                
              })
            }
            if (json_data[i].id === "6"){
              this.setState({
                reason_3_heading: json_data[i].attributes.title,
                reason_3_description: json_data[i].attributes.body                
              })
            }
            if (json_data[i].id === "7"){
              this.setState({
                reason_4_heading: json_data[i].attributes.title,
                reason_4_description: json_data[i].attributes.body                
              })
            }

            if (json_data[i].id === "8"){
              this.setState({
                image_heading: json_data[i].attributes.title
              })
            }
          }     
        } else {
          alert("Something went wrong")
        }
      }

      if (apiRequestCallId === this.apiChartererHomeTablets) {        
        if (responseJson && responseJson.data) {
          const json_data = responseJson.data;          
          
          for(let i = 0; i < json_data.length; i ++ ){

            if (json_data[i].id === "1"){
              this.setState({
                tablet_1: json_data[i].attributes.title_name,                
              })

              let sections = json_data[i].attributes.sections;

              for (let i = 0; i <= sections.length; i ++){
                this.setState({
                  tablet_1_section_1_heading: sections[0].name,
                  tablet_1_section_1_description: sections[0].description,
                  tablet_1_section_1_passengers: sections[0].passengers,
                  tablet_1_section_1_max_range: sections[0].max_range,
                  tablet_1_section_1_speed: sections[0].high_cruise_speed,

                  tablet_1_section_2_heading: sections[1].name,
                  tablet_1_section_2_description: sections[1].description,
                  tablet_1_section_2_passengers: sections[1].passengers,
                  tablet_1_section_2_max_range: sections[1].max_range,
                  tablet_1_section_2_speed: sections[1].high_cruise_speed,

                  tablet_1_section_3_heading: sections[2].name,
                  tablet_1_section_3_description: sections[2].description,
                  tablet_1_section_3_passengers: sections[2].passengers,
                  tablet_1_section_3_max_range: sections[2].max_range,
                  tablet_1_section_3_speed: sections[2].high_cruise_speed
                })
              }
              
            }


            if (json_data[i].id === "2"){
              this.setState({
                tablet_1: json_data[i].attributes.title_name,                
              })

              let sections = json_data[i].attributes.sections;

              for (let i = 0; i <= sections.length; i ++){
                this.setState({
                  tablet_2_section_1_heading: sections[0].name,
                  tablet_2_section_1_description: sections[0].description,
                  tablet_2_section_1_passengers: sections[0].passengers,
                  tablet_2_section_1_max_range: sections[0].max_range,
                  tablet_2_section_1_speed: sections[0].high_cruise_speed,

                  tablet_2_section_2_heading: sections[1].name,
                  tablet_2_section_2_description: sections[1].description,
                  tablet_2_section_2_passengers: sections[1].passengers,
                  tablet_2_section_2_max_range: sections[1].max_range,
                  tablet_2_section_2_speed: sections[1].high_cruise_speed,

                  tablet_2_section_3_heading: sections[2].name,
                  tablet_2_section_3_description: sections[2].description,
                  tablet_2_section_3_passengers: sections[2].passengers,
                  tablet_2_section_3_max_range: sections[2].max_range,
                  tablet_2_section_3_speed: sections[2].high_cruise_speed
                })
              }
              
            }


            if (json_data[i].id === "3"){
              this.setState({
                tablet_1: json_data[i].attributes.title_name,                
              })

              let sections = json_data[i].attributes.sections;

              for (let i = 0; i <= sections.length; i ++){
                this.setState({
                  tablet_3_section_1_heading: sections[0].name,
                  tablet_3_section_1_description: sections[0].description,
                  tablet_3_section_1_passengers: sections[0].passengers,
                  tablet_3_section_1_max_range: sections[0].max_range,
                  tablet_3_section_1_speed: sections[0].high_cruise_speed,

                  tablet_3_section_2_heading: sections[1].name,
                  tablet_3_section_2_description: sections[1].description,
                  tablet_3_section_2_passengers: sections[1].passengers,
                  tablet_3_section_2_max_range: sections[1].max_range,
                  tablet_3_section_2_speed: sections[1].high_cruise_speed,

                  tablet_3_section_3_heading: sections[2].name,
                  tablet_3_section_3_description: sections[2].description,
                  tablet_3_section_3_passengers: sections[2].passengers,
                  tablet_3_section_3_max_range: sections[2].max_range,
                  tablet_3_section_3_speed: sections[2].high_cruise_speed
                })
              }              
            }


            if (json_data[i].id === "4"){
              this.setState({
                tablet_1: json_data[i].attributes.title_name,                
              })

              let sections = json_data[i].attributes.sections;

              for (let i = 0; i <= sections.length; i ++){
                this.setState({
                  tablet_4_section_1_heading: sections[0].name,
                  tablet_4_section_1_description: sections[0].description,
                  tablet_4_section_1_passengers: sections[0].passengers,
                  tablet_4_section_1_max_range: sections[0].max_range,
                  tablet_4_section_1_speed: sections[0].high_cruise_speed,

                  tablet_4_section_2_heading: sections[1].name,
                  tablet_4_section_2_description: sections[1].description,
                  tablet_4_section_2_passengers: sections[1].passengers,
                  tablet_4_section_2_max_range: sections[1].max_range,
                  tablet_4_section_2_speed: sections[1].high_cruise_speed,

                  tablet_4_section_3_heading: sections[2].name,
                  tablet_4_section_3_description: sections[2].description,
                  tablet_4_section_3_passengers: sections[2].passengers,
                  tablet_4_section_3_max_range: sections[2].max_range,
                  tablet_4_section_3_speed: sections[2].high_cruise_speed
                })
              }              
            }


            if (json_data[i].id === "5"){
              this.setState({
                tablet_1: json_data[i].attributes.title_name,                
              })

              let sections = json_data[i].attributes.sections;

              for (let i = 0; i <= sections.length; i ++){
                this.setState({
                  tablet_5_section_1_heading: sections[0].name,
                  tablet_5_section_1_description: sections[0].description,
                  tablet_5_section_1_passengers: sections[0].passengers,
                  tablet_5_section_1_max_range: sections[0].max_range,
                  tablet_5_section_1_speed: sections[0].high_cruise_speed,

                  tablet_5_section_2_heading: sections[1].name,
                  tablet_5_section_2_description: sections[1].description,
                  tablet_5_section_2_passengers: sections[1].passengers,
                  tablet_5_section_2_max_range: sections[1].max_range,
                  tablet_5_section_2_speed: sections[1].high_cruise_speed,

                  tablet_5_section_3_heading: sections[2].name,
                  tablet_5_section_3_description: sections[2].description,
                  tablet_5_section_3_passengers: sections[2].passengers,
                  tablet_5_section_3_max_range: sections[2].max_range,
                  tablet_5_section_3_speed: sections[2].high_cruise_speed
                })
              }              
            }


            if (json_data[i].id === "6"){
              this.setState({
                tablet_1: json_data[i].attributes.title_name,                
              })

              let sections = json_data[i].attributes.sections;

              for (let i = 0; i <= sections.length; i ++){
                this.setState({
                  tablet_6_section_1_heading: sections[0].name,
                  tablet_6_section_1_description: sections[0].description,
                  tablet_6_section_1_passengers: sections[0].passengers,
                  tablet_6_section_1_max_range: sections[0].max_range,
                  tablet_6_section_1_speed: sections[0].high_cruise_speed,

                  tablet_6_section_2_heading: sections[1].name,
                  tablet_6_section_2_description: sections[1].description,
                  tablet_6_section_2_passengers: sections[1].passengers,
                  tablet_6_section_2_max_range: sections[1].max_range,
                  tablet_6_section_2_speed: sections[1].high_cruise_speed,

                  tablet_6_section_3_heading: sections[2].name,
                  tablet_6_section_3_description: sections[2].description,
                  tablet_6_section_3_passengers: sections[2].passengers,
                  tablet_6_section_3_max_range: sections[2].max_range,
                  tablet_6_section_3_speed: sections[2].high_cruise_speed
                })
              }              
            }

          }
               
        } else {
          alert("Something went wrong")
        }        

      }

      if (apiRequestCallId === this.apiBlogsHomeScreen) {
        if (responseJson && responseJson.data) {
          const json_data = responseJson.data;
          const slicedArray = json_data.slice(0, 3);
          this.setState(
            {
              blogs : slicedArray
            }
          )
          try {
            this.setState({
              blogTitle_1: slicedArray[0].attributes.title,
              blogImage_1: slicedArray[0].attributes.image
            })
            this.setState({
              blogTitle_2: slicedArray[1].attributes.title,
              blogImage_2: slicedArray[1].attributes.image
            })
            this.setState({
              blogTitle_3: slicedArray[2].attributes.title,
              blogImage_3: slicedArray[2].attributes.image
            })
          } catch (error) {
            
          }
        }
      } else {
        console.log("Blog API is onot working");
      }


    }    
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    
    this.getChartererHomeScreenData();
    this.getChartererHomeScreenTabletData();
    this.getBlogsInfo();
  };

  handleChangeBoxTab = (val: any) => {
    this.setState({
      boxTabValue: val
    })
  };

  handleChangeMainTab = (val: any) => {
    this.setState({
      mainTabValue: val
    })
  };


  getChartererHomeScreenData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChartereHomeScreen = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homeCharterer
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  getChartererHomeScreenTabletData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChartererHomeTablets = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homeChartererTablets
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBlogsInfo = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBlogsHomeScreen = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homeChartererBlogs
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}