// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

// export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  radioButton: string;
  authToken: any;
  aboutData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutUsController extends BlockComponent<
  Props,
  S,
  SS
> {

  aboutScreenApiCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      radioButton: "",
      authToken: '',
      aboutData: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.aboutScreenApiCallId) {
          console.log('__props', responseJson.data)
          if (responseJson.data) {
            return this.setState({ aboutData: responseJson?.data[0]?.attributes })
          }
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let localtoken = localStorage.getItem('authToken')
    if (localtoken) {
      this.setState({ authToken: localtoken })
    }
    this.getAboutDataLoadApi();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ radioButton: event.target.value });
  };

  handleCreateAccount = () => {
    if (this.state.radioButton === "charterer") {
      this.goToCharterSignup();
    } else {
      this.goToOperatorSignup();
    }
  };

  goToCharterSignup() {
    // on click of charterer login this function to execute
    alert("no page defined");
  }

  goToOperatorSignup() {
    // on click of operator login this function to execute
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OperatorReservationForm"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getAboutDataLoadApi = () => {

    const header = {
      "Content-Type": 'application/json'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.aboutScreenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_contact_us/informations'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}

// Customizable Area End