// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export interface Props {
  navigation?: any;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataSource: any[];
  selectedCountry: any;
  isOpen: boolean;
  mobileNo: string;
  token: string;
  label: any;
  timeLeft: any;
  snackbarMes: string;
  severity: string;
  otpReceived: number;
  otpReceivedPhone: number;
  mobiletoken: string;
  country: any;
  successOpen: boolean;
  first_name: string;
  emailId: string;
  last_name_error: boolean;
  last_name_errorMes: any;
  emailIdError: boolean;
  emailIdErrorMes: any;
  full_phone_numberError: boolean;
  full_phone_numbererrorMes: any;
  first_name_error: boolean;
  first_name_errorMes: any;
  last_name: string;
  selectValue: string;
  email: any;
  open: boolean;
  privacyOpen: boolean;
  privacyError: boolean;
  privacyChecked: boolean;
  privacyErrorMes: any;
  full_phone_number: string;
  terms_and_conditions: boolean;
  formNo: string;
  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  cursorPointer: any;
  loadingSpinner: boolean;
  otperror: boolean;
  otperrorText: string;
  termsOpen: boolean;
  mail: string;
  number: string;
  terms_and_condition: string;
  privacy_policy: string;
  otpError: boolean;
  resendEnabled: boolean;
  otpErrorMes: any;
  termsCheckBox: boolean;
  tempTermsCheckBox: boolean;
  privacyCheckBox: boolean;
  tempPrivacyCheckBox: boolean;
  isSubmit: boolean;
  otpVerifyDisabled: boolean;
  resendCodeSended:boolean
  // Customizable Area End
}

interface SS { }

export const configJSON = require("./../config.js");
const emailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export default class ChartererReservationFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCharterSignUpCallId: string = "";
  apiCountryCallId: string = "";
  apiTermsAndConditionsCallId: string = "";
  apiPrivacyPolicyCallId: string = "";
  countryCodeApiCallId: string = "";
  apiPhoneOtpCallId: string = "";
  apiEmailCallId: string = "";
  apiVerifyOTPCallId: string = "";
  intervalRef: any;
  apiPhoneNumberAlready: string =
    "Phone number already exists, Please try to sign up using different phone number.";
  apiEmailAlready: string =
    "Email id already exists, Please try to sign up using different email id.";
  apiPhoneandEmailAlready: string = "Your account is already created, Please login."
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isOpen: false,
      dataSource: [],
      selectedCountry: 0,
      mobileNo: "",
      token: "",
      label: null,
      first_name: "",
      last_name: "",
      email: "",
      full_phone_number: "",
      selectValue: "",
      timeLeft: 30,
      resendEnabled: true,
      severity: "",
      snackbarMes: "",
      otpError: false,
      otperrorText: "",
      otpErrorMes: "",
      mobiletoken: "",
      otpReceived: 0,
      otpReceivedPhone: 0,
      country: "",
      successOpen: false,
      emailId: "",
      last_name_error: false,
      last_name_errorMes: "",
      emailIdError: false,
      emailIdErrorMes: "",
      full_phone_numberError: false,
      full_phone_numbererrorMes: "",
      first_name_error: false,
      first_name_errorMes: "",
      open: false,
      privacyOpen: false,
      privacyError: false,
      privacyChecked: false,
      privacyErrorMes: "",
      terms_and_conditions: false,
      termsCheckBox: false,
      privacyCheckBox: false,
      formNo: "0",
      otp_code: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      cursorPointer: 0,
      loadingSpinner: false,
      otperror: false,
      termsOpen: false,
      mail: "",
      number: "",
      terms_and_condition: "",
      privacy_policy: "",
      tempTermsCheckBox: false,
      tempPrivacyCheckBox: false,
      isSubmit: false,
      otpVerifyDisabled: false,
      resendCodeSended:false
    };
    this.intervalRef = React.createRef();
    this.getTermsAndConditions = this.getTermsAndConditions.bind(this);
    this.getPrivacyPoliciy = this.getPrivacyPoliciy.bind(this);
    this.getCountryCode = this.getCountryCode.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);
      if (apiRequestCallId === this.countryCodeApiCallId) {
        if (responseJson) {
          this.setState({
            dataSource: this.countryCodesToDropDown(responseJson),
            selectedCountry: this.countryCodesToDropDown(responseJson)[0]
          });
        }
      }
      else if (apiRequestCallId === this.apiCharterSignUpCallId) {
        if (responseJson.data) {
          this.setState(
            {
              otpReceived: responseJson.data.attributes.pin,
              token: responseJson.meta.token,
              isSubmit: false
            },
            () => {
              this.handleFormChange("1");
            }
          );
        }
        if (responseJson.message === this.apiPhoneNumberAlready) {
          this.setState({
            full_phone_numberError: true,
            full_phone_numbererrorMes: "Contact number already exists",
            isSubmit: false
          });
        }
        if (responseJson.message === this.apiEmailAlready) {
          this.setState({
            emailIdError: true,
            emailIdErrorMes: "Email already exists",
            isSubmit: false
          });
        }
        if (responseJson.message === this.apiPhoneandEmailAlready) {
          this.setState({
            emailIdError: true,
            emailIdErrorMes: "Email already exists",
            full_phone_numberError: true,
            full_phone_numbererrorMes: "Contact number already exists",
            isSubmit: false
          });
        }
      }
      else if (apiRequestCallId === this.apiTermsAndConditionsCallId) {
        this.setState({
          terms_and_condition: responseJson.data.attributes.description
        });
      }
      else if (apiRequestCallId === this.apiPrivacyPolicyCallId) {
        this.setState({
          privacy_policy: responseJson.data.attributes.description
        });
      }
      else if (apiRequestCallId === this.apiVerifyOTPCallId) {
        this.setState({
          successOpen: true,
        });
        setTimeout(
          function() {
            window.location.replace('/HomeScreenCharterer')
          }
          .bind(this),
          3000
        );
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: (
        <div style={{ alignItems: 'center', display: 'flex' }}>
          &nbsp; &nbsp;
          <img
            src={`https://flagcdn.com/${item.country_ISO_code.toLowerCase()}.svg`}
            width="24"
            height='16'
            alt="flag"
          />
          &nbsp;
          <span>+{item.country_code}</span>
        </div>
      ),
      value: item.country_code
    }));
  };
  handleResend = (e: any) => {
    this.setState({
      resendCodeSended:true
    })
    this.handleSubmit(e);
    this.handleCountDown();
  };
  handleChange = (item: any) => {
    this.setState({ label: item.label });
    this.setState({ selectedCountry: item });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleContactNumberDropdownOpen = () => {
    this.setState({
      isOpen: true
    });
  };
  handleContactNumberDropdownClose = () => {
    this.setState({
      isOpen: false
    });
  };
  termsAndConditionsDialogCheckBoxOnChangeHandler = () => {
    this.setState({
      termsCheckBox: !this.state.termsCheckBox,

    });
  };
  privacyDialogCheckBoxOnChangeHandler = () => {
    this.setState({
      privacyCheckBox: !this.state.privacyCheckBox,
    });
  }
  privacyDialogAcceptBtnHandler = () => {
    this.setState({
      privacyOpen: false,
      privacyError: false,
      privacyErrorMes: "",
      termsOpen: false,
      tempPrivacyCheckBox: this.state.privacyCheckBox
    });
    if (this.state.tempTermsCheckBox && this.state.privacyCheckBox) {
      this.setState({
        terms_and_conditions: true
      })
    }
    else {
      this.setState({
        terms_and_conditions: false
      })
    }
  };
  privacyDialogDeclineBtnHandler = () => {
    this.setState({
      tempPrivacyCheckBox: this.state.tempPrivacyCheckBox,
      privacyOpen: false,
      termsOpen: false,
      privacyError: true,
      privacyErrorMes:
        "Please accept the Tribe's terms of use and privacy policy before continuing"
    });
  };
  termsAndConditionsDialogAcceptBtnHandler = () => {
    this.setState({
      privacyOpen: false,
      privacyError: false,
      privacyErrorMes: "",
      termsOpen: false,
      tempTermsCheckBox: this.state.termsCheckBox
    });
    if (this.state.termsCheckBox && this.state.tempPrivacyCheckBox) {
      this.setState({
        terms_and_conditions: true
      })
    }
    else {
      this.setState({
        terms_and_conditions: false
      })
    }
  };
  termsAndConditionsDialogDeclineBtnHandler = () => {
    this.setState({
      tempTermsCheckBox: this.state.tempTermsCheckBox,
      privacyOpen: false,
      termsOpen: false,
      privacyError: true,
      privacyErrorMes: "Please accept the Tribe's terms of use and privacy policy before continuing"
    });
  };
  countrycodeChangeHandler = (e: any) => {
    this.handleChange(e);
    this.setState({
      isOpen: false
    });
  };
  async componentDidMount() {
    this.getTermsAndConditions();
    this.getPrivacyPoliciy();
    this.getCountryCode();
  }
  getTermsAndConditions = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTermsAndConditionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPrivacyPoliciy = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPrivacyPolicyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicy
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  firstNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.length === 0) {
      this.setState({
        first_name_error: true,
        first_name_errorMes: "First name is required",
        first_name: ""
      });
    } else {
      this.setState({
        first_name: e.target.value,
        first_name_error: false,
        first_name_errorMes: ""
      });
    }
  };
  lastNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.length === 0) {
      this.setState({
        last_name_error: true,
        last_name_errorMes: "Last name is required",
        last_name: ""
      });
    } else {
      this.setState({
        last_name: e.target.value,
        last_name_error: false,
        last_name_errorMes: ""
      });
    }
  };
  phoneFieldOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        full_phone_numberError: true,
        full_phone_numbererrorMes: "Contact number is required",
        full_phone_number: ""
      });
    } else if (value.match(/^\d{10,18}$/)) {
      this.setState({
        full_phone_numberError: false,
        full_phone_numbererrorMes: "",
        full_phone_number: value
      });
    } else {
      this.setState({
        full_phone_numberError: true,
        full_phone_numbererrorMes: "Enter a valid Contact number",
        full_phone_number: value
      });
    }
  };
  phoneFieldOnBlur = () => {
    const phoneReg = /^\d{10,18}$/;
    if (
      this.state.full_phone_number === null ||
      this.state.full_phone_number.length === 0
    ) {
      this.setState({
        full_phone_numberError: true,
        full_phone_numbererrorMes: "Contact number is required"
      });
    } else if (!phoneReg.test(this.state.full_phone_number)) {
      this.setState({
        full_phone_numberError: true,
        full_phone_numbererrorMes: "Enter a valid Contact number"
      });
    } else {
      this.setState({
        full_phone_numberError: false,
        full_phone_numbererrorMes: ""
      });
    }
  };

  emailFieldOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Email address is required",
        email: ""
      });
    } else if (!emailReg.test(value)) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Enter a valid Email",
        email: event.target.value.trim()
      });
    } else if (emailReg.test(value)) {
      this.setState({
        emailIdError: false,
        emailIdErrorMes: "",
        email: event.target.value.trim()
      });
    }
  };
  emailFieldOnBlur = () => {
    if (this.state.email === null || this.state.email.length === 0) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Email address is required"
      });
    } else if (!emailReg.test(this.state.email)) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Enter a valid Email"
      });
    } else {
      this.setState({
        emailIdError: false,
        emailIdErrorMes: ""
      });
    }
  };

  validation = () => {
    const phoneReg = /^\d{10,18}$/;
    let allow: boolean = true;
    const {
      email,
      last_name,
      first_name,
      terms_and_conditions,
      full_phone_number
    } = this.state;
    if (
      terms_and_conditions === false ||
      email.length === 0 ||
      last_name.length === 0 ||
      first_name.length === 0 ||
      full_phone_number.length === 0
    ) {
      if (this.state.email.length === 0) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: "Email address is required"
        });
      }
      if (!emailReg.test(email)) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: " Enter a valid Company Email."
        });
      }
      if (this.state.last_name === "" || this.state.last_name.length === 0) {
        this.setState({
          last_name_error: true,
          last_name_errorMes: "Last name is required"
        });
      }
      if (this.state.first_name === "" || this.state.first_name.length === 0) {
        this.setState({
          first_name_error: true,
          first_name_errorMes: "First name is required"
        });
      }
      if (this.state.full_phone_number === "") {
        this.setState({
          full_phone_numberError: true,
          full_phone_numbererrorMes: "Contact number is required"
        });
      }
      if (!phoneReg.test(full_phone_number)) {
        this.setState({
          full_phone_numberError: true,
          full_phone_numbererrorMes: "Contact number is not valid."
        });
      }
      if (this.state.terms_and_conditions === false) {
        this.setState({
          privacyError: true,
          privacyErrorMes:
            "Please accept the Tribe's terms of use and privacy policy before continuing"
        });
      }
      allow = false;
    }
    return allow;
  };

  //```````````````API calling start for Signup `````````````````````````//

  handleVerification = async (e: any) => {
    e.preventDefault();
    const { otp1, otp2, otp3, otp4, otpReceived } = this.state;
    const pin = otp1 + otp2 + otp3 + otp4;
    if (otpReceived.toString() === pin) {
      this.handleOTPSubmit(e);
    } else {
      this.setState({
        otpErrorMes: "Uh-oh! You seem to have entered an invalid OTP",
        otpError: true
      });
    }
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    let allow: boolean = true;
    event.preventDefault();
    if (!this.state.resendEnabled) {
      return;
    }
    allow = await this.validation();
    if (allow) {
      this.setState({ isSubmit: true })
      const header = {
        "Content-Type": configJSON.validationApiContentType
      };
      let data = {
        data: {
          attributes: {
            user_type: configJSON.charterUserType,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            full_phone_number:
              this.state.selectedCountry.value + this.state.full_phone_number
          }
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiCharterSignUpCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.signUpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sellerDetailsAPIMethodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleOTPSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { otp1, otp2, otp3, otp4, token } = this.state;
    const pin = otp1 + otp2 + otp3 + otp4;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.VerifyEmailEndPoint + `?pin=${+pin}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleTermsOpen = () => {
    this.setState({
      termsCheckBox: this.state.tempTermsCheckBox,
      termsOpen: !this.state.termsOpen
    });
  };

  handleModalcheck = () => {
    this.setState({
      successOpen: false,
      formNo: "0",
      first_name: "",
      last_name: "",
      full_phone_number: "",
      email: "",
      terms_and_conditions: false,
      selectedCountry: 0
    });
  };

  handleSignupFormChanger = () => {
    this.setState({ formNo: "0" });
  };

  handlePrivacyOpen = () => {
    this.setState({
      privacyCheckBox: this.state.tempPrivacyCheckBox,
      privacyOpen: !this.state.privacyOpen
    });
  };

  handleTermsChecker = () => {
    if (this.state.terms_and_conditions) {
      this.setState({
        terms_and_conditions: !this.state.terms_and_conditions,
        tempTermsCheckBox: false,
        tempPrivacyCheckBox: false,
        privacyError: true,
        privacyErrorMes:
          "Please accept the Tribe's terms of use and privacy policy before continuing"
      });
    } else {
      this.setState({
        terms_and_conditions: !this.state.terms_and_conditions,
        privacyError: false,
        privacyErrorMes: "",
        tempTermsCheckBox: true,
        tempPrivacyCheckBox: true,
      });
    }
  };

  handleOTPInputChekcer = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 1);
  };

  handlePaster = (e: any) => {
    const otp = e.clipboardData.getData("Text");
    this.setState({
      otp1: otp[0],
      otp2: otp[1],
      otp3: otp[2],
      otp4: otp[3]
    });
  };

  handleOTPFields1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp2 !== "" &&
        this.state.otp3 !== "" &&
        this.state.otp4 !== ""
      ) {
        this.setState({
          otp1: event.target.value,
          otperror: false,
          otpVerifyDisabled: false
        });
      } else {
        this.setState({
          otp1: event.target.value,
          otpVerifyDisabled: true
        });
      }

      if (event.target.value.length === 1) {
        const nextfield = document.querySelector(`input[name=otp2]`);
        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    } else {
      this.setState({
        otp1: "",
        otpVerifyDisabled: true
      });
    }
  };
  handleOTPfields2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp1 !== "" &&
        this.state.otp3 !== "" &&
        this.state.otp4 !== ""
      ) {
        this.setState({
          otp2: event.target.value,
          otperror: false,
          otpVerifyDisabled: false
        });
      } else {
        this.setState({
          otp2: event.target.value,
          otpVerifyDisabled: true
        });
      }

      if (event.target.value.length === 1) {
        const nextfield = document.querySelector(`input[name=otp3]`);
        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    } else {
      this.setState({
        otp2: "",
        otpVerifyDisabled: true
      });
      const prevfield = document.querySelector(`input[name=otp_code]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  handleOTPfields3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp2 !== "" &&
        this.state.otp1 !== "" &&
        this.state.otp4 !== ""
      ) {
        this.setState({
          otp3: event.target.value,
          otperror: false,
          otpVerifyDisabled: false
        });
      } else {
        this.setState({
          otp3: event.target.value,
          otpVerifyDisabled: true
        });
      }

      if (event.target.value.length === 1) {
        const nextfield = document.querySelector(`input[name=otp4]`);
        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    } else {
      this.setState({
        otp3: "",
        otpVerifyDisabled: true
      });
      const prevfield = document.querySelector(`input[name=otp2]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  handleOTPfields4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp2 !== "" &&
        this.state.otp3 !== "" &&
        this.state.otp1 !== ""
      ) {
        this.setState({
          otp4: event.target.value,
          otperror: false,
          otpVerifyDisabled: false
        });
      } else {
        this.setState({
          otp4: event.target.value,
          otpVerifyDisabled: true
        });
      }
    } else {
      this.setState({
        otp4: "",
        otpVerifyDisabled: true
      });
      const prevfield = document.querySelector(`input[name=otp3]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  stopTimer = () => {
    clearInterval(this.intervalRef.current);
    this.setState({
      timeLeft: 30,
      resendEnabled: true
    });
  };
  handleCountDown = () => {
    this.intervalRef.current = setInterval(() => {
      if (this.state.timeLeft > 0) {
        this.setState(prevState => ({
          resendEnabled: false,
          timeLeft: prevState.timeLeft - 1
        }));
      } else {
        this.stopTimer();
      }
    }, 1000);
  };

  getCountryCode = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFormChange = (number: string) => {
    this.setState({ formNo: number });
  };
  // Customizable Area End
}
