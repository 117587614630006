import React from 'react';
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import {client_icon}
  from "../assets";


export default function ReasonComponent(props: any) {

  return (
    <>
     <div className="textLabel">
          <div>{props.reason_heading_1}</div>
          <div>{props.reason_heading_2}</div>
          <div className="textLabel1">{props.reason_heading_sub_heading}</div>
    </div>
    

    <Grid container style={{marginTop: "150px", marginBottom: "150px"}}>
      
      <Grid md={6} lg={6} sm={12} style={{ position: 'relative' }}>
          <img src={client_icon} style={{ width: "100%", marginTop: '28px', objectFit: 'fill', position: 'relative', right: '15%', maxHeight: '100%' }}></img>
      </Grid>

      <Grid item md={6} lg={6} sm={12} style={{margin: "auto"}}>
        <div className='ReasonTextSection'>
          
          <div style={{position: "relative", marginBottom: "150px"}}>
            <div className='frame'></div>
            <div className='ReasonTextHeading' style={{position: "absolute", top: "0px", marginTop: "0px"}}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {props.reason_1_heading}</div>
            <div className='ReasonText'>{props.reason_1_description}</div>
          </div>

          <div style={{position: "relative", marginBottom: "300px"}}>
            <div className='frame'></div>
            <div className='ReasonTextHeading' style={{position: "absolute", top: "0px", marginTop: "0px"}}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {props.reason_2_heading}</div>
            <div className='ReasonText'>{props.reason_2_description}</div>
          </div>

          <div style={{position: "relative", marginBottom: "450px"}}>
            <div className='frame'></div>
            <div className='ReasonTextHeading' style={{position: "absolute", top: "0px", marginTop: "0px"}}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {props.reason_3_heading}</div>
            <div className='ReasonText'>{props.reason_3_description}</div>
          </div>


          <div style={{position: "relative", marginBottom: "570px"}}>
            <div className='frame'></div>
            <div className='ReasonTextHeading' style={{position: "absolute", top: "0px", marginTop: "0px"}}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {props.reason_4_heading}</div>
            <div className='ReasonText'>{props.reason_4_description}</div>
          </div>


          <Button
                variant="contained"
                className="getStartedBtn"
                disableRipple
                onClick={()=>{
                  const element: any = document.getElementById('searchFocus_Id');
                  element.scrollIntoView();
                }}
              >
                <Box className="getStartedBtnBox">
                  <Typography className="getStartedBtnTxt">
                    Get started
                  </Typography>
                  <ArrowForwardIcon className="arrowForwardIcon" />
                </Box>
              </Button>

        </div>
      </Grid>

    </Grid>

    </>
  );
}
// Customizable Area End