import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
var logo = require("./assets/Logo.png");

export default function AppHeader() {
  const useStyles = makeStyles((theme) => ({
    homeHeaderMainContainer: {
      backgroundColor: "#150a1f",
      boxShadow: 'none',
      width: '100%',
      opacity: "1",
      position: 'sticky',
      top: '0%',
      left: '0%',
      color: '#fff',
      backdropFilter: 'blur(14px)',
      zIndex: 1111,
      float: 'left',
    },
    headerMainGridContainer: {
      alignItems: 'center'
    },
    logoGridContainer: {
      justifyContent: 'flex-start'
    },
    homeHeaderGridContainer: {
      margin: 'auto',
      padding: '15px 0px'
    },
    logoImageIconBtn: {
      "&:hover": {
        background: 'transparent',
        boxShadow: 'none'
      },
    }
  }));
  const classes = useStyles();
  return (
    <Box className={`${classes.homeHeaderMainContainer}`}>
      <Grid container>
        <Grid item xs={10} lg={10} md={10} className={classes.homeHeaderGridContainer}>
          <Grid container className={classes.headerMainGridContainer}>
            <Grid item xs={7} lg={4} md={4} className={classes.logoGridContainer}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                disableRipple
                href='/'
                className={classes.logoImageIconBtn}
              >
                <img src={logo} width="150px" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
