// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export interface Props {
  navigation?: any;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataSource: any[];
  selectedCountry: any;
  isOpen: boolean;
  mobileNo: string;
  token: string;
  label: any;
  timeLeft: any;
  snackbarMes: string;
  severity: string;
  otpReceived: number;
  otpReceivedPhone: number;
  mobiletoken: string;
  country: any;
  successOpen: boolean;
  operator_name: string;
  emailId: string;
  contact_name_error: boolean;
  contact_name_errorMes: any;
  emailIdError: boolean;
  emailIdErrorMes: any;
  operator_addressError: boolean;
  operator_addresserrorMes: any;
  full_phone_numberError: boolean;
  full_phone_numbererrorMes: any;
  operator_name_error: boolean;
  operator_name_errorMes: any;
  contact_name: string;
  operator_address: string;
  selectValue: string;
  email: any;
  open: boolean;
  privacyOpen: boolean;
  privacyError: boolean;
  privacyChecked: boolean;
  privacyErrorMes: any;
  full_phone_number: string;
  terms_and_conditions: boolean;
  formNo: string;
  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  cursorPointer: any;
  loadingSpinner: boolean;
  otperror: boolean;
  otperrorText: string;
  termsOpen: boolean;
  mail: string;
  number: string;
  terms_and_condition: string;
  privacy_policy: string;
  otpError: boolean;
  resendEnabled: boolean;
  otpErrorMes: any;
  responseMsg: string;
  // Customizable Area End
}

interface SS {}

export const configJSON = require("./../config.js");
const emailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export default class OperatorReservationFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSignUpCallId: string = "";
  apiCountryCallId: string = "";
  apiTermsAndConditionsCallId: string = "";
  apiPrivacyPolicyCallId: string = "";
  countryCodeApiCallId: string = "";
  apiPhoneOtpCallId: string = "";
  apiVerifyPhoneOtpCallId: string = "";
  apiEmailCallId: string = "";
  apiVerifyEmailCallId: string = "";
  apiPhoneNumberAlready: string =
    "Phone number already exists, Please try to sign up using different phone number.";
  apiEmailAlready: string =
    "Email id already exists, Please try to sign up using different email id.";
  apiPhoneandEmailAlready: string = " Your account is already created, Please login."
  intervalRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isOpen: false,
      dataSource: [],
      selectedCountry: 0,
      mobileNo: "",
      token: "",
      label: null,
      operator_name: "",
      contact_name: "",
      operator_address: "",
      email: "",
      full_phone_number: "",
      selectValue: "",
      timeLeft: 30,
      resendEnabled: true,
      severity: "",
      snackbarMes: "",
      otpError: false,
      otperrorText: "",
      otpErrorMes: "",
      mobiletoken: "",
      otpReceived: 0,
      otpReceivedPhone: 0,
      country: "",
      successOpen: false,
      emailId: "",
      contact_name_error: false,
      contact_name_errorMes: "",
      emailIdError: false,
      emailIdErrorMes: "",
      operator_addressError: false,
      operator_addresserrorMes: "",
      full_phone_numberError: false,
      full_phone_numbererrorMes: "",
      operator_name_error: false,
      operator_name_errorMes: "",
      open: false,
      privacyOpen: false,
      privacyError: false,
      privacyChecked: false,
      privacyErrorMes: "",
      terms_and_conditions: false,
      formNo: "0",
      otp_code: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      cursorPointer: 0,
      loadingSpinner: false,
      responseMsg: "",
      otperror: false,
      termsOpen: false,
      mail: "",
      number: "",
      terms_and_condition: "",
      privacy_policy: ""
    };
    this.intervalRef = React.createRef();
    this.userdata = this.userdata.bind(this);
    this.getTermsAndConditions = this.getTermsAndConditions.bind(this);
    this.getPrivacyPoliciy = this.getPrivacyPoliciy.bind(this);
    this.getCountryCode = this.getCountryCode.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);
      if (apiRequestCallId === this.countryCodeApiCallId && responseJson) {
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson),
          selectedCountry: this.countryCodesToDropDown(responseJson)[0]
        });
      }

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.apiSignUpCallId) {
          this.setState(
            {
              otpReceived: responseJson.data.attributes.pin,
              token: responseJson.meta.token,
              open: true
            },
            () => {
              this.handleFormChange("1");
            }
          );
        }
        if (apiRequestCallId === this.apiTermsAndConditionsCallId) {
          this.setState({
            terms_and_condition: responseJson.data.attributes.description
          });
        }
        if (apiRequestCallId === this.apiPrivacyPolicyCallId) {
          this.setState({
            privacy_policy: responseJson.data.attributes.description
          });
        }
        if (apiRequestCallId === this.apiVerifyEmailCallId) {
          this.setState({
            successOpen: true,
            open: true
          });
          setTimeout(
            function() {
              window.location.replace('/HomeScreenCharterer')
            }
            .bind(this),
            3000
          );
        }
        if (apiRequestCallId === this.apiVerifyPhoneOtpCallId) {
          this.setState({
            open: true,
            severity: "success"
          });
        }
      }
      if (responseJson.message === this.apiPhoneNumberAlready) {
        this.setState({
          full_phone_numberError: true,
          full_phone_numbererrorMes: "Contact number already exists"
        });
      }
      if (responseJson.message === this.apiEmailAlready) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: "Email already exists"
        });
      }
      if (responseJson.message === this.apiPhoneandEmailAlready) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: "Email already exists",
          full_phone_numberError: true,
          full_phone_numbererrorMes: "Contact number already exists"
        });
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  userdata = () => {
    console.log("userdata");
  };

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: (
        <>
          <img
            src={`https://flagcdn.com/${item.country_ISO_code.toLowerCase()}.svg`}
            width="24"
            height='16'            
            alt="flag"
            style={{ marginRight: "-10px", marginTop: "3px" }}
          />
          &nbsp; &nbsp; &nbsp; &nbsp;
          <span style={{fontFamily: "'Libre Franklin', sans-serif"}}>
            +{item.country_code}</span>
        </>
      ),
      value: item.country_code
    }));
  };

  handleChange = (item: any) => {
    this.setState({ label: item.label });
    this.setState({ selectedCountry: item });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  async componentDidMount() {
    this.userdata();
    this.getTermsAndConditions();
    this.getPrivacyPoliciy();
    this.getCountryCode();
  }

  getTermsAndConditions = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTermsAndConditionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPrivacyPoliciy = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPrivacyPolicyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicy
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  phoneFieldOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        full_phone_numberError: false,
        full_phone_numbererrorMes: "Contact number is required.",
        full_phone_number: ""
      });
    } else if (value.match(/^\d{10,18}$/)) {
      this.setState({
        full_phone_numberError: false,
        full_phone_numbererrorMes: "",
        full_phone_number: value
      });
    } else if (!value.match(/^\d{10,18}$/)) {
      this.setState({
        full_phone_numberError: true,
        full_phone_numbererrorMes: "Enter a valid Contact number.",
        full_phone_number: value
      });
    } else {
      this.setState({
        full_phone_numberError: false,
        full_phone_numbererrorMes: "",
        full_phone_number: value
      });
    }
  };
  phoneFieldOnBlur = () => {
    const phoneReg = /^\d{10,18}$/;
    if (
      this.state.full_phone_number === null ||
      this.state.full_phone_number.length === 0
    ) {
      this.setState({
        full_phone_numberError: true,
        full_phone_numbererrorMes: "Contact number is required."
      });
    } else if (!phoneReg.test(this.state.full_phone_number)) {
      this.setState({
        full_phone_numberError: true,
        full_phone_numbererrorMes: "Enter a valid Contact number"
      });
    } else {
      this.setState({
        full_phone_numberError: false,
        full_phone_numbererrorMes: ""
      });
    }
  };

  emailFieldOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        emailIdError: false,
        emailIdErrorMes: " Company Email is required"
      });
    } else if (!emailReg.test(value)) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Enter a valid Company Email"
      });
    } else if (emailReg.test(value)) {
      this.setState({
        emailIdError: false,
        emailIdErrorMes: ""
      });
    }
    this.setState({
      email: event.target.value.trim(),
      emailIdError: false,
      emailIdErrorMes: ""
    });
  };
  emailFieldOnBlur = () => {
    if (this.state.email === null || this.state.email.length === 0) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Email is required"
      });
    } else if (!emailReg.test(this.state.email)) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Enter a valid Email"
      });
    } else {
      this.setState({
        emailIdError: false,
        emailIdErrorMes: ""
      });
    }
  };

  validation = () => {
    const phoneReg = /^\d{10,18}$/;
    let allow: boolean = true;
    const {
      email,
      operator_address,
      contact_name,
      operator_name,
      terms_and_conditions,
      full_phone_number
    } = this.state;
    if (
      terms_and_conditions === false ||
      email.length === 0 ||
      operator_address.length === 0 ||
      contact_name.length === 0 ||
      operator_name.length === 0 ||
      full_phone_number.length === 0
    ) {
      if (this.state.email.length === 0) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: " Company Email is required"
        });
      } else if (!emailReg.test(email)) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: " Enter a valid Company Email"
        });
      }
      if (
        this.state.contact_name === "" ||
        this.state.contact_name.length === 0
      ) {
        this.setState({
          contact_name_error: true,
          contact_name_errorMes: "Contact name is required"
        });
      }
      if (
        this.state.operator_name === "" ||
        this.state.operator_name.length === 0
      ) {
        this.setState({
          operator_name_error: true,
          operator_name_errorMes: "Operator name is required"
        });
      }
      if (this.state.full_phone_number === "") {
        this.setState({
          full_phone_numberError: true,
          full_phone_numbererrorMes: "Contact number is required"
        });
      } else if (!phoneReg.test(full_phone_number)) {
        this.setState({
          full_phone_numberError: true,
          full_phone_numbererrorMes: "Contact number is not valid"
        });
      }
      if (
        this.state.operator_address === "" ||
        this.state.operator_address.length === 0
      ) {
        this.setState({
          operator_addressError: true,
          operator_addresserrorMes: "Operator address is required"
        });
      }
      if (this.state.terms_and_conditions === false) {
        this.setState({
          privacyError: true,
          privacyErrorMes:
            "Please accept the Tribe's terms of use and privacy policy before continuing"
        });
      }
      allow = false;
    }
    return allow;
  };

  //```````````````API calling start for Signup `````````````````````````//

  handleVerification = (e: any) => {
    e.preventDefault();
    const { otp1, otp2, otp3, otp4 } = this.state;
    const pin = otp1 + otp2 + otp3 + otp4;
    console.log("Verify your details here", pin, this.state.otpReceived);
    if (this.state.otpReceived === +pin) {
      this.handleVerifyEmail(e);
    } else {
      this.setState({
        otpErrorMes: "Uh-oh! You seem to have entered an invalid OTP",
        otpError: true
      });
    }
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    let allow: boolean = true;
    event.preventDefault();

    allow = await this.validation();
    if (allow) {
      const header = {
        "Content-Type": configJSON.validationApiContentType
      };
      let data = {
        data: {
          attributes: {
            user_type : "operator",
            first_name: this.state.operator_name,
            last_name: this.state.contact_name,
            operator_address: this.state.operator_address,
            email: this.state.email,
            full_phone_number:
              this.state.selectedCountry.value + this.state.full_phone_number,
            terms_and_condtions: this.state.terms_and_conditions
          }
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiSignUpCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.signUpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sellerDetailsAPIMethodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleTermsOpen = () => {
    this.setState({ termsOpen: !this.state.termsOpen });
  };

  handleModalcheck = () => {
    this.setState({ successOpen: false, formNo: "0" });
  };

  handleSignupFormChanger = () => {
    this.setState({ formNo: "0" });
  };

  handlePrivacyOpen = () => {
    this.setState({ privacyOpen: false });
  };

  handlePrivacyOpen1 = () => {
    this.setState({ privacyOpen: true });
  };

  handleTCchecker = () => {
    this.setState({
      terms_and_conditions: !this.state.terms_and_conditions,
      privacyError: this.state.terms_and_conditions ? true : false
    });
  };

  handleOTPInputChekcer = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 1);
  };

  handlePaster = (e: any) => {
    const otp = e.clipboardData.getData("Text");
    this.setState({
      otp1: otp[0],
      otp2: otp[1],
      otp3: otp[2],
      otp4: otp[3]
    });
  };
  handleCheckbox = () => {
    this.setState({
      terms_and_conditions: !this.state.terms_and_conditions,
      privacyError: this.state.terms_and_conditions ? true : false,
      privacyErrorMes: this.state.terms_and_conditions
        ? "Please accept the Trie's terms of use and privacy policy before continuing"
        : ""
    });
  };

  handleBlurChecker = () => {
    if (this.state.terms_and_conditions === false) {
      this.setState({
        privacyError: true,
        privacyErrorMes:
          "Please accept the Tribe’s terms of use and privacy policy before continuing"
      });
    } else {
      this.setState({
        privacyError: false,
        privacyErrorMes: ""
      });
    }
  };

  handleTermsChecker = () => {
    this.setState({
      terms_and_conditions: !this.state.terms_and_conditions,
      privacyError: this.state.terms_and_conditions ? true : false
    });
  };

  handleOTPFields1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value === "N" ? "" : event.target.value;
    if (
      this.state.otp2 !== "" &&
      this.state.otp3 !== "" &&
      this.state.otp4 !== ""
    ) {
      this.setState({
        otp1: value,
        otperror: false
      });
    } else {
      this.setState({
        otp1: value
      });
    }

    if (value.length === 1) {
      const nextfield = document.querySelector(`input[name=otp2]`);
      if (nextfield !== null) {
        (nextfield as HTMLElement).focus();
      }
    }
  };
  handleOTPfields2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value === "N" ? "" : event.target.value;
    if (
      this.state.otp1 !== "" &&
      this.state.otp3 !== "" &&
      this.state.otp4 !== ""
    ) {
      this.setState({
        otp2: value,
        otperror: false
      });
    } else {
      this.setState({
        otp2: value
      });
    }

    if (value.length === 1) {
      const nextfield = document.querySelector(`input[name=otp3]`);
      if (nextfield !== null) {
        (nextfield as HTMLElement).focus();
      }
    }else{
      const prevfield = document.querySelector(`input[name=otp1]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  handleOTPfields3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value === "N" ? "" : event.target.value;
    if (
      this.state.otp1 !== "" &&
      this.state.otp2 !== "" &&
      this.state.otp4 !== ""
    ) {
      this.setState({
        otp3: value,
        otperror: false
      });
    } else {
      this.setState({
        otp3: value
      });
    }

    if (value.length === 1) {
      const nextfield = document.querySelector(`input[name=otp4]`);
      if (nextfield !== null) {
        (nextfield as HTMLElement).focus();
      }
    }else{
      const prevfield = document.querySelector(`input[name=otp2]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  handleOTPfields4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value === "N" ? "" : event.target.value;
    if (
      this.state.otp1 !== "" &&
      this.state.otp2 !== "" &&
      this.state.otp3 !== ""
    ) {
      this.setState({
        otp4: value,
        otperror: false
      });
    } else {
      this.setState({
        otp4: value
      });
    }
    if (value.length !== 1) {
      const prevfield = document.querySelector(`input[name=otp3]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  handleVerifyEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { otp1, otp2, otp3, otp4 } = this.state;
    const pin = otp1 + otp2 + otp3 + otp4;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyEmailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.VerifyEmailEndPoint + `?pin=${+pin}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  stopTimer = () => {
    clearInterval(this.intervalRef.current);
    this.setState({
      timeLeft: 30,
      resendEnabled: true
    });
  };

  handleResend = (e: any) => {
    this.handleSubmit(e)    
    this.handleCountDown()
  }
  handleCountDown = () => {
    this.intervalRef.current = setInterval(() => {
      if (this.state.timeLeft > 0) {
        this.setState(prevState => ({
          resendEnabled: false,
          timeLeft: prevState.timeLeft - 1
        }));
      } else {
        this.stopTimer();
      }
    }, 1000);
  };

  getCountryCode = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFormChange = (number: string) => {
    this.setState({ formNo: number });
  };

  OperatorNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.length === 0) {
      this.setState({
        operator_name_error: true,
        operator_name_errorMes: "Operator name is required",
        operator_name: ""
      });
    } else {
      this.setState({
        operator_name: e.target.value,
        operator_name_error: false,
        operator_name_errorMes: ""
      });
    }
  };

  ContactNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.length === 0) {
      this.setState({
        contact_name_error: true,
        contact_name_errorMes: "Contact name is required",
        contact_name: ""
      });
    } else {
      this.setState({
        contact_name: e.target.value,
        contact_name_error: false,
        contact_name_errorMes: ""
      });
    }
  };

  OperatorAddressChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.length === 0) {
      this.setState({
        operator_addressError: true,
        operator_addresserrorMes: "Operator Address is required",
        operator_address: ""
      });
    } else {
      this.setState({
        operator_address: e.target.value,
        operator_addressError: false,
        operator_addresserrorMes: ""
      });
    }
  };
  
  // Customizable Area End
}
