// Customizable Area Start
import * as React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  createTheme,
  ThemeProvider,
  createStyles,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "./OperatorReservationForm.web.css";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import {
  backArrow, resend, caution, resend_disabled, congratulations_sign_up
} from "../assets";
import { arrow_down_icon } from "../../../landingpage/src/assets";
import Select from "react-select";
import FormHelperText from "@material-ui/core/FormHelperText";
import OperatorReservationFormController, {
  Props
} from "./OperatorReservationFormController.web";

export const Svg = (p: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);

export const Menu = (props: any) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2
      }}
      {...props}
    />
  );
};

export const Blanket = (props: any) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1
    }}
    {...props}
  />
);

export const Dropdown = ({ children, isOpen, target, onClose }: any) => (
  <div
    style={{
      color: "white",
      background: "#504857",
      // match with the menu
      // borderRadius: "3px 0px 0 0",
      // Overwrittes the different states of border
      border: "white",
      fontSize: 16,
      height: "59px",
      width: "100%",
      marginTop: "10px"
    }}
  >
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export const DropdownIndicator = () => (
  <div>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export const BootstrapNumberInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      color: "#FCF6F0",
      position: "relative",
      backgroundColor: "#504857",
      border: "white",
      fontSize: "16px",
      padding: "20px 20px 20px 0px",
      marginTop: "10px",
      lineHeight: "16px",
      letterSpacing: "0.40477px",
      // Use the system font instead of the default Roboto font.
      fontFamily: ["'Libre Franklin', sans-serif"].join(","),

      "&:focus": {
        borderColor: "white"
      }
    }
  })
)(InputBase);

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      position: "relative",
      backgroundColor: "#504857",
      border: "white",
      padding: "20px",
      marginTop: "10px",

      fontSize: "16px",
      lineHeight: "16px",

      letterSpacing: "0.40477px",

      color: "#FCF6F0",
      // Use the system font instead of the default Roboto font.
      fontFamily: ["'Libre Franklin', sans-serif"].join(","),
      "&:focus": {
        borderColor: "white"
      }
    }
  })
)(InputBase);

export function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    color: "white",
    background: "#504857",
    padding: "10px",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    border: "white",
    fontSize: 16,
    height: "57px",
    boxShadow: "1px,0px,0px,0px #504857",
    width: "146px",
    // Removes weird border around container
    "&:hover": {
      boxShadow: "1px,0px,0px,0px #504857"
      // Overwrittes the different states of border
    }
  }),
  input: (base: any) => ({
    ...base,
    color: "white",
    background: "#504857"
  }),
  menu: (base: any) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    color: "#FFFFFF"
  }),
  menuList: (base: any) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    background: "#504857",
    color: "#FFFFFF"
  }),
  option: (base: any) => ({
    ...base,
    color: "#FFFFFF",
    background: "#504857"
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#FFFFFF"
  })
};
export const theme = createTheme();
// Customizable Area End

export default class OperatorReservationForm extends OperatorReservationFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { formNo, otp1, otp2, otp3, otp4 } = this.state;
    console.log("includes", this.state.responseMsg.includes("Phone Number"));
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ marginTop: '119px' }}>
          <Grid item xs={9} style={{ margin: 'auto' }} className='setBackgroundTribeLogoSignUp'>
            {/* First screen element  */}
            {formNo === "0" && (
              <Box className="mainBoxForm1">
                <Grid container spacing={0} className="signupform">
                  <Grid item xs={12} sm={12}>
                    <Typography className="signupMaintext">
                      Create Operator account
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography className="manageFleet">
                      Manage your fleet by Signing Up
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  component="form"
                  onSubmit={this.handleSubmit}
                  sx={{ display: "contents" }}
                >
                  <Grid container spacing={2} className="signupForm">
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                      
                      <Grid container spacing={5}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          className="lasNameMarSpec"
                          style={{ padding: (this.state.operator_name_error || this.state.contact_name_error) ? '20px 20px 0px 20px' : '20px' }}
                        >
                      
                          <FormControl className="widthSpec">
                            <InputLabel
                              shrink
                              htmlFor="bootstrap-input"
                              className="inputLabelForm"
                            >
                              Operator name
                            </InputLabel>
                            <BootstrapInput
                              data-testid="operator-name-check"
                              placeholder="Enter business name"
                              autoComplete="given-name"
                              name="operatorName"
                              fullWidth
                              id="operatorName"
                              value={this.state.operator_name}
                              onChange={(e: any) => {
                                this.OperatorNameChangeHandler(e);
                              }
                              }
                            />
                            {this.state.operator_name_error && (
                              <FormHelperText className="helperTextFieldSignUp">
                                <img src={caution} className="errorimg" />{" "}
                                <span className="helpertext">
                                  {this.state.operator_name_errorMes}
                                </span>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          className="lasNameMarSpec"
                          style={{ padding: (this.state.operator_name_error || this.state.contact_name_error) ? '20px 20px 0px 20px' : '20px' }}
                        >
                          <FormControl className="widthSpec">
                            <InputLabel
                              shrink
                              htmlFor="bootstrap-input"
                              className="inputLabelForm"
                              style={{
                                color: "white",
                                fontFamily: "PlayfairDisplay"
                              }}
                            >
                              Contact name
                            </InputLabel>
                            <BootstrapInput
                              placeholder="Enter your full name"
                              autoComplete="given-name"
                              data-testid="contact-name-check"
                              name="contactName"
                              fullWidth
                              id="contactName"
                              value={this.state.contact_name}
                              onChange={(e: any) =>
                                this.ContactNameChangeHandler(e)
                              }
                            />
                            {this.state.contact_name_error && (
                              <FormHelperText className="helperTextFieldSignUp">
                                <img src={caution} className="errorimg" />{" "}
                                <span className="helpertext">
                                  {this.state.contact_name_errorMes}
                                </span>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid
                      item
                      md={10}
                      lg={10}
                      xs={12}
                      sm={12}
                      style={{ marginTop: "26px" }}
                    >
                        <FormControl className="widthSpec">
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className="inputLabelForm"
                            style={{
                              fontFamily: "PlayfairDisplay",
                              width: "200px"
                            }}
                          >
                            Contact number
                          </InputLabel>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        className="marginSpec"
                      >
                        <Grid container>
                          <Grid item lg={2} md={2} xs={2} sm={2}>
                            <Box className="customFlag_container">
                              <Dropdown
                                isOpen={this.state.isOpen}
                                data-testid="close_dropdown"
                                onClose={() =>
                                  this.setState({
                                    isOpen: false
                                  })
                                }
                                target={
                                  <Button
                                    variant="contained"
                                    data-testid="dropdown-btn-countrycode"
                                    style={{
                                      color: "white",
                                      background: "#504857",
                                      borderRadius: "3px 3px 0 0",
                                      border: "white",
                                      fontSize: 16,
                                      height: "59px",
                                      width: "100%",
                                      justifyContent: "left",
                                      fontFamily: "LibreFranklin"
                                    }}
                                    endIcon={<img src={arrow_down_icon}
                                      height="30px" width={"30px"}></img>}
                                    onClick={() =>
                                      this.setState({
                                        isOpen: true
                                      })
                                    }
                                  >
                                    {this.state.selectedCountry
                                      ? this.state.selectedCountry.label
                                      : "Select"}
                                  </Button>
                                }
                              >
                                <Select
                                  autoFocus
                                  backspaceRemovesValue={false}
                                  components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: null
                                  }}
                                  className="SelectField"
                                  controlShouldRenderValue={false}
                                  hideSelectedOptions={false}
                                  data-testid="country-check"
                                  isClearable={false}
                                  placeholder="Search..."
                                  menuIsOpen
                                  tabSelectsValue={false}
                                  styles={customStyles}
                                  options={this.state.dataSource}
                                  value={this.state.selectedCountry}
                                  onChange={e => {
                                    this.handleChange(e);
                                    this.setState({
                                      isOpen: false
                                    });
                                  }}
                                />
                              </Dropdown>
                            </Box>
                          </Grid>

                          <Grid
                            lg={1}
                            md={1}
                            xs={1}
                            sm={1}
                            style={{ maxWidth: "20px" }}
                          >
                            <Box>
                              <div className="verticalLine">
                                <div className="verticalLine2" />
                              </div>
                            </Box>
                          </Grid>

                          <Grid item lg={9} md={9} xs={9} sm={9}>
                            <Box>
                              <BootstrapNumberInput
                                placeholder="Enter your contact number for OTP"
                                autoComplete="given-name"
                                data-testid="phone-check"
                                name="contactNumber"
                                fullWidth
                                id="contactNumber"
                                value={this.state.full_phone_number}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => this.phoneFieldOnchange(event)}
                                onBlur={() => this.phoneFieldOnBlur()}
                              />
                            </Box>
                          </Grid>

                          <Grid
                            lg={1}
                            md={1}
                            xs={1}
                            sm={1}
                            style={{
                              position: "relative",
                              maxWidth: "55px",
                              background: "#504857",
                              marginTop: "10px",
                              marginRight: "2px"
                            }}
                          >
                            <Box />
                          </Grid>
                        </Grid>
                      </Grid>



                      {this.state.full_phone_numberError && (
                      <Grid item xs={12} sm={6} md={10} lg={10}
                          style={{paddingTop: "0px", marginTop: "0px"}}>
                          <FormHelperText className="helperTextField1CH" style={{marginTop: "0px"}}>
                            <img src={caution} className="errorimgcontact" />{" "}
                            <span className="helpertextcontact">
                              {this.state.full_phone_numbererrorMes}
                            </span>
                          </FormHelperText>
                      </Grid>
                      )}
                    
                    <Grid item xs={12} sm={12} md={10} lg={10} style={{marginTop: "12px"}}>                    
                      <Grid container spacing={5}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          className="lasNameMarSpec"
                          style={{ padding: (this.state.operator_addressError || this.state.emailIdError) ? '20px 20px 0px 20px' : '20px' }}
                        >
                        
                          <FormControl className="widthSpec">
                            <InputLabel
                              shrink
                              htmlFor="bootstrap-input"
                              className="inputLabelForm"
                            >
                              Operator Address
                            </InputLabel>
                            <BootstrapInput
                              data-testid="operator-address-check"
                              placeholder="Enter office address of the operator"
                              autoComplete="given-name"
                              name="operatorAddress"
                              fullWidth
                              id="operatorAddress"
                              value={this.state.operator_address}
                              onChange={(e: any) =>
                                this.OperatorAddressChangeHandler(e)
                              }
                            />
                            {this.state.operator_addressError && (
                              <FormHelperText className="helperTextFieldSignUp">
                                <img src={caution} className="errorimg" />{" "}
                                <span className="helpertext">
                                  {this.state.operator_addresserrorMes}
                                </span>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            className="lasNameMarSpec"
                            style={{ padding: (this.state.operator_addressError || this.state.emailIdError) ? '20px 20px 0px 20px' : '20px' }}
                          >
                        
                          <FormControl className="widthSpec">
                            <InputLabel
                              shrink
                              htmlFor="bootstrap-input"
                              className="inputLabelForm"
                              style={{
                                color: "white",
                                fontFamily: "PlayfairDisplay"
                              }}
                            >
                              Company Email
                            </InputLabel>
                            <BootstrapInput
                              placeholder="Enter company email address to register"
                              autoComplete="given-name"
                              data-testid="email-check"
                              name="email"
                              fullWidth
                              id="email"
                              value={this.state.email}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => this.emailFieldOnchange(event)}
                              onBlur={this.emailFieldOnBlur}
                            />
                            {this.state.emailIdError && (
                              <FormHelperText className="helperTextFieldSignUp">
                                <img src={caution} className="errorimg" />{" "}
                                <span className="helpertext">
                                  {this.state.emailIdErrorMes}
                                </span>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>   
                    </Grid>               

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      className="marginSpec"
                    >
                      <div style={{marginTop: "20px"}}>
                      <Box className="checkBoxGridDiv">
                        <Checkbox
                          data-testid="checkbox-check"
                          id="checkbox-check"
                          value={this.state.terms_and_conditions}
                          className={
                            this.state.terms_and_conditions
                              ? "chartererCheckFieldTrue"
                              : "chartererCheckField"
                          }
                          checked={this.state.terms_and_conditions}
                          onChange={this.handleCheckbox}
                        />
                        <Box>
                          <Typography className="terms_text">
                            By creating an account, I understand & agree to
                            Tribe's{" "}
                            <span
                              data-testid="terms-check"
                              className="terms_and_privacy"
                              onClick={this.handleTermsOpen}
                            >
                              terms of use
                            </span>{" "}
                            and{" "}
                            <span
                              data-testid="privacy-policy-check"
                              className="terms_and_privacy"
                              onClick={this.handlePrivacyOpen}
                            >
                              privacy policy.
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      </div>
                      {this.state.privacyError && (
                        <FormHelperText className="helperTextFieldSignUp" style={{
                          marginTop: "10px"
                        }}>
                          <img src={caution} className="caution_img" />{" "}                          
                          {this.state.privacyErrorMes}
                        </FormHelperText>
                      )}
                    </Grid>

                   
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: "20px"}}>
                      <Button
                        type="submit"
                        data-testid="createbtnSubmit"
                        className={
                          this.state.operator_name === "" ||
                            this.state.contact_name === "" ||
                            this.state.terms_and_conditions === false ||
                            this.state.emailIdError ||
                            this.state.full_phone_numberError
                            ? "charterCreateAccountBtn charterCreateAccountDisabledBtn"
                            : "charterCreateAccountBtn charterCreateAccountEnabledBtn"
                        }
                        disableRipple
                        disabled={
                          this.state.operator_name === "" ||
                          this.state.contact_name === "" ||
                          this.state.terms_and_conditions === false ||
                          this.state.emailIdError ||
                          this.state.full_phone_numberError
                        }
                      >
                        Create my account
                      </Button>
                    </Grid>
                    
                  </Grid>
                  </Box>

                
                <Grid item xs={12} sm={6} style={{ marginTop: "40px" }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item className="loginUseText">
                      Already have an account?{" "}
                      <span>
                        <Link href="/OperatorLogin" className="termsOfUseText">
                          {" "}
                          Log In
                        </Link>
                      </span>
                    </Grid>
                  </Grid>
                </Grid>              
              </Box>
            )}
            {/* Second screen element  */}
            {formNo === "1" && (
            <Box className="mainOTPBoxContainer">
              <Box className="mainBoxOTPForm">
                <Grid container className="signupForm">
                  <Grid item xs={12} sm={5} md={5} lg={5}>
                    <Grid container className="signupForm">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ position: "relative" }}
                      >
                        <Box className="backArrowCharterImg">
                          <img
                            onClick={this.handleSignupFormChanger}
                            src={backArrow}
                            width="30px"
                            height="20px"
                            className="backArowImage"
                          />
                        </Box>
                        <Typography className="textlabel_OtpVerification">
                          OTP Verification
                        </Typography>
                      </Grid>

                      <Grid
                          item
                          xs={12}
                          sm={12}
                          className="verficationCodegrid"
                        >
                          <Typography
                            component="h1"
                            className="verficationOTPCodetext"
                          >
                            {this.state.resendEnabled ? <>Verification code sent to</> : <>A new OTP has been sent successfully to</>}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          className="verficationCodegrid"
                        >
                          <Typography
                            component="h1"
                            className="verficationOTPCodetext"
                          >
                            +{this.state.selectedCountry.value} {this.state.full_phone_number} and {this.state.email}
                          </Typography>
                        
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Typography className="chartererSignupnterOtptext">
                            Enter the OTP
                          </Typography>
                        </Grid>
                        
                    </Grid>
                

                   
                    <Grid item xs= {12} sm={12}>
                      <Box
                        component="form"
                        data-testid="testOTP"
                        // onSubmit={this.handleVerification}
                        sx={{ display: "contents" }}
                      >                    
                      <div
                        // className="EmailLoginOTPTextFieldDiv"
                        data-testid="otpchecker"
                      >
                      <Grid container className="signupForm" spacing={4}>
                        <Grid item xs={3} sm={3}>

                          <TextField
                            data-testid="otp1-check"
                            id="standard-basic"
                            className="EmailChartersignupOtpForrmInputTextfield"
                            variant="outlined"
                            autoFocus={true}
                            type="tel"
                            name="otp1"
                            placeholder="_"
                            value={this.state.otp1}
                            error={this.state.otperror}
                            onPaste={this.handlePaster}
                            onChange={this.handleOTPFields1}
                            inputProps={{
                              className: this.state.otpError
                                ? "signup_otp_input_props_error"
                                : "signup_otp_input_props"
                            }}
                            onInput={this.handleOTPInputChekcer}
                            InputProps={{
                              style: {
                                backgroundColor: this.state.otp1
                                  ? "#504857"
                                  : "#150a1f"
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={3} sm={3}>
                        
                          <TextField
                            // id="standard-basic"
                            data-testid="otp2-check"
                            id="standard-basic"
                            className="EmailChartersignupOtpForrmInputTextfield"
                            variant="outlined"
                            type="tel"
                            name="otp2"
                            placeholder="_"
                            value={this.state.otp2}
                            error={this.state.otperror}
                            onPaste={this.handlePaster}
                            onChange={this.handleOTPfields2}
                            inputProps={{
                              className: this.state.otpError
                                ? "signup_otp_input_props_error"
                                : "signup_otp_input_props"
                            }}
                            onInput={this.handleOTPInputChekcer}
                            InputProps={{
                              style: {
                                backgroundColor: this.state.otp2
                                  ? "#504857"
                                  : "#150a1f"
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={3} sm={3}>

                          <TextField
                            id="standard-basic"
                            data-testid="otp3-check"                            
                            className="EmailChartersignupOtpForrmInputTextfield"
                            variant="outlined"                            
                            type="tel"
                            name="otp3"
                            placeholder="_"
                            value={this.state.otp3}
                            error={this.state.otperror}
                            onPaste={this.handlePaster}
                            onChange={this.handleOTPfields3}
                            inputProps={{
                              className: this.state.otpError
                                ? "signup_otp_input_props_error"
                                : "signup_otp_input_props"
                            }}
                            onInput={this.handleOTPInputChekcer}
                            InputProps={{
                              style: {
                                backgroundColor: this.state.otp3
                                  ? "#504857"
                                  : "#150a1f"
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={3} sm={3}>

                          <TextField
                            id="standard-basic"
                            data-testid="otp4-check"                            
                            className="EmailChartersignupOtpForrmInputTextfield"
                            variant="outlined"                            
                            type="tel"
                            name="otp4"
                            placeholder="_"
                            value={this.state.otp4}
                            error={this.state.otperror}
                            onPaste={this.handlePaster}
                            onChange={this.handleOTPfields4}
                            inputProps={{
                              className: this.state.otpError
                                ? "signup_otp_input_props_error"
                                : "signup_otp_input_props"
                            }}
                            onInput={this.handleOTPInputChekcer}
                            InputProps={{
                              style: {
                                backgroundColor: this.state.otp4
                                  ? "#504857"
                                  : "#150a1f"
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      </div>                    
                    </Box>

                  {/* </Grid> */}
                  {this.state.otpError && (
                    <Grid container className="signupForm" spacing={4}>
                      <Grid item xs={12} className="otpValidateErrorBox">

                        <FormHelperText className="helperTextField">
                          <img src={caution} className="errorimgOtp" />{" "}
                          {this.state.otpErrorMes}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  )}
                  {/* </Grid> */}
                
                  <Grid container className="signupForm" spacing={4}>
                    <Grid item xs={12} style={
                      { 
                        margin: this.state.otpError ? '24px 0px 8px 0px' : '64px 0px 0px 0px',
                        paddingBottom: "0px" 
                      }}>
                      <Box className="charter_otp_box">
                        <Typography component="h1" className="didnotreceive">
                          {this.state.resendEnabled
                            ? "Didn't receive OTP?"
                            : `Valid for 10:00`}
                        </Typography>
                        <Button
                          data-testid="resend_OTP"
                          disableRipple
                        >
                          {this.state.resendEnabled
                            ? (
                              <>
                                <img src={resend} className="resend-img" />{" "}
                                <span className="resend_OTP" onClick={(e: any) => {
                                  this.handleResend(e)
                                }}>
                                  Resend OTP</span>
                              </>)
                            : (
                              <>
                                <img src={resend_disabled} className="resend-img" />{" "}
                                <span className="resend_OTP_Disabled">
                                  Resend OTP in 00:{this.state.timeLeft} </span>
                              </>
                            )
                          }
                        </Button>
                      </Box>
                      

                    </Grid>
                  </Grid>

                  <Grid container className="signupForm" spacing={4}>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        data-testid="verify-check"
                        onClick={this.handleVerification}
                        id="verify-check"
                        variant="contained"
                        disabled={
                          otp1 === "" ||
                          otp2 === "" ||
                          otp3 === "" ||
                          otp4 === "" 
                        }
                        className="verfiy-signupCharterBtn"
                      >
                        Verify and Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                </Grid> 
                </Grid>              
              </Grid>
            </Box>
            </Box>
            )}
            {/* Success Modal */}
            <Dialog
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
              }}
              PaperProps={{
                style: {
                  backgroundColor: "#150a1f",
                  minHeight: "100vh",
                  minWidth: "100vw"
                }
              }}
              id="modal-check"
              data-testid="modal-check"
              onClick={this.handleModalcheck}
              aria-labelledby="customized-dialog-title"
              open={this.state.successOpen}
            >
              <>
                <div style={{
                  textAlign: "center"
                }}>
                  <img src={congratulations_sign_up} width="60%" height="700px" />
                </div>
              </>
            </Dialog>
            {/* terms of use modal */}
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "#f2dcc4",
                  minHeight: "80vh",
                  minWidth: "80vh"
                }
              }}
              // onClick={this.handleTermsOpen}
              aria-labelledby="customized-dialog-title"
              open={this.state.termsOpen}
            >
              <DialogContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.terms_and_condition
                  }}
                />
              </DialogContent>
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "20px"
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FormControlLabel
                      data-testid="formdetails"
                      control={
                        <Checkbox
                          checked={this.state.terms_and_conditions}
                          data-testid="checkbox"
                          style={{ color: "black" }}
                          onChange={() => {
                            this.setState({
                              terms_and_conditions: !this.state
                                .terms_and_conditions,
                              privacyError: this.state.terms_and_conditions
                                ? true
                                : false,
                              privacyErrorMes: this.state.terms_and_conditions
                                ? "Please accept the Tribe's terms of use and privacy policy before continuing"
                                : ""
                            });
                          }}
                        />
                      }
                      label={
                        <Typography className="emailLabelModal">
                          By accepting, I agree to the above terms of services.{" "}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="modalButtonContainer">
                    <Button
                      data-testid="modalButton2"
                      variant="contained"
                      className="modalButton"
                      disableRipple
                      style={{
                        boxShadow: "none"
                      }}
                      onClick={() =>
                        this.setState({
                          termsOpen: false,
                          terms_and_conditions: false,
                          privacyError: true,
                          privacyErrorMes:
                            "Please accept the Tribe's terms of use and privacy policy before continuing"
                        })
                      }
                    >
                      Decline
                    </Button>
                    <Button
                      variant="contained"
                      data-testid="modalButtonAccept1"
                      className="modalButtonAccept"
                      disableRipple
                      onClick={() =>
                        this.setState({
                          terms_and_conditions: true,
                          termsOpen: false,
                          privacyError: false,
                          privacyErrorMes: ""
                        })
                      }
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
            {/* Privacy policy Modal */}
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "#f2dcc4",
                  minHeight: "80vh",
                  minWidth: "80vh"
                }
              }}
              // onClick={this.handlePrivacyOpen}
              aria-labelledby="customized-dialog-title"
              open={this.state.privacyOpen}
            >
              <DialogContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.privacy_policy
                  }}
                />
              </DialogContent>
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "20px"
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FormControlLabel
                      data-testid="formdetails2"
                      control={
                        <Checkbox
                          data-testid="checkbox"
                          checked={this.state.terms_and_conditions}
                          style={{ color: "black" }}
                          onChange={() => {
                            this.setState({
                              terms_and_conditions: !this.state
                                .terms_and_conditions,
                              privacyError: this.state.terms_and_conditions
                                ? true
                                : false,
                              privacyErrorMes: this.state.terms_and_conditions
                                ? "Please accept the Tribe's terms of use and privacy policy before continuing"
                                : ""
                            });
                          }}
                        />
                      }
                      label={
                        <Typography className="emailLabelModal">
                          {" "}By accepting, I agree to the above privacy policy.{" "}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="modalButtonContainer">
                    <Button
                      variant="contained"
                      className="modalButton"
                      data-testid="modalButton1"
                      disableRipple
                      style={{
                        boxShadow: "none"
                      }}
                      onClick={() =>
                        this.setState({
                          privacyOpen: false,
                          terms_and_conditions: false,
                          privacyError: true,
                          privacyErrorMes:
                            "Please accept the Tribe's terms of use and privacy policy before continuing"
                        })
                      }
                    >
                      Decline
                    </Button>
                    <Button
                      variant="contained"
                      className="modalButtonAccept"
                      data-testid="modalButtonAccept2"
                      disableRipple
                      onClick={() =>
                        this.setState({
                          terms_and_conditions: true,
                          privacyOpen: false,
                          privacyError: false,
                          privacyErrorMes: ""
                        })
                      }
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.open}
          onClose={this.handleClose}
          autoHideDuration={6000}
        >
          <Alert
            onClose={this.handleClose}
            severity={this.state.severity}
            sx={{ width: "100%" }}
          >
            {this.state.snackbarMes}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    );
    // for new code
    // Customizable Area End
  }
}
