import React from "react";

import {
  Container,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./TermsAndConditions.web.css";
// Customizable Area End

import TermsAndConditionsController, {
  Props,
} from "./TermsAndConditionsController";
import HomeHeader from "../../../components/src/HomeHeader.web";
import FooterHomePageWeb from "../../landingpage/src/FooterHomePage/FooterHomePage.web";

export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Backdrop open={this.state.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <>
          <Box className="headerContainerTerms">
            <HomeHeader />
          </Box>
          <Container className="mainContainerTerms">
            <Grid container>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <div className="termsofuseHeading">Terms of use</div>
              </Grid>

              <Grid item md={12} lg={12} sm={12} xs={12} style={{color:"#c4c1c7"}}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.terms_and_conditions,
                  }}
                />
              </Grid>
            </Grid>
          </Container>
          <FooterHomePageWeb />
        </>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
