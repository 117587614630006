import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  SafeAreaView,
  View,
} from "react-native";
import "./AboutUs.css";
import { image_Logo, image_Back } from '../assets';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CallMadeIcon from "@material-ui/icons/CallMade";
import Typography from "@material-ui/core/Typography";
import AboutUsController, {
  Props,
  //   configJSON
} from "./AboutUsController";
import FooterHomePageWeb from "../../../landingpage/src/FooterHomePage/FooterHomePage.web";
import AboutHeader from "../../../../components/src/AboutHeader.web";

export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <div style={{ backgroundColor: '#150A1F', fontFamily: 'sans-serif' }}>
          <div>
            <Container style={{ minHeight: '46vh', marginTop: '69px' }}>
              <div className="aboutus_header">
                <img src={image_Logo} alt="" style={{ marginTop: "50px" }} height="160px" width="170px"/>
                <div style={{ fontFamily: 'Playfair Display', marginTop: '50px', fontSize: '50px' }}>{this.state.aboutData?.heading}</div>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '50px' }}>{this.state.aboutData?.sub_heading}</div>
              </div>
              <Grid container justifyContent="center" alignItems="center" style={{ marginTop: '14px' }}>
                <Grid xs={8}>
                  <div className="aboutus_txt_styles">{this.state.aboutData?.description1}</div>
                  <div className="aboutus_txt_styles">{this.state.aboutData?.description2}</div>
                  <div className="aboutus_txt_styles">{this.state.aboutData?.description3}</div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '56px' }}>
                    <a href='/Contact-us'>
                      <Button
                        variant="contained"
                        className="contactUSgetStartedBtn"
                        disableRipple
                      >
                        <Box className="getStartedBtnBox">
                          <Typography className="getStartedBtnTxt">
                            Contact Us
                          </Typography>
                          <ArrowForwardIcon className="arrowForwardIcon" />
                        </Box>
                      </Button>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>

          <Container style={{maxWidth:"85%"}}>
            <Divider style={{ background: '#8A848F', margin: '124px 0' }} />
          </Container>

          <Container style={{ color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center',maxWidth:"85%" }}>
            <div style={{ color: '#F2DCC4', marginBottom: '29px', fontSize: '22px', fontFamily: 'Libre Franklin' }}>{this.state.aboutData?.heading2}</div>
            <div style={{ letterSpacing: '1.5', fontSize: '32px', fontFamily: 'Playfair Display', fontWeight: 700 }}>{this.state.aboutData?.sub_heading2}</div>
          </Container>

          <Container style={{maxWidth:"85%"}}>
            <Divider style={{ background: '#8A848F', margin: '124px 0' }} />
          </Container>

          <Container style={{ color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '109px' }}>
            <div style={{ fontSize: '32px', fontFamily: 'Playfair Display', fontWeight: 700, marginBottom: '48px' }}>{this.state.aboutData?.content}</div>
            {this.state.authToken ? (
              <Button
                variant="contained"
                className="contactUSbookNowBtn"
                disableRipple
                href='/HomeScreenCharterer'
              >
                <Box className="contactUSbookNowBtnBox">
                  <Typography className="BookBtnTxt">Book now</Typography>
                  <CallMadeIcon className="callMadeIcon" />
                </Box>
              </Button>
            ) : (
              <Button
                variant="contained"
                className="contactUSgetStartedBtn"
                disableRipple
                href='/LandingPage/Signup'
              >
                <Box className="getStartedBtnBox">
                  <Typography className="getStartedBtnTxt">
                    Get started
                  </Typography>
                  <ArrowForwardIcon className="arrowForwardIcon" />
                </Box>
              </Button>
            )}
          </Container>

          <FooterHomePageWeb />
        </div>
      </>
    );
  }
}



// Customizable Area End
