// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export interface Props {
  navigation?: any;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  first_name: string;
  first_name_error: boolean;
  first_name_errorMsg: string;
  last_name: string;
  company_name: string;
  email_id: string;
  email_id_error: boolean;
  email_id_errorMsg: string;
  contact_number: string;
  contact_number_error: boolean;
  contact_number_errorMsg: string;
  helpText: string;
  isOpen: boolean;
  selectedCountry: any;
  dataSource: any;
  label: string;
  isSubmittedForm: boolean;
  isAuth: boolean;
  severity: string;
  snackbarMes: string;
  open: boolean;
  // Customizable Area End
}

interface SS { }

export const configJSON = require("./config.js");
const emailReg =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default class PrivateJetContactusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryCodeApiCallId: string = "";
  apiConactUsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      first_name: "",
      first_name_error: false,
      first_name_errorMsg: "",
      last_name: "",
      company_name: "",
      email_id: "",
      email_id_error: false,
      email_id_errorMsg: "",
      contact_number: "",
      contact_number_error: false,
      contact_number_errorMsg: "",
      helpText: "",
      isOpen: false,
      selectedCountry: 0,
      dataSource: [],
      label: "",
      isSubmittedForm: false,
      isAuth: false,
      severity: "",
      snackbarMes: "",
      open: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);
      if (apiRequestCallId === this.countryCodeApiCallId && responseJson) {
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson),
          selectedCountry: this.countryCodesToDropDown(responseJson)[0],
        });
      }
      else if (apiRequestCallId === this.apiConactUsCallId) {
        if (responseJson.data) {
          this.setState({
            isSubmittedForm: true,
          });
        } else {
          this.setState({
            open: true,
            severity: "error",
            snackbarMes: "Something wrong happened",
          });
        }

      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  async componentDidMount() {
    this.getCountryCode();
    this.getUserData();
  }
  getUserData = () => {
    const isUserLogin = localStorage.getItem('userInfo')
    if (isUserLogin && isUserLogin != null) {
      this.setState({
        isAuth: true
      })
    }
    else {
      this.setState({
        isAuth: false
      })
    }
  }
  validation = () => {
    const phoneReg = /^\d{10,18}$/;
    let allow: boolean = true;
    const { email_id, first_name, contact_number } = this.state;
    if (
      email_id.length === 0 ||
      first_name.length === 0 ||
      contact_number.length === 0
    ) {
      if (this.state.email_id === "") {
        this.setState({
          email_id_error: true,
          email_id_errorMsg: "Email address is required",
        });
      }
      if (!emailReg.test(email_id)) {
        this.setState({
          email_id_error: true,
          email_id_errorMsg: "Please enter a valid Email",
        });
      }
      if (this.state.first_name === "" || this.state.first_name.length === 0) {
        this.setState({
          first_name_error: true,
          first_name_errorMsg: "First name is required",
        });
      }
      if (
        this.state.contact_number === ""
      ) {
        this.setState({
          contact_number_error: true,
          contact_number_errorMsg: "Contact number is required",
        });
      }
      if (!phoneReg.test(contact_number)) {
        this.setState({
          contact_number_error: true,
          contact_number_errorMsg: "Please enter a valid number",
        });
      }
      allow = false;
    }
    return allow;
  };
  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: (
        <>
          <span>+{item.country_code}</span>
        </>
      ),
      value: item.country_code,
    }));
  };
  firstNameChangeHandler = (event: any) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        first_name_error: true,
        first_name_errorMsg: "First name is required",
        first_name: "",
      });
    } else {
      this.setState({
        first_name: event.target.value,
        first_name_error: false,
        first_name_errorMsg: "",
      });
    }
  };
  lastNameChangeHandler = (event: any) => {
    this.setState({
      last_name: event.target.value,
    });
  };
  companyNameChangeHandler = (event: any) => {
    this.setState({
      company_name: event.target.value,
    });
  };
  emailFieldOnchange = (event: any) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        email_id_error: true,
        email_id_errorMsg: "Please enter a valid Email",
        email_id: "",
      });
    } else if (!emailReg.test(value)) {
      this.setState({
        email_id_error: true,
        email_id_errorMsg: "Please enter a valid Email",
        email_id: event.target.value.trim(),
      });
    } else if (emailReg.test(value)) {
      this.setState({
        email_id_error: false,
        email_id_errorMsg: "",
        email_id: event.target.value.trim(),
      });
    }
  };
  emailFieldOnBlur = () => {
    if (this.state.email_id === null || this.state.email_id.length === 0) {
      this.setState({
        email_id_error: true,
        email_id_errorMsg: "Please enter a valid Email",
      });
    } else if (!emailReg.test(this.state.email_id)) {
      this.setState({
        email_id_error: true,
        email_id_errorMsg: "Please enter a valid Email",
      });
    } else {
      this.setState({
        email_id_error: false,
        email_id_errorMsg: "",
      });
    }
  };
  contactNumberFieldOnchange = (event: any) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        contact_number_error: true,
        contact_number_errorMsg: "Please enter a valid number",
        contact_number: "",
      });
    } else if (value.match(/^\d{10,18}$/)) {
      this.setState({
        contact_number_error: false,
        contact_number_errorMsg: "",
        contact_number: value,
      });
    } else {
      this.setState({
        contact_number_error: true,
        contact_number_errorMsg: "Please enter a valid number",
        contact_number: value,
      });
    }
  };
  contactNumberFieldOnBlur = () => {
    const phoneReg = /^\d{10,18}$/;
    if (
      this.state.contact_number === null ||
      this.state.contact_number.length === 0
    ) {
      this.setState({
        contact_number_error: true,
        contact_number_errorMsg: "Please enter a valid number",
      });
    } else if (!phoneReg.test(this.state.contact_number)) {
      this.setState({
        contact_number_error: true,
        contact_number_errorMsg: "Please enter a valid number",
      });
    } else {
      this.setState({
        contact_number_error: false,
        contact_number_errorMsg: "",
      });
    }
  };
  handleContactNumberDropdownClose = () => {
    this.setState({
      isOpen: false,
    });
  };
  handleChange = (item: any) => {
    this.setState({ label: item.label });
    this.setState({ selectedCountry: item });
  };
  countrycodeChangeHandler = (e: any) => {
    this.handleChange(e);
    this.setState({
      isOpen: false,
    });
  };
  handleContactNumberDropdownOpen = () => {
    this.setState({
      isOpen: true,
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  helpTextFieldOnchange = (event: any) => {
    this.setState({
      helpText: event.target.value,
    });
  };
  getCountryCode = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryEndPoint
    );
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSubmit = async (event: any) => {
    let allow: boolean = true;
    const {
      first_name,
      last_name,
      contact_number,
      email_id,
      company_name,
      helpText,
      selectedCountry,
      isSubmittedForm,
    } = this.state;
    event.preventDefault();
    allow = await this.validation();
    if (allow) {
      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
      };
      let data = {
        data: {
          first_name: first_name,
          last_name: last_name,
          email: email_id,
          phone_number: selectedCountry.value + contact_number,
          company_name: company_name,
          description: helpText,
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiConactUsCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  anotherRequestHandler = () => {
    this.setState({
      isSubmittedForm: false,
      first_name: "",
      first_name_error: false,
      first_name_errorMsg: "",
      last_name: "",
      company_name: "",
      email_id: "",
      email_id_error: false,
      email_id_errorMsg: "",
      contact_number: "",
      contact_number_error: false,
      contact_number_errorMsg: "",
      helpText: "",
    });
  };
  formSubmitHandler = async (event: any) => {
    const { isSubmittedForm } = this.state;
    event.preventDefault();
    if (isSubmittedForm) {
      this.anotherRequestHandler();
    } else {
      this.handleSubmit(event);
    }
  };
  bookNowHandler = () => {
    this.props.navigation.navigate("LandingPage");
  };
  getStartedHandler = () => {
    this.props.navigation.navigate("LandingPage");
  };
  // Customizable Area End
}
