Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleTextSignUp = "Sign Up as a Charterer or Operator";
exports.labelTitleTextLogin = "Log In as a Charterer or Operator";
exports.labelBodyText = "Luxury ahead, but first select your profile ";
exports.btnExampleTitleCreate = "Create ";
exports.btnExampleTitleAccount = " account";
exports.loginTitleTextCharterer = "Log In as Charterer "
exports.loginTitleTextOperator = "Log In as Operator"
exports.loginButtonText = "Log In "
exports.signUpButtonText= "Sign up"
exports.loginButtonCharterer = "Log In as Charterer"
exports.loginButtonOperator = "Log In as Operator"

exports.getSellersAPIMethod = "GET";
exports.homeCharterer = "bx_block_charterer_home_screen/charterer_home_screens"
exports.homeChartererTablets = "bx_block_charterer_home_screen/tablets"
exports.homeChartererBlogs = "bx_block_content_management/blogs"
exports.alreadyTextSigniin = "Already have an account? "
exports.alreadyTextSignup = "Don't have an account? "
// Customizable Area End
