import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  blogTabValue: number;
  blogTabValueTemp: number;
  boxTabValue: number;
  blogs: any;
  blog: any;
  last_index: boolean;
  last_index_val: number;
  blogsLength: boolean;
  blogFirstIndex: number;
  blogLastIndex: number;
  isLoading: boolean;
  blogId: any;
  // Customizable Area End
}

interface SS {}

export default class BlogDetailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  apiBlogsData: string = "";
  apiBlogData: string = "";

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      blogs: [],
      blog: [],
      blogTabValue: 1,
      blogTabValueTemp: 1,
      boxTabValue: 1,
      last_index: false,
      last_index_val: 0,
      blogsLength: true,
      blogFirstIndex: 0,
      blogLastIndex: 3,
      isLoading: false,
      blogId: null,
    };

    this.getBlogsData = this.getBlogsData.bind(this);
    this.getBlogsDetailData = this.getBlogsDetailData.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.apiBlogsData) {
          const json_data = responseJson.data;
          const length_json = json_data.length;

          this.setState({
            blogs: json_data.sort((a: any, b: any) => a.id - b.id),
            last_index_val: length_json,
            blogsLength: length_json > 0 ? false : true,
          });
        } else if (apiRequestCallId === this.apiBlogData) {
          this.setState(
            {
              blog: responseJson.data,
              isLoading: false,
            }
            ,
            () => this.countTablValue(responseJson.data.id)
          );
        } else {
          return null;
        }
      } else {
        alert("Something went wrong");
      }
    }
  }
  // Customizable Area End
  // Customizable Area Start

  async componentDidMount() {
    this.getBlogsData();
    const url = window.location.pathname;
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    const digit = parseInt(lastPart, 10);
    
    this.setState(
      { blogId: digit }, () => this.getBlogsDetailData(digit)
    );
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    const { blogs,blogId } = this.state;

    if (prevState.blogs !== blogs ) {
     this.countTablValue(blogId)
    }
    if (prevState.blogId !== blogId ) {
      const url = `/BlogDetail/${blogId}`;

      window.history.pushState(null, "", url);
      this.getBlogsDetailData(blogId);
    }
  }

  countTablValue = (id: any) => {
    if(this.state.blogs.length>0){
    let blogIndex = this.state.blogs.findIndex((x:any) => x.id == id)
      if(blogIndex >=2){
        this.setState({
          blogFirstIndex:2,
          blogLastIndex:5,
          blogTabValue:blogIndex-2,
          // blogId:id,
          blogTabValueTemp:blogIndex
        });
      }else{
        this.setState({
          // blogId:id,
          blogTabValue:blogIndex,
           blogFirstIndex:0,
          blogLastIndex:3,
          blogTabValueTemp:blogIndex

        });

      }
    }
  };
  handleTabNextButtonChange = () => {
    // this.setState({ blogId: this.state.blogs[this.state.blogTabValueTemp + 1]?.id});
    this.countTablValue(this.state.blogs[this.state.blogTabValueTemp + 1]?.id);
  };
  handleTabForwardButtonChange = () => {
    // this.setState({ blogId: this.state.blogs[this.state.blogTabValueTemp - 1]?.id});
    this.countTablValue(this.state.blogs[this.state.blogTabValueTemp - 1]?.id);
  };
  getBlogsData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBlogsData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blogAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getBlogsDetailData = (id: any) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBlogData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blogAPIEndpoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
