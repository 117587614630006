// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import ReservationFormController from "./ReservationFormController.web";
import "./ReservationForm.web.css"
export default class RegistrationForm extends ReservationFormController {
  render() {
    return (
      <Grid
        style={{ width: "100%", position: "relative" }}
        container
        direction="row"
      >
        <div className="wrap" data-pos="0">
          <div className="headbar">
            <i className="zmdi zmdi-arrow-left btnBack" />{" "}
            <span>Flight Booking App</span>
          </div>
          <div className="content">
            <section>
              <div className="form">
                <div className="control select">
                  <div className="control-head">
                    <i className="zmdi zmdi-flight-takeoff" />
                    <span className="close">
                      <i className="zmdi zmdi-close" />
                    </span>
                    <div>
                      <h6>From</h6>
                      <span className="airport-name" data-role="from">
                        DUB, Dublin Airport
                      </span>
                    </div>
                  </div>
                  <div className="control-body">
                    <ul className="select-index" />
                    <div className="nano">
                      <div className="nano-content">
                        <ul className="select-data" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control select">
                  <div className="control-head">
                    <i className="zmdi zmdi-flight-land" />
                    <span className="close">
                      <i className="zmdi zmdi-close" />
                    </span>
                    <div>
                      <h6>To</h6>
                      <span className="airport-name" data-role="to">
                        MRS, Marseille Provence Airport
                      </span>
                    </div>
                  </div>
                  <div className="control-body">
                    <ul className="select-index" />
                    <div className="nano">
                      <div className="nano-content">
                        <ul className="select-data" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control dateinput">
                  <div className="control-head">
                    <i className="zmdi zmdi-calendar" />
                    <span className="close">
                      <i className="zmdi zmdi-close" />
                    </span>
                    <div className="control-item">
                      <h6>Depar</h6>
                      <span>MON, 8 May</span>
                    </div>
                    <div className="control-item">
                      <h6>Return</h6>
                      <span>One Way</span>
                      {/* <!--Quitar si no se selecciona--> */}
                    </div>
                  </div>
                  <div className="control-body">
                    <div className="info-message">
                      <i className="zmdi zmdi-info" />
                      <span>
                        Select the depar date then the return date if you need a
                        round trip ticket
                      </span>
                      <span>
                        By the moment theres only One Way tickets, thanks.
                      </span>
                    </div>
                    <div className="calendar">
                      <div className="month">
                        <i className="zmdi zmdi-chevron-left" />
                        <span>May</span>
                        <i className="zmdi zmdi-chevron-right" />
                      </div>
                      <div className="week">
                        <span>S</span>
                        <span>M</span>
                        <span>T</span>
                        <span>W</span>
                        <span>T</span>
                        <span>F</span>
                        <span>S</span>
                      </div>
                      <div className="days" />
                    </div>
                  </div>
                </div>
                <div className="control radio passengers">
                  <i className="zmdi zmdi-accounts" />
                  <div className="control-item">
                    <h6>Passengers</h6>
                    <label>
                      <input type="radio" name="passengers" value="0" checked />
                      <div>
                        <span>1</span>&times;
                        <i className="zmdi zmdi-male-alt" />
                        <small>Adults</small>
                      </div>
                    </label>
                    <label>
                      <input type="radio" name="passengers" value="1" />
                      <div>
                        <span>0</span>&times;
                        <i className="zmdi zmdi-face" />
                        <small>Kids</small>
                      </div>
                    </label>
                    <label>
                      <input type="radio" name="passengers" value="2" />
                      <div>
                        <span>0</span>&times;
                        <i className="zmdi zmdi-walk" />
                        <small>Elders</small>
                      </div>
                    </label>
                  </div>
                  <section className="spinner">
                    <button data-action="plus">
                      <i className="zmdi zmdi-plus" />
                    </button>
                    <button data-action="minus">
                      <i className="zmdi zmdi-minus" />
                    </button>
                  </section>
                </div>
                <div className="control radio">
                  <i className="zmdi zmdi-airline-seat-recline-extra" />
                  <div className="control-item">
                    <h6 style={{ marginBottom: "8px" }}>className</h6>
                    <label>
                      <input type="radio" name="seat" value="Economy" checked />
                      <span>Economy</span>
                    </label>
                    <label>
                      <input type="radio" name="seat" value="Business" />
                      <span>Business</span>
                    </label>
                    <label>
                      <input type="radio" name="seat" value="First className" />
                      <span>First className</span>
                    </label>
                  </div>
                </div>
                <div className="control">
                  <button className="btnSearch">Search Flights</button>
                </div>
              </div>
              <div className="list">
                <div className="nano">
                  <div className="nano-content" />
                </div>
              </div>

              <div className="ticket">
                <section />
                <button className="btnBook">BOOK FLIGHT</button>
                <button className="btnHome">BACK TO HOME</button>
                <div className="loader">Loading...</div>
              </div>
            </section>
          </div>
        </div>
      </Grid>
    );
  }
}
// Customizable Area End
