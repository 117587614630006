// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AboutUs from '../../blocks/contactus/src/AboutUs/AboutUs';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import SearchHistory from '../../blocks/SearchHistory/src/SearchHistory';
import Customform from '../../blocks/customform/src/Customform';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import Analytics from '../../blocks/analytics/src/Analytics';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CfFlightInfo1 from '../../blocks/CfFlightInfo1/src/CfFlightInfo1';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions.web';
import PrivacyPolicy from '../../blocks/TermsAndConditions/src/PrivacyPolicy.web';
import Reservations from '../../blocks/Reservations/src/Reservations';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import HamburgerMenu from '../../blocks/HamburgerMenu/src/HamburgerMenu';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import HomePage from '../../blocks/landingpage/src/HomePage/HomePage.web';
// import HeaderHomePage from '../../blocks/landingpage/src/HeaderHomePage/HeaderHomePage.web';
import FooterHomePage from '../../blocks/landingpage/src/FooterHomePage/FooterHomePage.web';
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import Search from '../../blocks/search/src/Search';
import CarouselDisplay from '../../blocks/carouseldisplay/src/CarouselDisplay';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import MultipleCurrencySupport from '../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Payments from '../../blocks/Payments/src/Payments';
import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
import Geofence from '../../blocks/geofence/src/Geofence';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import ItemAvailability from '../../blocks/ItemAvailability/src/ItemAvailability';
import CfFlexApi2 from '../../blocks/CfFlexApi2/src/CfFlexApi2';
import ProductDescription from '../../blocks/ProductDescription/src/ProductDescription';
import TermsAndConditions3 from '../../blocks/TermsAndConditions3/src/TermsAndConditions3';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Sorting from '../../blocks/sorting/src/Sorting';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import InventoryManagement2 from '../../blocks/InventoryManagement2/src/InventoryManagement2';
import ImageThumbnail from '../../blocks/ImageThumbnail/src/ImageThumbnail';
import OperatorReservationForm from '../../blocks/customform/src/OperatorReservationForm/OperatorReservationForm.web';
import ReservationForm from '../../blocks/customform/src/FlightReservationForm/ReservationForm.web';
import OperatorLoginForm from '../../blocks/customform/src/OperatorLoginForm/OperatorLoginForm.web'
import HomeScreenCharterer from '../../blocks/landingpage/src/HomeScreenCharterer/homescreencharterer.web'
import ChartererLoginForm from '../../blocks/customform/src/ChartererLoginForm/ChartererLoginForm.web'
import BlogHome from '../../blocks/catalogue/src/BlogHome/blogHome.web';
import ChartererReservationForm from '../../blocks/customform/src/ChartererReservationForm/ChartererReservationForm.web';
import BlogDetail from "../../blocks/catalogue/src/BlogDetail/blogDetail.web"
import PrivateJetContactus from '../../blocks/contactus/src/PrivateJetContactus.web';
import TopNav from '../../components/src/TopNav';
import AppHeader from '../../components/src/AppHeader.web'
import { watermark, watermarkTribe } from '../../blocks/customform/src/assets';
import HomeHeader from '../../components/src/HomeHeader.web';
const routeMap = {
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  InventoryManagement2: {
    component: InventoryManagement2,
    path: '/InventoryManagement2'
  },
  ImageThumbnail: {
    component: ImageThumbnail,
    path: '/ImageThumbnail'
  },

  CarouselDisplay: {
    component: CarouselDisplay,
    path: '/CarouselDisplay'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  MultipleCurrencySupport: {
    component: MultipleCurrencySupport,
    path: '/MultipleCurrencySupport'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: '/PaymentAdmin'
  },
  Geofence: {
    component: Geofence,
    path: '/Geofence'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  ItemAvailability: {
    component: ItemAvailability,
    path: '/ItemAvailability'
  },
  CfFlexApi2: {
    component: CfFlexApi2,
    path: '/CfFlexApi2'
  },
  ProductDescription: {
    component: ProductDescription,
    path: '/ProductDescription'
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: '/TermsAndConditions3'
  },

  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },

  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  SearchHistory: {
    component: SearchHistory,
    path: '/SearchHistory'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CfFlightInfo1: {
    component: CfFlightInfo1,
    path: '/CfFlightInfo1'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  Reservations: {
    component: Reservations,
    path: '/Reservations'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  HamburgerMenu: {
    component: HamburgerMenu,
    path: '/HamburgerMenu'
  },
  OperatorReservationForm: {
    component: OperatorReservationForm,
    path: '/OperatorReservationForm',
    exact: true
  },
  OperatorLoginForm: {
    component: OperatorLoginForm,
    path: '/OperatorLogin',
    exact: true
  },
  ChartererLoginForm: {
    component: ChartererLoginForm,
    path: '/ChartererLogin',
    exact: true
  },
  ChartererReservationForm: {
    component: ChartererReservationForm,
    path: '/ChartererReservation',
    exact: true
  },
  FlightReservationForm: {
    component: ReservationForm,
    path: '/ReservationForm',
    exact: true
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard',
    exact: true
  },
  HomePage: {
    component: HomePage,
    path: '/',
    exact: true
  },
  // HeaderHomePage: {
  //   component: HeaderHomePage,
  //   path: '/HeaderHomePage',
  //   exact: true
  // },
  FooterHomePage: {
    component: FooterHomePage,
    path: '/FooterHomePage',
    exact: true
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage/:id',
    exact: true

  },
  Search: {
    component: Search,
    path: '/Search'
  },

  Home: {
    component: HomeScreen,
    path: '/HomeScreen',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AboutUs: {
    component: AboutUs,
    path: '/aboutus',
    exact: true
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  HomeScreenCharterer: {
    component: HomeScreenCharterer,
    path: '/HomeScreenCharterer'
  },
  BlogHome: {
    component: BlogHome,
    path: '/BlogHome'
  },
  PrivateJetContactus: {
    component: PrivateJetContactus,
    path: '/Contact-us',
    exact: true
  },
  BlogDetail: {
    component: BlogDetail,
    path: '/BlogDetail/:id',
    exact: true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {

  state = {
    activePath: ''
  }

  componentDidMount() {
    const routeEndPoint = window.location.pathname
    const pathEndPoint = routeEndPoint.split('/')
    this.setState({ activePath: window.location.pathname === '/' ? '/' : pathEndPoint[1] })
  };

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const homeHeaderRouteArray = ['/', 'Contact-us', 'aboutus', 'BlogHome', 'BlogDetail', 'TermsAndConditions', 'PrivacyPolicy',]
    const appHeaderRouteArray = ['LandingPage', 'OperatorLogin', 'OperatorReservationForm', 'ChartererReservation', 'ChartererLogin']
    const watermarckImageRouteArray = ["LandingPage", 'OperatorLogin', 'OperatorReservationForm', 'ChartererReservation', 'ChartererLogin']
    return (
      <View style={{ backgroundColor: "#150a1f", overflowY: "scroll", position: 'relative' }}>
        {/* <TopNav /> */}
        <div style={{ position: 'sticky', width: '100%', zIndex: 99999, height: 0, top: 0, left: 0, }}>
          {homeHeaderRouteArray.includes(this.state.activePath) ?
            <HomeHeader />
            :
            <>
              {appHeaderRouteArray.includes(this.state.activePath) && <AppHeader />}
            </>
          }
        </div>

        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
