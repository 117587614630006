import React from "react";
// Customizable Area Start
import { View } from "react-native";
import HomeScreenChatererController, {
  Props
} from "./homeScreenChatererController";
import {
  mainImage,
  passengers_icon,
  luggage_icon,
  arrow_down_icon,
  elevate_image,
  flyprivate_image
} from "../assets";
import "./homeScreenChatererController";
import "./homescreencharterer.web.css";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CallMadeIcon from "@material-ui/icons/CallMade";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import LightJet from "./lightJet.web";
import ReasonComponent from "./reasonComponent.web";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MidSizejet from "./midSizeJet.web";
import SuperMidSizejet from "./superMidSizeJet.web";
import HeavyJet from "./heavyJet.web";
import UltraLongRangeJet from "./ultraLongRangeJet.web";
import VVIPAirlinerJet from "./VVIPAIrlinerJet.web";
import FooterHomePageWeb from "../FooterHomePage/FooterHomePage.web";
import HomeHeader from "../../../../components/src/HomeHeader.web";
import LeftBlog from "../../../catalogue/src/BlogHome/leftBlog.web";
import HomePageoneWayBoxComponent from "../homePageoneWayBoxComponent.web";

const tabStyle = {
  default_tab: {
    color: "#F2DCC4",
    backgroundColor: "#150A1F",
    fontSize: 16
  },
  active_tab: {
    color: "#150A1F",
    backgroundColor: "#F2DCC4",
    fontSize: 16,
    borderRadius: "28px"
  }
};

const getStyle = (isActive: any) => {
  return isActive ? tabStyle.active_tab : tabStyle.default_tab;
};

export function TabPanel(props: any) {
  const { value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === 0 && <HomePageoneWayBoxComponent />}

      {value === 1 && <HomePageoneWayBoxComponent />}

      {value === 2 && <HomePageoneWayBoxComponent />}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export function MainTabPanel(props: any) {
  const { value, index, ...other } = props;
  const { section_1_heading, section_2_heading, section_3_heading } = props;
  const {
    section_1_description,
    section_2_description,
    section_3_description
  } = props;
  const { section_1_passengers, section_1_max_range, section_1_speed } = props;
  const { section_2_passengers, section_2_max_range, section_2_speed } = props;
  const { section_3_passengers, section_3_max_range, section_3_speed } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === 0 && (
        <LightJet
          navigation={undefined}
          id={""}
          tablet_section_1_heading={section_1_heading}
          tablet_section_1_description={section_1_description}
          tablet_section_1_passengers={section_1_passengers}
          tablet_section_1_max_range={section_1_max_range}
          tablet_section_1_speed={section_1_speed}
          tablet_section_2_heading={section_2_heading}
          tablet_section_2_description={section_2_description}
          tablet_section_2_passengers={section_2_passengers}
          tablet_section_2_max_range={section_2_max_range}
          tablet_section_2_speed={section_2_speed}
          tablet_section_3_heading={section_3_heading}
          tablet_section_3_description={section_3_description}
          tablet_section_3_passengers={section_3_passengers}
          tablet_section_3_max_range={section_3_max_range}
          tablet_section_3_speed={section_3_speed}
        />
      )}

      {value === 1 && (
        <MidSizejet
          navigation={undefined}
          id={""}
          tablet_section_1_heading={section_1_heading}
          tablet_section_1_description={section_1_description}
          tablet_section_1_passengers={section_1_passengers}
          tablet_section_1_max_range={section_1_max_range}
          tablet_section_1_speed={section_1_speed}
          tablet_section_2_heading={section_2_heading}
          tablet_section_2_description={section_2_description}
          tablet_section_2_passengers={section_2_passengers}
          tablet_section_2_max_range={section_2_max_range}
          tablet_section_2_speed={section_2_speed}
          tablet_section_3_heading={section_3_heading}
          tablet_section_3_description={section_3_description}
          tablet_section_3_passengers={section_3_passengers}
          tablet_section_3_max_range={section_3_max_range}
          tablet_section_3_speed={section_3_speed}
        />
      )}

      {value === 2 && (
        <SuperMidSizejet
          navigation={undefined}
          id={""}
          tablet_section_1_heading={section_1_heading}
          tablet_section_1_description={section_1_description}
          tablet_section_1_passengers={section_1_passengers}
          tablet_section_1_max_range={section_1_max_range}
          tablet_section_1_speed={section_1_speed}
          tablet_section_2_heading={section_2_heading}
          tablet_section_2_description={section_2_description}
          tablet_section_2_passengers={section_2_passengers}
          tablet_section_2_max_range={section_2_max_range}
          tablet_section_2_speed={section_2_speed}
          tablet_section_3_heading={section_3_heading}
          tablet_section_3_description={section_3_description}
          tablet_section_3_passengers={section_3_passengers}
          tablet_section_3_max_range={section_3_max_range}
          tablet_section_3_speed={section_3_speed}
        />
      )}

      {value === 3 && (
        <HeavyJet
          navigation={undefined}
          id={""}
          tablet_section_1_heading={section_1_heading}
          tablet_section_1_description={section_1_description}
          tablet_section_1_passengers={section_1_passengers}
          tablet_section_1_max_range={section_1_max_range}
          tablet_section_1_speed={section_1_speed}
          tablet_section_2_heading={section_2_heading}
          tablet_section_2_description={section_2_description}
          tablet_section_2_passengers={section_2_passengers}
          tablet_section_2_max_range={section_2_max_range}
          tablet_section_2_speed={section_2_speed}
          tablet_section_3_heading={section_3_heading}
          tablet_section_3_description={section_3_description}
          tablet_section_3_passengers={section_3_passengers}
          tablet_section_3_max_range={section_3_max_range}
          tablet_section_3_speed={section_3_speed}
        />
      )}

      {value === 4 && (
        <UltraLongRangeJet
          navigation={undefined}
          id={""}
          tablet_section_1_heading={section_1_heading}
          tablet_section_1_description={section_1_description}
          tablet_section_1_passengers={section_1_passengers}
          tablet_section_1_max_range={section_1_max_range}
          tablet_section_1_speed={section_1_speed}
          tablet_section_2_heading={section_2_heading}
          tablet_section_2_description={section_2_description}
          tablet_section_2_passengers={section_2_passengers}
          tablet_section_2_max_range={section_2_max_range}
          tablet_section_2_speed={section_2_speed}
          tablet_section_3_heading={section_3_heading}
          tablet_section_3_description={section_3_description}
          tablet_section_3_passengers={section_3_passengers}
          tablet_section_3_max_range={section_3_max_range}
          tablet_section_3_speed={section_3_speed}
        />
      )}

      {value === 5 && (
        <VVIPAirlinerJet
          navigation={undefined}
          id={""}
          tablet_section_1_heading={section_1_heading}
          tablet_section_1_description={section_1_description}
          tablet_section_1_passengers={section_1_passengers}
          tablet_section_1_max_range={section_1_max_range}
          tablet_section_1_speed={section_1_speed}
          tablet_section_2_heading={section_2_heading}
          tablet_section_2_description={section_2_description}
          tablet_section_2_passengers={section_2_passengers}
          tablet_section_2_max_range={section_2_max_range}
          tablet_section_2_speed={section_2_speed}
          tablet_section_3_heading={section_3_heading}
          tablet_section_3_description={section_3_description}
          tablet_section_3_passengers={section_3_passengers}
          tablet_section_3_max_range={section_3_max_range}
          tablet_section_3_speed={section_3_speed}
        />
      )}
    </div>
  );
}

MainTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  section_1_heading: PropTypes.any,
  section_1_description: PropTypes.any,
  section_1_passengers: PropTypes.any,
  section_1_max_range: PropTypes.any,
  section_1_speed: PropTypes.any,
  section_2_heading: PropTypes.any,
  section_2_description: PropTypes.any,
  section_2_passengers: PropTypes.any,
  section_2_max_range: PropTypes.any,
  section_2_speed: PropTypes.any,
  section_3_heading: PropTypes.any,
  section_3_description: PropTypes.any,
  section_3_passengers: PropTypes.any,
  section_3_max_range: PropTypes.any,
  section_3_speed: PropTypes.any
};

export function BlogTabPanel(props: any) {
  const { value, index, ...other } = props;
  const { blogs } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === 0 && (
        <>
          {blogs.length != 0 ? (
            <LeftBlog
              title={blogs[0].attributes.title}
              description={
                blogs[0].attributes.content.substring(0, 300) + "..."
              }
              image={blogs[0].attributes.image}
              last_index={true}
              id={"/BlogDetail/" + blogs[0].id}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {value === 1 && (
        <>
          {blogs.length != 0 ? (
            <LeftBlog
              title={blogs[1].attributes.title}
              description={
                blogs[1].attributes.content.substring(0, 300) + "..."
              }
              image={blogs[1].attributes.image}
              last_index={true}
              id={"/BlogDetail/" + blogs[1].id}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {value === 2 && (
        <>
          {blogs.length != 0 ? (
            <LeftBlog
              title={blogs[2].attributes.title}
              description={
                blogs[2].attributes.content.substring(0, 300) + "..."
              }
              image={blogs[2].attributes.image}
              last_index={true}
              id={"/BlogDetail/" + blogs[2].id}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

BlogTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  blogs: PropTypes.any
};

export default class HomeScreenCharterer extends HomeScreenChatererController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <>
        <Box
          style={{
            position: "sticky",
            width: "100%",
            zIndex: 99999,
            height: 0,
            top: 0,
            left: 0
          }}
        >
          <HomeHeader />
        </Box>
        <Box style={{ backgroundColor: "#150A1F" }}>
          <Box className="image-containerLanding">
            <Grid container style={{ position: "relative" }}>
              <Grid
                item
                xs={10}
                lg={10}
                md={10}
                style={{ margin: "auto", zIndex: 22 }}
              >
                <Box className="mainText">
                  <div className="h2Heading">{this.state.main_heading_1}</div>
                  <div className="h1Heading">{this.state.main_heading_2}</div>
                  <div className="textHeading" id="searchFocus_Id">
                    {this.state.main_heading_sub_heading}
                  </div>
                </Box>
              </Grid>
              <Grid
                item
                xs={10}
                lg={10}
                md={10}
                style={{ margin: "auto", zIndex: 22 }}
              >
                <Box>
                  <Grid container style={{ position: "relative" }}>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      style={{ margin: "auto" }}
                    >
                      <Box className="mainBoxHome">
                        <Grid
                          container
                          style={{
                            alignItems: "center",
                            borderBottom: "1px solid #8a848f"
                          }}
                        >
                          <Grid item lg={4} md={6} sm={12}>
                            <Box>
                              <Grid container>
                                <AppBar
                                  position="static"
                                  className="homePageboxTab"
                                >
                                  <Tabs
                                    value={this.state.boxTabValue}
                                    onChange={this.handleChangeBoxTab}
                                    aria-label="simple tabs example"
                                    variant="fullWidth"
                                    style={{ borderBottom: "none" }}
                                    TabIndicatorProps={{
                                      style: {
                                        backgroundColor: "white",
                                        color: "white"
                                      }
                                    }}
                                  >
                                    <Tab
                                      disableRipple
                                      className="tabExamples"
                                      label="One-way"                                      
                                      onClick={() => {
                                        this.setState({ boxTabValue: 0 });
                                      }}
                                    />
                                    <Tab
                                      disableRipple
                                      className="tabExamples"
                                      label="Round trip"
                                      onClick={() => {
                                        this.setState({ boxTabValue: 1 });
                                      }}
                                    />
                                    <Tab
                                      disableRipple
                                      className="tabExamples"
                                      label="Multi-leg"                                    
                                      onClick={() => {
                                        this.setState({ boxTabValue: 2 });
                                      }}
                                    />
                                  </Tabs>
                                </AppBar>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item lg={8} md={6} sm={11}>
                            <Grid container>
                              <Grid item md={2} sm={2} lg={6} />
                              <Grid
                                item
                                md={5}
                                sm={5}
                                lg={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}
                              >
                                <IconButton className="boxButton1">
                                  <img
                                    src={passengers_icon}
                                    height="20px"
                                    width={"20px"}
                                  />
                                  <span className="boxIcon">Passenger(s)</span>
                                  <img
                                    src={arrow_down_icon}
                                    height="10px"
                                    width={"10px"}
                                  />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                md={5}
                                sm={5}
                                lg={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <IconButton className="boxButton1">
                                  <img
                                    src={luggage_icon}
                                    height="20px"
                                    width={"20px"}
                                  />
                                  <span className="boxIcon">Luggage</span>
                                  <img
                                    src={arrow_down_icon}
                                    height="10px"
                                    width={"10px"}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box style={{ marginTop: "40px" }}>
                          <TabPanel value={this.state.boxTabValue} index={0}>
                            One-way
                          </TabPanel>
                          <TabPanel value={this.state.boxTabValue} index={1}>
                            Round trip
                          </TabPanel>
                          <TabPanel value={this.state.boxTabValue} index={2}>
                            Multi-leg
                          </TabPanel>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <img src={mainImage} alt="planeimg" className="homepageImage" />
          </Box>
        </Box>

        <View style={{ backgroundColor: "#150A1F" }}>
            <Grid container style={{ position: "relative"}}>
              <Grid item md={10} lg={10} sm={12} xs={12} style={{margin: "auto"}}>
            <div className="textLabel">
              <div>{this.state.explore_heading_1}</div>
              <div>{this.state.explore_heading_2}</div>
              <div className="textLabel1">
                {this.state.explore_heading_sub_heading}
              </div>
            </div>

            <div>
              <AppBar position="static" className="mainTab">
                <Tabs
                  value={this.state.mainTabValue}
                  onChange={this.handleChangeMainTab}
                  variant="scrollable"
                  scrollButtons="off"
                  aria-label="scrollable auto tabs example"
                  TabIndicatorProps={{
                    style: {
                      display: "none"
                    }
                  }}
                >
                  <Tab
                    label="Light Jet"
                    style={getStyle(this.state.mainTabValue === 0)}
                    className="mainTabExample"
                    onClick={() => {
                      this.setState({ mainTabValue: 0 });
                    }}
                  />
                  <Tab
                    label="Midsize Jet"
                    style={getStyle(this.state.mainTabValue === 1)}
                    className="mainTabExample"
                    onClick={() => {
                      this.setState({ mainTabValue: 1 });
                    }}
                  />
                  <Tab
                    label="Super-Midsize Jet"
                    style={getStyle(this.state.mainTabValue === 2)}
                    className="mainTabExample"
                    onClick={() => {
                      this.setState({ mainTabValue: 2 });
                    }}
                  />
                  <Tab
                    label="Heavy Jet"
                    style={getStyle(this.state.mainTabValue === 3)}
                    className="mainTabExample"
                    onClick={() => {
                      this.setState({ mainTabValue: 3 });
                    }}
                  />
                  <Tab
                    label="Ultra Long Range"
                    style={getStyle(this.state.mainTabValue === 4)}
                    className="mainTabExample"
                    onClick={() => {
                      this.setState({ mainTabValue: 4 });
                    }}
                  />
                  <Tab
                    label="VVIP Airliner"
                    style={getStyle(this.state.mainTabValue === 5)}
                    className="mainTabExample"
                    onClick={() => {
                      this.setState({ mainTabValue: 5 });
                    }}
                  />
                </Tabs>
              </AppBar>
            </div>

            <div style={{ marginTop: "100px" }}>
              <MainTabPanel
                value={this.state.mainTabValue}
                index={0}
                section_1_heading={this.state.tablet_1_section_1_heading}
                section_1_description={
                  this.state.tablet_1_section_1_description
                }
                section_1_passengers={this.state.tablet_1_section_1_passengers}
                section_1_max_range={this.state.tablet_1_section_1_max_range}
                section_1_speed={this.state.tablet_1_section_1_speed}
                section_2_heading={this.state.tablet_1_section_2_heading}
                section_2_description={
                  this.state.tablet_1_section_2_description
                }
                section_2_passengers={this.state.tablet_1_section_2_passengers}
                section_2_max_range={this.state.tablet_1_section_2_max_range}
                section_2_speed={this.state.tablet_1_section_2_speed}
                section_3_heading={this.state.tablet_1_section_3_heading}
                section_3_description={
                  this.state.tablet_1_section_3_description
                }
                section_3_passengers={this.state.tablet_1_section_3_passengers}
                section_3_max_range={this.state.tablet_1_section_3_max_range}
                section_3_speed={this.state.tablet_1_section_3_speed}
              >
                Light Jet
              </MainTabPanel>
              <MainTabPanel
                value={this.state.mainTabValue}
                index={1}
                section_1_heading={this.state.tablet_2_section_1_heading}
                section_1_description={
                  this.state.tablet_2_section_1_description
                }
                section_1_passengers={this.state.tablet_2_section_1_passengers}
                section_1_max_range={this.state.tablet_2_section_1_max_range}
                section_1_speed={this.state.tablet_2_section_1_speed}
                section_2_heading={this.state.tablet_2_section_2_heading}
                section_2_description={
                  this.state.tablet_2_section_2_description
                }
                section_2_passengers={this.state.tablet_2_section_2_passengers}
                section_2_max_range={this.state.tablet_2_section_2_max_range}
                section_2_speed={this.state.tablet_2_section_2_speed}
                section_3_heading={this.state.tablet_2_section_3_heading}
                section_3_description={
                  this.state.tablet_2_section_3_description
                }
                section_3_passengers={this.state.tablet_2_section_3_passengers}
                section_3_max_range={this.state.tablet_2_section_3_max_range}
                section_3_speed={this.state.tablet_2_section_3_speed}
              >
                Midsize Jet
              </MainTabPanel>

              <MainTabPanel
                value={this.state.mainTabValue}
                index={2}
                section_1_heading={this.state.tablet_3_section_1_heading}
                section_1_description={
                  this.state.tablet_3_section_1_description
                }
                section_1_passengers={this.state.tablet_3_section_1_passengers}
                section_1_max_range={this.state.tablet_3_section_1_max_range}
                section_1_speed={this.state.tablet_3_section_1_speed}
                section_2_heading={this.state.tablet_3_section_2_heading}
                section_2_description={
                  this.state.tablet_3_section_2_description
                }
                section_2_passengers={this.state.tablet_3_section_2_passengers}
                section_2_max_range={this.state.tablet_3_section_2_max_range}
                section_2_speed={this.state.tablet_3_section_2_speed}
                section_3_heading={this.state.tablet_3_section_3_heading}
                section_3_description={
                  this.state.tablet_3_section_3_description
                }
                section_3_passengers={this.state.tablet_3_section_3_passengers}
                section_3_max_range={this.state.tablet_3_section_3_max_range}
                section_3_speed={this.state.tablet_3_section_3_speed}
              >
                Super-Midsize Jet
              </MainTabPanel>

              <MainTabPanel
                value={this.state.mainTabValue}
                index={3}
                section_1_heading={this.state.tablet_4_section_1_heading}
                section_1_description={
                  this.state.tablet_4_section_1_description
                }
                section_1_passengers={this.state.tablet_4_section_1_passengers}
                section_1_max_range={this.state.tablet_4_section_1_max_range}
                section_1_speed={this.state.tablet_4_section_1_speed}
                section_2_heading={this.state.tablet_4_section_2_heading}
                section_2_description={
                  this.state.tablet_4_section_2_description
                }
                section_2_passengers={this.state.tablet_4_section_2_passengers}
                section_2_max_range={this.state.tablet_4_section_2_max_range}
                section_2_speed={this.state.tablet_4_section_2_speed}
                section_3_heading={this.state.tablet_4_section_3_heading}
                section_3_description={
                  this.state.tablet_4_section_3_description
                }
                section_3_passengers={this.state.tablet_4_section_3_passengers}
                section_3_max_range={this.state.tablet_4_section_3_max_range}
                section_3_speed={this.state.tablet_4_section_3_speed}
              >
                Heavy Jet
              </MainTabPanel>

              <MainTabPanel
                value={this.state.mainTabValue}
                index={4}
                section_1_heading={this.state.tablet_5_section_1_heading}
                section_1_description={
                  this.state.tablet_5_section_1_description
                }
                section_1_passengers={this.state.tablet_5_section_1_passengers}
                section_1_max_range={this.state.tablet_5_section_1_max_range}
                section_1_speed={this.state.tablet_5_section_1_speed}
                section_2_heading={this.state.tablet_5_section_2_heading}
                section_2_description={
                  this.state.tablet_5_section_2_description
                }
                section_2_passengers={this.state.tablet_5_section_2_passengers}
                section_2_max_range={this.state.tablet_5_section_2_max_range}
                section_2_speed={this.state.tablet_5_section_2_speed}
                section_3_heading={this.state.tablet_5_section_3_heading}
                section_3_description={
                  this.state.tablet_5_section_3_description
                }
                section_3_passengers={this.state.tablet_5_section_3_passengers}
                section_3_max_range={this.state.tablet_5_section_3_max_range}
                section_3_speed={this.state.tablet_5_section_3_speed}
              >
                Ultra Long Range
              </MainTabPanel>

              <MainTabPanel
                value={this.state.mainTabValue}
                index={5}
                section_1_heading={this.state.tablet_6_section_1_heading}
                section_1_description={
                  this.state.tablet_6_section_1_description
                }
                section_1_passengers={this.state.tablet_6_section_1_passengers}
                section_1_max_range={this.state.tablet_6_section_1_max_range}
                section_1_speed={this.state.tablet_6_section_1_speed}
                section_2_heading={this.state.tablet_6_section_2_heading}
                section_2_description={
                  this.state.tablet_6_section_2_description
                }
                section_2_passengers={this.state.tablet_6_section_2_passengers}
                section_2_max_range={this.state.tablet_6_section_2_max_range}
                section_2_speed={this.state.tablet_6_section_2_speed}
                section_3_heading={this.state.tablet_6_section_3_heading}
                section_3_description={
                  this.state.tablet_6_section_3_description
                }
                section_3_passengers={this.state.tablet_6_section_3_passengers}
                section_3_max_range={this.state.tablet_6_section_3_max_range}
                section_3_speed={this.state.tablet_6_section_3_speed}
              >
                VVIP Airliner
              </MainTabPanel>
            </div>

            <div className="moreText">and many more...</div>
          </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>
          <ReasonComponent
            reason_heading_1={this.state.reason_heading_1}
            reason_heading_2={this.state.reason_heading_2}
            reason_heading_sub_heading={this.state.reason_heading_sub_heading}
            reason_1_heading={this.state.reason_1_heading}
            reason_1_description={this.state.reason_1_description}
            reason_2_heading={this.state.reason_2_heading}
            reason_2_description={this.state.reason_2_description}
            reason_3_heading={this.state.reason_3_heading}
            reason_3_description={this.state.reason_3_description}
            reason_4_heading={this.state.reason_4_heading}
            reason_4_description={this.state.reason_4_description}
          />
          </Grid>
          </Grid>
        </View>

        <View style={{ backgroundColor: "#150A1F" }}>
          <div style={{ margin: "20px" }}>
            <img src={elevate_image} width="100%" height="100%" />
          </div>

          <div className="elevateText">{this.state.image_heading}</div>
        </View>

        <View style={{ backgroundColor: "#150A1F" }}>
          <Container style={{maxWidth:"85%"}}>
          <Box style={{ marginTop: "100px" }}>
            <BlogTabPanel
              value={this.state.blogTabValue}
              index={0}
              blogs={this.state.blogs}
            >
              Blog 1
            </BlogTabPanel>
            <BlogTabPanel
              value={this.state.blogTabValue}
              index={1}
              blogs={this.state.blogs}
            >
              Blog 2
            </BlogTabPanel>
            <BlogTabPanel
              value={this.state.blogTabValue}
              index={2}
              blogs={this.state.blogs}
            >
              Blog 3
            </BlogTabPanel>
          </Box>
          </Container>
          {this.state.blogs && (
            <Grid container className="blogTab">
              <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>
                <Grid container spacing={3}>
                  {this.state.blogs.map((blog_Data: any, index: number) => (
                    <Grid item xs={3} lg={3} md={3} key={index}>
                      <Box
                        style={{
                          marginBottom: "30px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          alignItems: "center"
                        }}
                        onClick={() => {
                          this.setState({ blogTabValue: index });
                        }}
                      >
                        <img
                          src={blog_Data.attributes.image}
                          width="48"
                          height="48"
                          style={{
                            borderRadius: "50%"
                          }}
                        />
                        <Typography className="homePageBlogTabExample">
                          {blog_Data.attributes.title.substring(0, 40) + "..."}
                        </Typography>
                      </Box>
                      <Box
                        className={`${this.state.blogTabValue === index &&
                          "blogTabGridBorder"}`}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={3} lg={3} md={3} style={{ textAlign: "end" }}>
                    <Button
                      variant="contained"
                      className="viewallBtn"
                      disableRipple
                      href="/BlogHome"
                    >
                      <Box className="viewallBtnBox">
                        <Typography className="viewallBtnTxt">
                          View all
                        </Typography>
                        <ArrowForwardIcon className="arrowForwardIcon" />
                      </Box>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Container>
            <div
              style={{
                // margin: "200px 0px 200px 0px",
                marginBottom: "200px",
                marginTop: "230px",
                textAlign: "center"
              }}
            >
              <img src={flyprivate_image} width="75%" height="35%" />
              <br />
              <Button
                variant="contained"
                className="bookNowBtn"
                disableRipple
                onClick={() => {
                  const element: any = document.getElementById(
                    "searchFocus_Id"
                  );
                  element.scrollIntoView();
                }}
              >
                <Box className="bookNowBtnBox">
                  <Typography className="BookBtnTxt">Book now</Typography>
                  <CallMadeIcon className="callMadeIcon" />
                </Box>
              </Button>
            </div>
          </Container>
        </View>

        <FooterHomePageWeb />
      </>
    );
  }
}
// Customizable Area End
