export const mainImage = require("../assets/mainImage.png");
export const passengers_icon = require("../assets/passengers_icon.png");
export const luggage_icon = require("../assets/Luggage_icons.png");
export const arrow_down_icon = require("../assets/arrow_down_icon.png");
export const calender_icons = require("../assets/calendar_icons.png");
export const From_icon = require("../assets/From_icon.png");
export const To_icon = require("../assets/To_icon.png");
export const profile_icon = require("../assets/Profile_icon_normal.png");
export const profile_hovered = require("../assets/Profile_icon_hovered.png");
export const light_jet_1_1 = require("../assets/light_jet_1-1.png");
export const light_jet_1_2 = require("../assets/Light_jet_1_2.png");
export const light_jet_1_3 = require("../assets/Light_jet_1_3.png");
export const light_jet_2_1 = require("../assets/light_jet_2-1.png");
export const light_jet_2_2 = require("../assets/Light_jet_2_2.png");
export const light_jet_2_3 = require("../assets/Light_jet_2_3.png");
export const light_jet_3_1 = require("../assets/light_jet_3-1.png");
export const light_jet_3_2 = require("../assets/Light_jet_3_2.png");
export const light_jet_3_3 = require("../assets/Light_jet_3_3.png");
export const Midsize_jet_1_1 = require("../assets/Midsize_jet_1_1.png");
export const Midsize_jet_1_2 = require("../assets/Midsize_jet_1_2.png");
export const Midsize_jet_1_3 = require("../assets/Midsize_jet_1_3.png");
export const Midsize_jet_2_1 = require("../assets/Midsize_jet_2_1.png");
export const Midsize_jet_2_2 = require("../assets/Midsize_jet_2_2.png");
export const Midsize_jet_3_1 = require("../assets/Midsize_jet_3_1.png");
export const Midsize_jet_3_2 = require("../assets/Midsize_jet_3_2.png");
export const Midsize_jet_3_3 = require("../assets/Midsize_jet_3_3.png");
export const Super_midsize_jet_1_1 = require("../assets/Super_midsize_jet_1_1.png");
export const Super_midsize_jet_1_2 = require("../assets/Super_midsize_jet_1_2.png");
export const Super_midsize_jet_1_3 = require("../assets/Super_midsize_jet_1_3.png");
export const Super_midsize_jet_2_1 = require("../assets/Super_midsize_jet_2_1.png");
export const Super_midsize_jet_2_2 = require("../assets/Super_midsize_jet_2_2.png");
export const Super_midsize_jet_2_3 = require("../assets/Super_midsize_jet_2_3.png");
export const Super_midsize_jet_3_1 = require("../assets/Super_midsize_jet_3_1.png");
export const Super_midsize_jet_3_2 = require("../assets/Super_midsize_jet_3_2.png");
export const Super_midsize_jet_3_3 = require("../assets/Super_midsize_jet_3_3.png");
export const Heavy_jet_1_1 = require("../assets/Heavy_jet_1_1.png");
export const Heavy_jet_1_2 = require("../assets/Heavy_jet_1_2.png");
export const Heavy_jet_1_3 = require("../assets/Heavy_jet_1_3.png");
export const Heavy_jet_2_1 = require("../assets/Heavy_jet_2_1.png");
export const Heavy_jet_2_2 = require("../assets/Heavy_jet_2_2.png");
export const Heavy_jet_2_3 = require("../assets/Heavy_jet_2_3.png");
export const Heavy_jet_3_1 = require("../assets/Heavy_jet_3_1.png");
export const Heavy_jet_3_2 = require("../assets/Heavy_jet_3_2.png");
export const Heavy_jet_3_3 = require("../assets/Heavy_jet_3_3.png");
export const Ultra_Long_Range_1_1 = require("../assets/Ultra_Long_Range_1_1.png");
export const Ultra_Long_Range_1_2 = require("../assets/Ultra_Long_Range_1_2.png");
export const Ultra_Long_Range_1_3 = require("../assets/Ultra_Long_Range_1_3.png");
export const Ultra_Long_Range_2_1 = require("../assets/Ultra_Long_Range_2_1.png");
export const Ultra_Long_Range_2_2 = require("../assets/Ultra_Long_Range_2_2.png");
export const Ultra_Long_Range_2_3 = require("../assets/Ultra_Long_Range_2_3.png");
export const Ultra_Long_Range_3_1 = require("../assets/Ultra_Long_Range_3_1.png");
export const Ultra_Long_Range_3_2 = require("../assets/Ultra_Long_Range_3_2.png");
export const Ultra_Long_Range_3_3 = require("../assets/Ultra_Long_Range_3_3.png");
export const VVIP_Airliner_1_1 = require("../assets/VVIP_Airliner_1_1.png");
export const VVIP_Airliner_1_2 = require("../assets/VVIP_Airliner_1_2.png");
export const VVIP_Airliner_1_3 = require("../assets/VVIP_Airliner_1_3.png");
export const VVIP_Airliner_2_1 = require("../assets/VVIP_Airliner_2_1.png");
export const VVIP_Airliner_2_2 = require("../assets/VVIP_Airliner_2_2.png");
export const VVIP_Airliner_2_3 = require("../assets/VVIP_Airliner_2_3.png");
export const VVIP_Airliner_3_1 = require("../assets/VVIP_Airliner_3_1.png");
export const VVIP_Airliner_3_2 = require("../assets/VVIP_Airliner_3_2.png");
export const VVIP_Airliner_3_3 = require("../assets/VVIP_Airliner_3_3.png");
export const max_range = require("../assets/max_range_icon.png");
export const high_speed = require("../assets/High_cruise_speed_icon.png");
export const client_icon = require("../assets/client_image.png");
export const elevate_image = require("../assets/elevate_journey_image.png");
export const blog_1 = require("../assets/blog_1.png");
export const blog_2 = require("../assets/blog_2.png");
export const blog_3 = require("../assets/blog_3.png");
export const blog_1_icon = require("../assets/blog_1_icon.png");
export const blog_2_icon = require("../assets/blog_2_icon.png");
export const blog_3_icon = require("../assets/blog_3_icon.png");
export const exclude = require("../assets/Exclude.png");
export const flyprivate_image = require("../assets/flyprivate_image.png");
export const Epitome = require("../assets/Epitome.png");
export const container = require("../assets/container.png");
export const containerImg = require("../assets/container.png");
export const joinourNewsletter = require("../assets/join_our_newsletter.png");
export const tribeImg = require("../assets/Tribe.png");
export const image_Back = require("../assets/arrow.png");
export const iconSearch = require("../assets/iconsSearchLine.png");
export const right_active_arrow = require("../assets/right_active_arrow.png");
export const right_arrow_inactive = require("../assets/right_arrow_inactive.png");
export const left_arrow_active = require("../assets/left_arrow_active.png");
export const left_arrow_inactive = require("../assets/left_arrow_inactive.png");
export const planeimg = require("../assets/planeimg.png");
export const Logo = require("../assets/Logo.png");
