// Customizable Area Start
import * as React from "react";
import BlogDetailController, { Props } from "./blogDetailController.web";
import FooterHomePageWeb from "../../../landingpage/src/FooterHomePage/FooterHomePage.web";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { exclude } from "../../../landingpage/src/assets";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./blogDetail.web.css";
import { Artboard, ArtboardDetail, Dot } from "../assets";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class BlogDetail extends BlogDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      blogs,
      blog,
      blogFirstIndex,
      blogLastIndex,
      blogTabValue,
      isLoading,
      blogTabValueTemp
    } = this.state;
    return (
      <>
        {Object.keys(blog).length > 0 && (
          <>
            <Grid container>
              <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}> 
              <Grid container spacing={2} className="imageDicContainer">
                <Grid item md={12} lg={12} sm={12} xs={12} className="imgGrid">
                  <img
                    src={blog.attributes.image || ArtboardDetail}
                    className="imgHeader"
                  />
                  <Backdrop open={isLoading} style={{ position: "absolute" }}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <div className="benefitHeadings">{blog.attributes.title}</div>
                </Grid>
              </Grid>

            </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}> 
              <Grid container spacing={4}>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <div
                    className="benefiDetails"
                    dangerouslySetInnerHTML={{
                      __html: blog.attributes.content
                    }}
                  />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12} className="dot">
                  <img src={Dot} className="dotImg" />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <div className="benefitTribeBlog">
                    <img src={exclude} className="benefitTribeImg" />
                    <span className="benefitTribeTxt">Tribe</span>
                  </div>
                </Grid>
              </Grid>

            </Grid>
            </Grid>

            <Grid container style={{marginBottom:'96px'}}>
            <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>            
              <Grid container>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <div className="benefiDetails">
                    <AppBar position="static" className="blogTabsDetail">
                      <Tabs
                        value={blogTabValue+1}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons="off"
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#e9d3bd",
                            border: "1px solid #e9d3bd",
                            width: "23%"
                          }
                        }}
                      >
                        <Button
                          // href={"/BlogDetail/" + blogs[blogTabValueTemp - 1]?.id}
                          data-testid="forward-btn"
                          variant="contained"
                          className="viewallBtnsBlog"
                          disabled={blogTabValueTemp === 0}
                          disableRipple
                          onClick={this.handleTabForwardButtonChange}
                        >
                          <Box className="viewallBtnBoxsBlog1">
                            <ArrowBackIcon className="arrowForwardIcon" />
                          </Box>
                        </Button>
                        {blogs.length > 0 &&
                          blogs
                          .slice(blogFirstIndex, blogLastIndex)                                    
                            .map((item: any, index: any) => {
                              return (
                                <div className="individualTab">
                                  <div className="individualTabImgdiv">
                                    <img
                                      src={item.attributes.image || Artboard}
                                      className="individualTabImg"
                                    />
                                  </div>
                                  <Tab
                                    href={"/BlogDetail/" + item.id}
                                    disableRipple
                                    value={index+1}
                                    className="blogTabExampledetail"
                                    label={
                                      <span className="tabLabel">
                                        {item.attributes.title}
                                      </span>
                                    }
                                  />
                                </div>
                              );
                            })}

                        <Button
                        // href={"/BlogDetail/" + blogs[blogTabValueTemp + 1]?.id}
                          data-testid="next-btn"
                          variant="contained"
                          className="viewallBtnsBlognext"
                          disabled={
                            blogTabValueTemp === blogs.length-1
                          }
                          disableRipple
                          onClick={this.handleTabNextButtonChange}
                        >
                          <Box className="viewallBtnBoxsBlog2">
                            <ArrowForwardIcon className="arrowForwardIcon" />
                          </Box>
                        </Button>
                      </Tabs>
                    </AppBar>
                  </div>
                </Grid>
              </Grid>
              </Grid>
              </Grid>
          </>
        )}
        <FooterHomePageWeb />
      </>
    );
    // Customizable Area End
  }
}
