import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  radioButton: string;
  selectMethod: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      radioButton: "",
      selectMethod: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  async componentDidMount() {
    this.getParamsData();
  }
  getParamsData = () => {
    const pathData = window.location.pathname
    const LoginIndex = pathData.split('/').findIndex((res => res === 'Login'))
    const SignUpIndex = pathData.split('/').findIndex((res => res === 'Signup'))
    if (SignUpIndex != -1) return this.setState({ selectMethod: false })
    if (LoginIndex != -1) return this.setState({ selectMethod: true })
  }
  handleChange = (type: any) => {
    if (type) {
      this.setState({ radioButton: type });
    }
  };

  handleCreateAccount = () => {
    if (this.state.radioButton === "Charterer") {
      if (!this.state.selectMethod) {
        this.goToCharterSignup();
      } else {
        this.goToCharterLogin();
      }
    } else {
      if (!this.state.selectMethod) {
        this.goToOperatorSignup();
      } else {
        this.goToOperatorLogin();
      }
    }
  };
  goToCharterSignup() {
    // on click of charterer login this function to execute
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ChartererReservationForm"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToOperatorSignup() {
    // on click of operator login this function to execute
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OperatorReservationForm"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToCharterLogin() {
    // on click of charterer login this function to execute
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ChartererLoginForm"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToOperatorLogin() {
    // on click of operator login this function to execute
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OperatorLoginForm"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area End
}
