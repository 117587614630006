import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Blog {
  id: number;
  type: string;
  attributes: {
    content: string;
    title: string;
    image: string;
  };
}

interface S {
  // Customizable Area Start
  blogs: Array<Blog>;
  last_index: boolean;
  last_index_val: number;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomeScreenChatererController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  apiBlogsData: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      blogs: [],
      last_index: false,
      last_index_val: 0
    };

    this.getBlogsData = this.getBlogsData.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);

      if (apiRequestCallId === this.apiBlogsData) {
        if (responseJson && responseJson.data) {
          const json_data = responseJson.data;
          const length_json = json_data.length;
          console.log(length_json, "length");

          this.setState({
            blogs: json_data,
            last_index_val: length_json
          });
        }
      } else {
        alert("Something went wrong");
      }
    }
  }

  // Customizable Area End

  // Customizable Area Start

  async componentDidMount() {
    this.getBlogsData();
  }

  getBlogsData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBlogsData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blogAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
