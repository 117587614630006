// Customizable Area Start
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { exclude } from "../../../landingpage/src/assets";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Artboard } from "../assets";
import "./blogHome.web.css";

export default function RightBlog(props: any) {
  // Customizable Area Start

  return (
    <>
          <Grid container>
            <Grid md={6} lg={6} sm={12} xs={12}>
              <div className="image_box">
                {props.image ? 
                  (<img src={props.image} height="90%" width="100%" className="image_class"></img>)
                  : 
                  (<img src={Artboard} height="90%" width="90%"></img>)}
              </div>  
            </Grid>

            
            <Grid md={6} lg={6} sm={12} xs={12}>
              <div style={{margin: "0px 0px 50px 70px"}}>
                <div className='benefitHeading'>{props.title}</div>
              
                <div dangerouslySetInnerHTML={{
                    __html: props.description
                    }}
                    />
                                         
                <div className='benefitTribe'>
                  <img src={exclude} width="20" height="20" style={{marginTop: "5px"}}></img>
                  <span style={{marginLeft: "15px"}}>Tribe</span>
                </div>
            
            <Button
              variant="contained"
              className="readMoreBtn"
              disableRipple
              href={props.id}
            >
              <Box className="readMoreBtnBox">
                <Typography className="readMoreBtnTxt">Read More</Typography>
                <ArrowForwardIcon className="arrowForwardIcon" />
              </Box>
            </Button>
          </div>
        </Grid>
      </Grid>

      {props.last_index ? <></> : <hr className="HrLine" />}
    </>
  );
  // Customizable Area End
}
