// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FormHelperText from "@material-ui/core/FormHelperText";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormControl from "@material-ui/core/FormControl";
import CallMadeIcon from "@material-ui/icons/CallMade";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import PrivateJetContactusController, {
  Props,
} from "./PrivateJetContactusController.web";
import Select from "react-select";
import { caution, fly_private_image } from "./assets";
import "./PrivateJetContactus.web.css";
import FooterHomePageWeb from "../../landingpage/src/FooterHomePage/FooterHomePage.web";

export const Menu = (props: any) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};
export const Svg = (p: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
export const DropdownIndicator = () => (
  <div>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
export const Blanket = (props: any) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
export const Dropdown = ({ children, isOpen, target, onClose }: any) => (
  <div
    style={{
      color: "white",
      background: "transparent",
      fontSize: 14,
      height: "30px",
      width: "100%",
    }}
  >
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
export function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    color: "white",
    background: "#504857",
    padding: "10px",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    fontSize: 16,
    height: "57px",
    width: "160px",
    fontFamily: 'Libre Franklin'
  }),
  input: (base: any) => ({
    ...base,
    color: "white",
    background: "#504857",
    fontFamily: 'Libre Franklin',
  }),
  menu: (base: any) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    color: "#FFFFFF",
    fontFamily: 'Libre Franklin'
  }),
  menuList: (base: any) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    background: "#504857",
    color: "#FFFFFF",
    fontFamily: 'Libre Franklin'
  }),
  option: (base: any) => ({
    ...base,
    color: "#FFFFFF",
    background: "#504857",
    fontFamily: 'Libre Franklin'
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#FFFFFF",
    fontFamily: 'Libre Franklin'
  }),
};
export const BootstrapNumberInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      color: "#fcf6f0",
      position: "relative",
      backgroundColor: "transparent",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "0.46259466px",
      padding: "10px 0px",
      fontFamily: ["Libre Franklin"].join(","),
    },
  })
)(InputBase);
export const CssTextField = withStyles({
  root: {
    ".MuiFormLabel-root": {
      color: "#8A848F",
    },
    "& label.Mui-focused": {
      color: "#8A848F",
    },
    "& .MuiInput-underline:after": {
      border: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#8A848F",
      },
      "&:hover fieldset": {
        borderColor: "#8A848F",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#8A848F",
      },
    },
    "& .MuiInputBase-input": {
      padding: "0px 0px 16px"
    }
  },
})(TextField);
// Customizable Area End

export default class PrivateJetContactus extends PrivateJetContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    return (
      <Box>
        <Grid container>
          <Grid item xs={10} lg={10} md={10} style={{ margin: 'auto' }}>
            <Box className="mainContainer">
              <form onSubmit={this.formSubmitHandler}>
                <Grid container className="contactUsFormContainer">
                  <Grid item xs={12} lg={5} md={5} className="getInTouchContainer">
                    <Grid container>
                      <Grid item xs={12} lg={12} md={12}>
                        <Box>
                          <Box className="getInTouchTxt">Get in touch</Box>
                          <Box className="getInTouchSubTitleTxt">
                            We're more than happy to have a conversation.
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={5} md={5}>
                    {this.state.isSubmittedForm ? (
                      <Grid container className="submittedFormTitleGrid">
                        <Grid item xs={12} lg={12} md={12}>
                          <Box className="submittedFormTitleTxt">
                            Thanks for getting in touch!
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                          <Box className="submittedFormSubTitleTxt">
                            Sit back and relax, our team will look into the finer
                            details and reach out to you soon.
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <Box>
                        <Grid container>
                          <Grid item xs={12} lg={12} md={12}>
                            <CssTextField
                              id="standard-basic"
                              fullWidth
                              value={this.state.first_name}
                              onChange={this.firstNameChangeHandler}
                              autoComplete="first-name"
                              data-testid="first-name-check"
                              label="First name *"
                              className="input_textfield"
                              InputProps={{ className: "input_textfield" }}
                              InputLabelProps={{ className: "textField_inputLabel" }}
                              style={{
                                fontWeight: 400
                              }}
                            />
                            {this.state.first_name_error && (
                              <FormHelperText className="errorTextField">
                                <img src={caution} className="caution_img" />{" "}
                                {this.state.first_name_errorMsg}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} lg={12} md={12}>
                            <CssTextField
                              id="standard-basic"
                              fullWidth
                              value={this.state.last_name}
                              onChange={this.lastNameChangeHandler}
                              autoComplete="last-name"
                              data-testid="last-name-check"
                              label="Last name"
                              className="input_textfield"
                              InputProps={{ className: "input_textfield" }}
                              InputLabelProps={{ className: "textField_inputLabel" }}
                            />
                          </Grid>
                          <Grid item xs={12} lg={12} md={12}>
                            <CssTextField
                              id="standard-basic"
                              fullWidth
                              value={this.state.company_name}
                              onChange={this.companyNameChangeHandler}
                              autoComplete="company_name"
                              data-testid="company-name-check"
                              label="Company name"
                              className="input_textfield"
                              InputProps={{ className: "input_textfield" }}
                              InputLabelProps={{ className: "textField_inputLabel" }}
                            />
                          </Grid>
                          <Grid item xs={12} lg={12} md={12}>
                            <CssTextField
                              id="standard-basic"
                              fullWidth
                              value={this.state.email_id}
                              onChange={this.emailFieldOnchange}
                              onBlur={this.emailFieldOnBlur}
                              data-testid="email-check"
                              autoComplete="given-email"
                              label="Email *"
                              className="input_textfield"
                              InputProps={{ className: "input_textfield" }}
                              InputLabelProps={{ className: "textField_inputLabel" }}
                            />
                            {this.state.email_id_error && (
                              <FormHelperText className="errorTextField">
                                <img src={caution} className="caution_img" />{" "}
                                {this.state.email_id_errorMsg}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} lg={12} md={12} className='contactNumberBox'>
                            <Box className="contactNumberTxt">
                              Contact number *
                            </Box>
                            <Grid container className="contactNumberMainBox">
                              <Grid item lg={3} md={3} xs={3} sm={3} className="contactNumberDropdown">
                                <Grid container>
                                  <Grid item lg={9} md={9} xs={9} sm={9} >
                                    <Dropdown
                                      isOpen={this.state.isOpen}
                                      data-testid="close_dropdown"
                                      onClose={this.handleContactNumberDropdownClose}
                                      target={
                                        <Button
                                          variant="contained"
                                          data-testid="dropdown-btn-countrycode"
                                          style={{
                                            color: "white",
                                            background: "transparent",
                                            fontSize: "14px",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            padding: "0px",
                                            boxShadow: "none",
                                            cursor: "pointer",
                                            fontFamily: 'Libre Franklin'
                                          }}
                                          disableRipple
                                          endIcon={<KeyboardArrowDownIcon />}
                                          onClick={this.handleContactNumberDropdownOpen}
                                        >
                                          {this.state.selectedCountry
                                            ? this.state.selectedCountry.label
                                            : "Select"}
                                        </Button>
                                      }
                                    >
                                      <Select
                                        autoFocus
                                        backspaceRemovesValue={false}
                                        components={{
                                          DropdownIndicator,
                                          IndicatorSeparator: null,
                                        }}
                                        controlShouldRenderValue={false}
                                        hideSelectedOptions={false}
                                        data-testid="country-check"
                                        isClearable={false}
                                        placeholder="Search..."
                                        menuIsOpen
                                        tabSelectsValue={false}
                                        styles={customStyles}
                                        options={this.state.dataSource}
                                        value={this.state.selectedCountry}
                                        onChange={this.countrycodeChangeHandler}
                                      />
                                    </Dropdown>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    xs={3}
                                    sm={3}
                                    className="dividerGrid"
                                  >
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        background: "#8A848F",
                                        margin: "auto",
                                        height: "50%",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item lg={9} md={9} xs={9} sm={9}>
                                <Box>
                                  <BootstrapNumberInput
                                    placeholder="Contact number *"
                                    autoComplete="given-name"
                                    data-testid="contact-number-check"
                                    name="contactNumber"
                                    fullWidth
                                    id="contactNumber"
                                    value={this.state.contact_number}
                                    onChange={this.contactNumberFieldOnchange}
                                    onBlur={this.contactNumberFieldOnBlur}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl>
                                {this.state.contact_number_error && (
                                  <FormHelperText className="errorTextField">
                                    <img src={caution} className="caution_img" />{" "}
                                    {this.state.contact_number_errorMsg}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} lg={12} md={12}>
                            <CssTextField
                              id="standard-basic"
                              fullWidth
                              value={this.state.helpText}
                              onChange={this.helpTextFieldOnchange}
                              autoComplete="helpText"
                              data-testid="help-text-check"
                              label="How can we help you?"
                              className="input_textfield"
                              InputProps={{ className: "input_textfield" }}
                              InputLabelProps={{ className: "textField_inputLabel" }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container className="btnContainer">
                  <Grid item xs={12} lg={5} md={5}>
                    <Divider
                      orientation="horizontal"
                      style={{
                        background: "#8A848F",
                        margin: this.state.isSubmittedForm
                          ? "96px 0px 0px 0px"
                          : "0px 0px 60px 0px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5} md={5} className="submittedFormGrid">
                    <Grid container>
                      <Grid item xs={12} lg={12} md={12}>
                        <Button
                          type="submit"
                          data-testid="submit-contact-form"
                          className="contactusSubmitBtn"
                          disableRipple
                        >
                          {this.state.isSubmittedForm ? (
                            <>Send another request</>
                          ) : (
                            <>Get in touch</>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              <Grid container className="getBetterTxtContainer">
                <Grid item xs={12} lg={6} md={6}>
                  <img src={fly_private_image} className="flyPrivateImage" />
                </Grid>
              </Grid>

              <Grid container className="getStartedTxtContainer">
                <Grid item xs={6} lg={4} md={4}>
                  {this.state.isAuth ? (
                    <Button
                      variant="contained"
                      className="contactUSbookNowBtn"
                      disableRipple
                      href='/HomeScreenCharterer'
                    >
                      <Box className="contactUSbookNowBtnBox">
                        <Typography className="BookBtnTxt">Book now</Typography>
                        <CallMadeIcon className="callMadeIcon" />
                      </Box>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="contactUSgetStartedBtn"
                      disableRipple
                      href='/LandingPage/Signup'
                    >
                      <Box className="getStartedBtnBox">
                        <Typography className="getStartedBtnTxt">
                          Get started
                        </Typography>
                        <ArrowForwardIcon className="arrowForwardIcon" />
                      </Box>
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.open}
                onClose={this.handleClose}
                autoHideDuration={6000}
              >
                <Alert
                  onClose={this.handleClose}
                  severity={this.state.severity}
                  sx={{ width: "100%" }}
                >
                  {this.state.snackbarMes}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
        <FooterHomePageWeb />
      </Box>
    );
    // Customizable Area End
  }
}
