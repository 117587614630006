import React from 'react';
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { client_icon }
  from "../assets";


export default function ReasonComponentHome(props: any) {

  return (
    <>
      <div className="reasonComponentTextLabel">
        <div>{props?.data?.heading3}</div>
        <div>{props?.data?.sub_heading3}</div>
        <div className="homePageTextLabel1">{props?.data?.heading3_text}</div>
      </div>


      <Grid container>
        <Grid md={6} lg={6} sm={12} style={{ position: 'relative' }}>
          <img src={client_icon} style={{ width: "100%", marginTop: '28px 0px', objectFit: 'fill', position: 'relative', right: '15%', maxHeight: '656px' }}></img>
        </Grid>

        <Grid md={6} lg={6} sm={12}>
          <div className='ReasonTextSectionHome'>

            <div style={{ position: "relative", }}>
              <div className='frame' style={{ zIndex: -1 }}></div>
              <div className='ReasonTextHeadingHome'>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {props?.data?.reason2_point1_heading}</div>
              <div className='ReasonTextHome'>{props?.data?.reason2_point1_subheading}</div>
            </div>

            <div style={{ position: "relative", marginTop: '56px' }}>
              <div className='frame' style={{ zIndex: -1 }}></div>
              <div className='ReasonTextHeadingHome'>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {props?.data?.reason2_point2_heading}</div>
              <div className='ReasonTextHome'>{props?.data?.reason2_point2_subheading}</div>
            </div>

            <div style={{ position: "relative", marginTop: '56px' }}>
              <div className='frame' style={{ zIndex: -1 }}></div>
              <div className='ReasonTextHeadingHome'>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {props?.data?.reason2_point3_heading}</div>
              <div className='ReasonTextHome'>{props?.data?.reason2_point3_subheading}</div>
            </div>


            <div style={{ position: "relative", marginTop: '56px' }}>
              <div className='frame' style={{ zIndex: -1 }}></div>
              <div className='ReasonTextHeadingHome'>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {props?.data?.reason2_point4_heading}</div>
              <div className='ReasonTextHome'>{props?.data?.reason2_point4_subheading}</div>
            </div>


            <Button
              variant="contained"
              className="homePageGetStartedBtn"
              disableRipple
              href='/LandingPage/Signup'
              style={{ marginTop: '72px' }}
            >
              <Box className="getStartedBtnBox">
                <Typography className="getStartedBtnTxt">
                  Get started
                </Typography>
                <ArrowForwardIcon className="arrowForwardIcon" />
              </Box>
            </Button>

          </div>
        </Grid>

      </Grid>

    </>
  );
}
// Customizable Area End