// Customizable Area Start
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Logo } from '../assets';
// import {airbus_hd_photo_1_168573} from '../assets';
import FooterHomePageController, {
  Props,
} from "./FooterHomePageController.web";
import Box from "@material-ui/core/Box";
import './FooterHomePage.css'

const useStyles = () => ({
  root: {
    background: '#150a1f',
  },
  link: {
    paddingBottom: '12px',
    '& a': {
      color: '#C4C1C7'
    },
    '&:hover': {
      color: '#fff',
      textDecoration: 'underline'
    }
  },
  linkMain: {
    paddingBottom: '20px',
    '& a': {
      color: '#C4C1C7'
    }
  },
  logo: {
    width: "164px",
  },
  footershortcuts: {
    color: '#C4C1C7'
  },
  homeFooterGridContainer: {
    margin: 'auto'
  },
});

class FooterHomePage extends FooterHomePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    const { classes } = this.props;

    return (
      <footer className={classes.root}>
        <Divider style={{ background: '#8A848F' }} />
        <Grid container className={classes.homeFooterMainGridContainer} style={{ margin: '64px 0px' }}>
          <Grid item xs={10} lg={10} md={10} className={classes.homeFooterGridContainer}>
            <Grid container>
              <Grid item xs={12} lg={8} md={8}>
                <div className="footer_brandname">
                  <div>
                    <img className={classes.logo} src={Logo} />
                  </div>
                  <div className="footer_brandname_text">
                    Go ahead, be there.
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} lg={2} md={2}>
                <Box className="footer_tabListDiv">
                  <Box className='footer_aboutUs_Box'>
                    <a href="/aboutus" className='footer_tabList' style={{}}>
                      About Us
                    </a>
                  </Box>
                  <Box>
                    <a href="/Contact-us" className='footer_tabList'>
                      Contact Us
                    </a>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} lg={2} md={2}>
                <div>
                  <ul className="footer_tabEndListDiv">
                    <li>
                      <a href="/OperatorReservationForm" className='footer_tabList'>
                        For Operators
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} lg={12} md={12}>
                <Divider style={{ background: '#8A848F', marginTop: '64px' }} />
              </Grid>
            </Grid>
            <Grid container className='footer_copyRightContainer' style={{ marginTop: '64px' }}>
              <Grid item xs={12} lg={8} md={8}>
                <div className={`footer_copyRightTxt ${classes.footerrights}`}>
                  Copyright 2023 Tribe. All rights reserved
                  <span style={{ margin: '0px 8px' }}>
                    <svg height="10" width="10">
                      <circle cx="4" cy="4" r="4" stroke-width="1" fill="#C4C1C7" />
                    </svg>
                  </span>
                  <span>
                    <a
                      href='mailto:info@flytribe.ae'
                      className="footer_mailToTxt"
                    >
                      info@flytribe.ae
                    </a>
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} lg={2} md={2}>
                <div className="footer_tabListDiv"><a href="/PrivacyPolicy" className='footer_tabList'>Privacy Policy</a></div>
              </Grid>
              <Grid item xs={6} lg={2} md={2}>
                <div className="footer_tabEndListDiv"><a href="/TermsAndConditions" className='footer_tabList'>Terms of use</a></div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer >
    );
  }

}
export default withStyles(useStyles)(FooterHomePage);
// Customizable Area End 
