// Customizable Area Start
import * as React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  createTheme,
  ThemeProvider,
  createStyles,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import OperatorLoginFormController, {
  Props
} from "./OperatorLoginFormController.web";
import "./OperatorLoginForm.web.css";
import {
  backArrow, resend, caution, resend_disabled,
  congratulations_log_in
} from "../assets";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@material-ui/lab/Alert";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      color: "#fcf6f0",
      position: "relative",
      backgroundColor: "#504857",
      border: "white",
      fontSize: 16,
      width: "100%",
      height: "40px",
      padding: "8px 12px",
      // Use the system font instead of the default Roboto font.
      fontFamily: ["'Libre Franklin', sans-serif"].join(","),
      "&:focus": {
        borderColor: "white"
      }
    }
  })
)(InputBase);
const theme = createTheme();
// Customizable Area End
export function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default class OperatorLoginForm extends OperatorLoginFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { formNo, emailId } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ marginTop: '119px' }}>
          <div className="setBackgroundTribeLogo">
          <Grid item xs={5} style={{ margin: 'auto' }}>
            {/* First screen element  */}
            {formNo === "0" && (
              <Box className="firstFormBox1">
                <Grid container spacing={1} className="OperatorLoginFirstFormGrid1">
                  <Grid item xs={12} sm={12}>
                    <Typography className="loginOperator">
                      Log In as an Operator
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography className="fleetFont">
                      Manage your fleet by Logging In
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  component="form"
                  onSubmit={this.handleSubmit}
                >
                  <Grid container spacing={1} className="displayContents ">
                    <Grid item xs={9} sm={9} className="formBlockContainer">
                      <FormControl className=" marginTopFieldOperator">
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input"
                          className="fleetFontEmail "
                        >
                          Company Email
                        </InputLabel>
                        <BootstrapInput
                          data-testid="email-check"
                          placeholder="Enter registered company email address"
                          name="companyAddress"
                          fullWidth
                          id="companyAddress"
                          autoFocus
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.emailFieldOnchange(event)}
                          onBlur={() => this.emailFieldOnBlur()}
                        />
                        {this.state.emailIdError && (
                          <FormHelperText className="helperTextFieldImage">
                            <img src={caution} width="18px" height="18px" />{" "}
                            <FormHelperText className="helperTextFieldText">
                              {this.state.emailIdErrorMes}
                            </FormHelperText>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item container xs={9} sm={9} md={9} lg={9} className="checkBoxGridOperator">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          checked={this.state.privacyChecked}
                          className={
                            this.state.privacyChecked
                              ? "checkfieldtrue"
                              : "checkfield"
                          }
                          onChange={this.handleTandPChecker}
                          onBlur={() => {
                            if (this.state.privacyChecked === false) {
                              this.handlePrivacyErrorMes();
                            } else {
                              this.handlePrivacyError();
                            }
                          }}
                        />
                        {/* Terms and Privacy section Start */}
                        <Typography className="checkboxlLabelLoggin">
                          By logging in, I understand & agree to Tribe's{" "}
                          <span
                            data-testid="term-btn"
                            className="terms_and_privacys"
                            style={{ color: "#f2dcc4" }}
                            onClick={this.handleTermOpen}
                          >
                            terms of use
                          </span>{" "}
                          and{" "}
                          <span
                            className="terms_and_privacys"
                            style={{ color: "#f2dcc4" }}
                            onClick={this.handlePrivacyOpen}
                          >
                            privacy policy.
                          </span>
                        </Typography>
                        {/* Terms and Privacy section End */}
                      </div>
                    </Grid>
                    {this.state.privacyError && (
                      <Grid item xs={9} sm={9} style={{ margin: 'auto' }}>
                        <FormHelperText className="helperTextFieldImageCheckError">
                          <img src={caution} width="18px" height="18px" />{" "}
                          <FormHelperText className="helperTextFieldText">
                            {this.state.privacyErrorMes}
                          </FormHelperText>
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={9}
                    className="EmailLoginOTPTextFieldDiv"
                  >
                    <Button
                      data-testid="send-otp-btn"
                      type="submit"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      className="sendOtpButtonOperator"
                      disableRipple
                    >
                      Send OTP
                    </Button>
                  </Grid>
                </Box>
                <Grid item xs={12} sm={6} style={{ marginTop: "40px",marginBottom:"40px" }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item className="terms">
                      Don't have an account?{" "}
                      <Link href="/OperatorReservationForm" variant="body2">
                        {" "}
                        <span className="termsOfUseText"> Sign Up </span>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}


            {/* Second screen element  */}
            {formNo === "1" && (
              <Box className="firstFormBox1">
                <Grid container spacing={1} className="OperatorLoginFirstFormGrid1">
                  <Grid item xs={12} sm={12} md={11} lg={11} style={{ margin: 'auto', position: 'relative' }}>
                    <img
                      onClick={this.handleForm0}
                      src={backArrow}
                      width="30px"
                      height="20px"
                      style={{
                        width: '30px',
                        height: '20px',
                        position: 'absolute',
                        left:'-9%',
                        top: '30%'
                      }}
                    />
                    <Typography className="loginOperator">
                      Verify your mail address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography className="fleetFont">
                      Verification code sent to
                    </Typography>
                    <Typography className="emailLabelEmail">{emailId}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={9} lg={9} style={{ margin: 'auto' }}>
                    <Box
                      component="form"
                      // onSubmit={this.handleVerify}
                      style={{ width: '100%' }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} className="displayContents">
                          <Grid item xs={12} sm={12} style={{ marginTop: "90px", display: 'flex', justifyContent: 'center' }}>
                            <Typography className="fleetFont"><span style={{color: "white"}}>Enter the OTP</span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} className="EmailLoginOTPTextFieldDiv">
                          <Grid container spacing={4} className="signupForm">
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                                                
                                autoFocus={true}
                                type="tel"
                                placeholder="_"
                                name="otp1"
                                value={this.state.otp1}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange1}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props"
                                }}
                                onInput={this.handleOTPInputChekcer}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp1
                                      ? "#504857"
                                      : "#150a1f"
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp2-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                                                
                                type="tel"
                                name="otp2"
                                placeholder="_"
                                value={this.state.otp2}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange2}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props"
                                }}
                                onInput={this.handleOTPInputChekcer}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp2
                                      ? "#504857"
                                      : "#150a1f"
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp3-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                                                
                                type="tel"
                                name="otp3"
                                placeholder="_"
                                value={this.state.otp3}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange3}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props"
                                }}
                                onInput={this.handleOTPInputChekcer}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp3
                                      ? "#504857"
                                      : "#150a1f"
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp4-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                                                
                                type="tel"
                                name="otp4"
                                placeholder="_"
                                value={this.state.otp4}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange4}
                                onInput={this.handleOTPInputChekcer}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props"
                                }}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp4
                                      ? "#504857"
                                      : "#150a1f"
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {this.state.otpError && (
                          <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: 'auto' }}>
                            <FormHelperText className="helperTextFieldOTPImage">
                              <img src={caution} width="18px" height="18px" />{" "}
                              <FormHelperText className="helperTextFieldText">
                                {this.state.otpErrorMes}
                              </FormHelperText>
                            </FormHelperText>
                          </Grid>
                        )}
                        {/* Resend OTP Section start */}
                        <Box className="OperatorLoginResendBox">
                          <Grid container spacing={4} className="signupForm">

                          <Grid item xs={12} sm={12} md={6} lg={6} style={{ margin: 'auto' }}>                            
                                {this.state.resendEnabled
                                  ?
                                  <Typography className="ChartererLoginResendBoxFirstText">Didn't receive OTP?</Typography>
                                  :
                                  <Typography className="ChartererLoginResendBoxFirstTextValid">
                                    <span style={{color: "white"}}>Valid for 10:00</span></Typography>
                                }
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {this.state.resendEnabled ? (
                                  <>
                                    <img src={resend} className="resend-img" />{" "}
                                    <span className="resend_OTP" onClick={(e: any) => {
                                      this.handleResendClick(e)
                                    }}>
                                      Resend OTP</span>
                                  </>
                                ) : (
                                  <>
                                    <img src={resend_disabled} className="resend-img" />{" "}
                                    <span className="resend_OTP_Disabled">
                                      Resend OTP in 00:{this.state.timeLeft} </span>
                                  </>
                                )
                                }
                              </Grid>
                            </Grid>
                        </Box>
                        <Grid item xs={12} sm={12} md={12} lg={12} 
                        className="EmailLoginOTPTextFieldDivVerifyBTN">
                          <Button
                            data-testid="verify-btn"
                            // type="submit"
                            variant="contained"
                            className="OperatorLoginVerifyBtn"
                            style={{ textTransform: "none" }}
                            onClick={(e: any) => this.handleVerify(e)}
                            disabled={this.state.otpVerifyDisabled}
                            disableRipple
                          >
                            Verify and continue
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}


            {/* Success Modal */}
            <Dialog
              fullScreen
              PaperProps={{
                style: {
                  backgroundColor: "#150a1f"
                }
              }}
              data-testid="sucess-modal-check"
              onClick={() => this.handleModalcheck}
              aria-labelledby="customized-dialog-title"
              open={this.state.successOpen}
            >
              <>
                <div style={{
                  textAlign: "center"
                }}>
                  <img src={congratulations_log_in} width="60%" height="80%" ></img>
                </div>
              </>
            </Dialog>


            {/* terms of use modal */}
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "#f2dcc4",
                  minHeight: "80vh",
                  minWidth: "80vh"
                }
              }}
              aria-labelledby="customized-dialog-title"
              open={this.state.termsOpen}
            >
              <DialogContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.terms_and_conditions
                  }}
                />
              </DialogContent>
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "20px"
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FormControlLabel
                      data-testid="formdetails3"
                      control={
                        <Checkbox
                          data-testid="term-checkbox"
                          checked={this.state.privacyChecked}
                          style={{ color: "black" }}
                          onChange={this.handleTandPChecker}
                        />
                      }
                      label={
                        <Typography className="emailLabelModal" style={{ marginLeft: "5px" }}>
                          By accepting, I agree to the above terms of services.{" "}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="modalButtonContainer">
                    <Button
                      data-testid="terms-decline-btn"
                      variant="contained"
                      className="modalButton"
                      disableRipple
                      onClick={() =>
                        this.setState({
                          termsOpen: false,
                          privacyChecked: false,
                          privacyError: true,
                          privacyErrorMes:
                            "Please accept the Tribe's terms of use and privacy policy before continuing"
                        })
                      }
                    >
                      Decline
                    </Button>
                    <Button
                      data-testid="terms-accept-btn"
                      variant="contained"
                      className="modalButtonAccept"
                      disableRipple
                      onClick={() =>
                        this.setState({
                          privacyChecked: true,
                          termsOpen: false,
                          privacyError: false,
                          privacyErrorMes: ""
                        })
                      }
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>


            {/* privacy modal */}
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "#f2dcc4",
                  minHeight: "80vh",
                  minWidth: "80vh"
                }
              }}
              aria-labelledby="customized-dialog-title"
              open={this.state.privacyOpen}
            >
              <DialogContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.privacy_policy
                  }}
                />
              </DialogContent>
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "20px"
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FormControlLabel
                      data-testid="formdetails2"
                      control={
                        <Checkbox
                          checked={this.state.privacyChecked}
                          style={{ color: "black" }}
                          onChange={this.handleTandPChecker}
                        />
                      }
                      label={
                        <Typography className="emailLabelModal" style={{ marginLeft: "5px" }}>
                          By accepting, I agree to the above privacy policy.{" "}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="modalButtonContainer">
                    <Button
                      data-testid="privacy-decline-btn"
                      variant="contained"
                      className="modalButton"
                      disableRipple
                      onClick={this.handlePrivacyDecline}
                    >
                      Decline
                    </Button>
                    <Button
                      data-testid="privacy-accept-btn"
                      variant="contained"
                      className="modalButtonAccept"
                      disableRipple
                      onClick={this.handlePrivacyAccept}
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>


          </Grid>
          </div>
        </Grid>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
