// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import React from "react";
// Customizable Area End

export interface Props {
  navigation?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formNo: string;
  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  cursorPointer: any;
  loadingSpinner: boolean;
  otperror: boolean;
  otperrorText: string;
  successOpen: boolean;
  emailId: string;
  emailIdError: boolean;
  emailIdErrorMes: any;
  termsOpen: boolean;
  privacyOpen: boolean;
  privacyError: boolean;
  privacyChecked: boolean;
  privacyErrorMes: any;
  otpError: boolean;
  otpErrorMes: any;
  otpReceived: number;
  token: string;
  open: boolean;
  snackbarMes: string;
  severity: string;
  terms_and_conditions: string;
  privacy_policy: string;
  timeLeft: any;
  resendEnabled: boolean;
  otpVerifyDisabled: boolean;
  otpDidnt: boolean;
  // Customizable Area End
}

interface SS {}

export const configJSON = require("./../config.js");
const re = /^[0-9\b]+$/;
const emailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export default class OperatorLoginFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiLoginCallId: string = "";
  apiVerifyCallId: string = "";
  apiTermsAndConditionsCallId: string = "";
  apiPrivacyPolicyCallId: string = "";
  intervalRef: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      terms_and_conditions: "",
      privacy_policy: "",
      timeLeft: 30,
      resendEnabled: true,
      otpDidnt: true,
      severity: "",
      snackbarMes: "",
      open: false,
      formNo: "0",
      otp_code: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otpVerifyDisabled: false,
      cursorPointer: 0,
      loadingSpinner: false,
      otperror: false,
      otperrorText: "",
      successOpen: false,
      termsOpen: false,
      privacyOpen: false,
      emailId: "",
      emailIdError: false,
      emailIdErrorMes: "",
      privacyChecked: false,
      privacyError: false,
      privacyErrorMes: "",
      otpError: false,
      otpErrorMes: "",
      otpReceived: 0,
      token: "",
    };

    this.intervalRef = React.createRef();
    this.userdata = this.userdata.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validation = this.validation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleCountDown = this.handleCountDown.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.emailFieldOnchange = this.emailFieldOnchange.bind(this);
    this.emailFieldOnBlur = this.emailFieldOnBlur.bind(this);
    this.getTermsAndConditions = this.getTermsAndConditions.bind(this);
    this.getPrivacyPoliciy = this.getPrivacyPoliciy.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);

      if (apiRequestCallId === this.apiLoginCallId) {
        if (responseJson && responseJson.data) {
          this.setState(
            {
              otpReceived: responseJson.data.attributes.pin,
              token: responseJson.meta.token,
              open: true,
              severity: "success",
              snackbarMes: "OTP sent to your mail Id",
            },
            () => {
              this.handleFormChange("1");
              localStorage.setItem("token", responseJson.meta.token);
            }
          );
        } else {
          this.setState({
            open: true,
            severity: "error",
            snackbarMes: "Something wrong happened",
          });
        }
      }
      if (apiRequestCallId === this.apiVerifyCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            successOpen: true,
            resendEnabled: false,
          });
          setTimeout(
            function() {
              window.location.replace('/HomeScreenCharterer')
            }
            .bind(this),
            3000
          );
        } else {
          this.setState({
            open: true,
            severity: "error",
            snackbarMes: "Something wrong happened",
          });
        }
      }
      if (apiRequestCallId === this.apiTermsAndConditionsCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            terms_and_conditions: responseJson.data.attributes.description,
          });
        } else {
          this.setState({
            open: true,
            severity: "error",
            snackbarMes: "Something wrong happened",
          });
        }
      }
      if (apiRequestCallId === this.apiPrivacyPolicyCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            privacy_policy: responseJson.data.attributes.description,
          });
        } else {
          this.setState({
            open: true,
            severity: "error",
            snackbarMes: "Something wrong happened",
          });
        }
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  userdata = () => {
    console.log("userdata");
  };

  async componentDidMount() {
    this.userdata();
    this.getTermsAndConditions();
    this.getPrivacyPoliciy();
    const { otp1, otp2, otp3, otp4 } = this.state;
    if (otp1 === "" || otp2 === "" || otp3 === "" || otp4 == "") {
      this.setState({ otpVerifyDisabled: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleModalcheck = () => {
    this.setState({ successOpen: false, formNo: "0" });
  };

  handlePrivacyAccept = () => {
    this.setState({
      privacyChecked: true,
      privacyOpen: false,
      privacyError: false,
      privacyErrorMes: ""
    })
  };

  handlePrivacyDecline = () => {
    this.setState({
      privacyOpen: false,
      privacyChecked: false,
      privacyError: true,
      privacyErrorMes:
      "Please accept the Tribe’s terms of use and privacy policy before continuing"
    })
  }

  handlePrivacyErrorMes = () => {
    this.setState({
      privacyError: true,
      privacyErrorMes:
      "Please accept the Tribe’s terms of use and privacy policy before continuing"
    });
  }

  handlePrivacyError = () => {
    this.setState({
      privacyError: false,
      privacyErrorMes: ""
    });
  }

  handleTandPChecker = () => {
    this.setState({
      privacyChecked: !this.state.privacyChecked,
      privacyError: this.state.privacyChecked
        ? true
        : false,
      privacyErrorMes: this.state.privacyChecked
        ? "Please accept the Tribe’s terms of use and privacy policy before continuing"
        : ""
    });
  }

  handleTermOpen = () => {
    this.setState({ termsOpen: true});
  };
  
  handlePrivacyOpen = () => {
    this.setState({ privacyOpen: true});
  };

  handleForm0 = () => {
    this.setState({ formNo: "0"});
  };

  handleOTPInputChekcer = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 1);
  };

  handlePaster = (e: any) => {
    const otp = e.clipboardData.getData("Text");
    this.setState({
      otp1: otp[0],
      otp2: otp[1],
      otp3: otp[2],
      otp4: otp[3]
    });
  };

  handleBlurChecker = () => {
    if (this.state.privacyChecked === false) {
      this.setState({
        privacyError: true,
        privacyErrorMes:
          "Please accept the Tribe’s terms of use and privacy policy before continuing"
      });
    } else {
      this.setState({
        privacyError: false,
        privacyErrorMes: ""
      });
    }
  };


  otpFieldOnChange1 = (event: any) => {
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp2 !== "" &&
        this.state.otp3 !== "" &&
        this.state.otp4 !== ""
      ) {
        this.setState({
          otp1: event.target.value,
          otperror: false,
          otpVerifyDisabled: false,
        });
      } else {
        this.setState({
          otp1: event.target.value,
          otpVerifyDisabled: true,
        });
      }

      if (event.target.value.length === 1) {
        const nextfield = document.querySelector(`input[name=otp2]`);
        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    } else {
      this.setState({
        otp1: "",
        otpVerifyDisabled: true,
      });
    }
  };
  otpFieldOnChange2 = (event: any) => {
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp1 !== "" &&
        this.state.otp3 !== "" &&
        this.state.otp4 !== ""
      ) {
        this.setState({
          otp2: event.target.value,
          otperror: false,
          otpVerifyDisabled: false,
        });
      } else {
        this.setState({
          otp2: event.target.value,
          otpVerifyDisabled: true,
        });
      }

      if (event.target.value.length === 1) {
        const nextfield = document.querySelector(`input[name=otp3]`);
        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    } else {
      this.setState({
        otp2: "",
        otpVerifyDisabled: true,
      });
      const prevfield = document.querySelector(`input[name=otp1]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };
  otpFieldOnChange3 = (event: any) => {
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp2 !== "" &&
        this.state.otp1 !== "" &&
        this.state.otp4 !== ""
      ) {
        this.setState({
          otp3: event.target.value,
          otperror: false,
          otpVerifyDisabled: false,
        });
      } else {
        this.setState({
          otp3: event.target.value,
          otpVerifyDisabled: true,
        });
      }

      if (event.target.value.length === 1) {
        const nextfield = document.querySelector(`input[name=otp4]`);
        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    } else {
      this.setState({
        otp3: "",
        otpVerifyDisabled: true,
      });
      const prevfield = document.querySelector(`input[name=otp2]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };
  otpFieldOnChange4 = (event: any) => {
    if (event.target.value !== "" && re.test(event.target.value)) {
      if (
        this.state.otp2 !== "" &&
        this.state.otp3 !== "" &&
        this.state.otp1 !== ""
      ) {
        this.setState({
          otp4: event.target.value,
          otperror: false,
          otpVerifyDisabled: false,
        });
      } else {
        this.setState({
          otp4: event.target.value,
          otpVerifyDisabled: true,
        });
      }
    } else {
      this.setState({
        otp4: "",
        otpVerifyDisabled: true,
      });
      const prevfield = document.querySelector(`input[name=otp3]`);
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  validation = () => {
    let allow: boolean = true;
    const emailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const { emailId, privacyChecked } = this.state;
    if (privacyChecked === false || emailId.length === 0) {
      if (this.state.emailId === "" || this.state.emailId.length === 0) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: "Company Email is required.",
        });
      } else if (!emailReg.test(emailId)) {
        this.setState({
          emailIdError: true,
          emailIdErrorMes: " Enter a valid email.",
        });
      }
      if (this.state.privacyChecked === false) {
        this.setState({
          privacyError: true,
          privacyErrorMes:
          "Please accept the Tribe’s terms of use and privacy policy before continuing"
        });
      }
      allow = false;
    }
    return allow;
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let allow: boolean = true;
    allow = this.validation();
    if (allow) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      let data = {
        data: {
          attributes: {
            email: this.state.emailId,
            terms_and_conditions: this.state.privacyChecked,
          },
          type: "email_account",
          user_type: "operator"
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiLoginCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sellerDetailsAPIMethodPOST
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleVerify = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { otp1, otp2, otp3, otp4 } = this.state;
    const pin = otp1 + otp2 + otp3 + otp4;
    if (this.state.otpReceived === +pin) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiVerifyCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginVerifyEndPoint + `?pin=${+pin}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sellerDetailsAPIMethodPOST
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({
        otpError: true,
        otpErrorMes: "Uh-oh! You seem to have entered an invalid OTP",
      });
    }
  };
  handleResendClick = (e: any) => {
      this.handleSubmit(e);
      this.handleCountDown();
    };
  getTermsAndConditions = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTermsAndConditionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPrivacyPoliciy = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPrivacyPolicyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicy
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  stopTimer = () => {
    clearInterval(this.intervalRef.current);
    this.setState({
      timeLeft: 30,
      resendEnabled: true,
      otpDidnt: false,
    });
  };
  handleCountDown = () => {
    this.intervalRef.current = setInterval(() => {
      if (this.state.timeLeft > 0) {
        this.setState((prevState) => ({
          resendEnabled: false,
          otpDidnt: true,
          timeLeft: prevState.timeLeft - 1,
        }));
      } else {
        this.stopTimer();
      }
    }, 1000);
  };
  handleFormChange = (number: string) => {
    this.setState({ formNo: number });
  };
  emailFieldOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Company Email is required.",
      });
    } else if (!emailReg.test(value)) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Enter a valid Email.",
      });
    } else if (emailReg.test(value)) {
      this.setState({
        emailIdError: false,
        emailIdErrorMes: "",
      });
    }
    this.setState({ emailId: event.target.value.trim() });
  };
  emailFieldOnBlur = () => {
    if (this.state.emailId === null || this.state.emailId.length === 0) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Company Email is required.",
      });
    } else if (!emailReg.test(this.state.emailId)) {
      this.setState({
        emailIdError: true,
        emailIdErrorMes: "Enter a valid Email.",
      });
    } else {
      this.setState({
        emailIdError: false,
        emailIdErrorMes: "",
      });
    }
  };
  // Customizable Area End
}
