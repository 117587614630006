import React from 'react';
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {passengers_icon, 
  max_range, high_speed, 
  right_active_arrow, right_arrow_inactive, left_arrow_active, left_arrow_inactive, 
  VVIP_Airliner_1_1,
  VVIP_Airliner_1_2,
  VVIP_Airliner_1_3,
  VVIP_Airliner_2_1,
  VVIP_Airliner_2_2,
  VVIP_Airliner_2_3,
  VVIP_Airliner_3_1,
  VVIP_Airliner_3_2,
  VVIP_Airliner_3_3}
  from "../assets";

import TabController, { Props } from './TabController';


export default class VVIPAirlinerJet extends TabController {

  componentDidUpdate(prevProps: Readonly<Props>): void {

  }
  
  
  render(){
  return (
    <>
        <Grid container>
          

        <Grid md={6} lg={6} sm={12} xs={12}>
          
          {this.state.lightJetFirstImage === 0 ? (
            <div className='container'>

            <img src={left_arrow_inactive} width="40px" height="40px" className='imageOnImage1'></img>

            <img src={right_active_arrow} width="40px" height="40px" className='imageOnImage' onClick={()=>{
              this.setState({
                lightJetFirstImage: 1
              })
            }}></img>
            
            <img src={VVIP_Airliner_1_1} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
            </div>
          ) : (
            <>
              {this.state.lightJetFirstImage === 1 ? (
                <div className='container'>
                  <img src={left_arrow_active} width="40px" height="40px" className='imageOnImage1' onClick={()=>{
                    this.setState({
                      lightJetFirstImage: 0
                    })
                  }}></img>

                  <img src={right_active_arrow} width="40px" height="40px" className='imageOnImage' onClick={()=>{
                    this.setState({
                      lightJetFirstImage: 2
                    })
                  }}></img>

                  <img src={VVIP_Airliner_1_2} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
                </div>
              ) : (
                <div className='container'>
                  <img src={left_arrow_active} width="40px" height="40px" className='imageOnImage1' onClick={()=>{
                    this.setState({
                      lightJetFirstImage: 1
                    })
                  }}></img>

                  <img src={right_arrow_inactive} width="40px" height="40px" className='imageOnImage'></img>

                  <img src={VVIP_Airliner_1_3} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
                </div>
              )}
            </>
          )}
          
          
          
          </Grid>
          
          <Grid md={6} lg={6} sm={12} xs={12} >
            <div style={{marginTop: "30px", marginLeft: "30px"}}>
              <div className="tabScreenHeading">{this.props.tablet_section_1_heading}</div>

              <div style={{marginTop: "40px", marginLeft: "1px"}}>
                <Grid container>
                  
                <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSectionsBorder'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={passengers_icon} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>
                            <div className='headingTextTab'>Passengers</div>
                            <div className='contectTextTab'>{this.props.tablet_section_1_passengers} max*</div>
                          </div>
                        </Grid>
                    </Grid>                   
                  </Grid>

                  <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSectionsBorder'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={max_range} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>                            
                            <div className='headingTextTab'>Max Range</div>                            
                            <div className='contectTextTab'>{this.props.tablet_section_1_max_range} mi</div>
                          </div>
                        </Grid>
                    </Grid>                   
                  </Grid>
                  
                  <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSections'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={high_speed} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>                            
                          <div className='headingTextTab'>High Cruise Speed</div>
                            <div className='contectTextTab'>{this.props.tablet_section_1_speed} mph</div>
                          </div>
                        </Grid>
                    </Grid>
                  </Grid>


                  
                </Grid>
              </div>

              <div className="textTab1">{this.props.tablet_section_1_description}</div>
            </div>
          </Grid>

        </Grid>





        <Grid container style={{marginTop: "60"}}>
          <Grid md={6} lg={6} sm={12} xs={12} >
            <div style={{marginTop: "30px", marginRight: "30px"}}>
              <div className="tabScreenHeading">{this.props.tablet_section_2_heading}</div>

              <div style={{marginTop: "40px", marginLeft: "1px"}}>
                <Grid container>
                  
                <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSectionsBorder'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={passengers_icon} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>
                          <div className='headingTextTab'>Passengers</div>
                          <div className='contectTextTab'>{this.props.tablet_section_1_passengers} max*</div>
                          </div>
                        </Grid>
                    </Grid>                   
                  </Grid>

                  <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSectionsBorder'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={max_range} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>
                          <div className='headingTextTab'>Max Range</div>
                          <div className='contectTextTab'>{this.props.tablet_section_1_max_range} mi</div>
                          </div>
                        </Grid>
                    </Grid>                   
                  </Grid>

                  <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSections'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={high_speed} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>
                          <div className='headingTextTab'>High Cruise Speed</div>
                          <div className='contectTextTab'>{this.props.tablet_section_1_speed} mph</div>
                          </div>
                        </Grid>
                    </Grid>
                  </Grid>



                  
                </Grid>
              </div>

              <div className="textTab1">{this.props.tablet_section_2_description}</div>
            </div>
          </Grid>



          <Grid md={6} lg={6} sm={12} xs={12}>
          {this.state.lightJetSecondImage === 0 ? (
            <div className='container'>

            <img src={left_arrow_inactive} width="40px" height="40px" className='imageOnImage1'></img>

            <img src={right_active_arrow} width="40px" height="40px" className='imageOnImage' onClick={()=>{
              this.setState({
                lightJetSecondImage: 1
              })
            }}></img>
            
            <img src={VVIP_Airliner_2_1} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
            </div>
          ) : (
            <>
              {this.state.lightJetSecondImage === 1 ? (
                <div className='container'>
                  <img src={left_arrow_active} width="40px" height="40px" className='imageOnImage1' onClick={()=>{
                    this.setState({
                      lightJetSecondImage: 0
                    })
                  }}></img>

                  <img src={right_active_arrow} width="40px" height="40px" className='imageOnImage' onClick={()=>{
                    this.setState({
                      lightJetSecondImage: 2
                    })
                  }}></img>

                  <img src={VVIP_Airliner_2_2} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
                </div>
              ) : (
                <div className='container'>
                  <img src={left_arrow_active} width="40px" height="40px" className='imageOnImage1' onClick={()=>{
                    this.setState({
                      lightJetSecondImage: 1
                    })
                  }}></img>

                  <img src={right_arrow_inactive} width="40px" height="40px" className='imageOnImage'></img>

                  <img src={VVIP_Airliner_2_3} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
                </div>
              )}
            </>
          )}
          
          </Grid>


        </Grid>



        <Grid container style={{marginTop: "60"}}>

          <Grid md={6} lg={6} sm={12} xs={12}>
          {this.state.lightJetThirdImage === 0 ? (
            <div className='container'>

            <img src={left_arrow_inactive} width="40px" height="40px" className='imageOnImage1'></img>

            <img src={right_active_arrow} width="40px" height="40px" className='imageOnImage' onClick={()=>{
              this.setState({
                lightJetThirdImage: 1
              })
            }}></img>
            
            <img src={VVIP_Airliner_3_1} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
            </div>
          ) : (
            <>
              {this.state.lightJetThirdImage === 1 ? (
                <div className='container'>
                  <img src={left_arrow_active} width="40px" height="40px" className='imageOnImage1' onClick={()=>{
                    this.setState({
                      lightJetThirdImage: 0
                    })
                  }}></img>

                  <img src={right_active_arrow} width="40px" height="40px" className='imageOnImage' onClick={()=>{
                    this.setState({
                      lightJetThirdImage: 2
                    })
                  }}></img>

                  <img src={VVIP_Airliner_3_2} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
                </div>
              ) : (
                <div className='container'>
                  <img src={left_arrow_active} width="40px" height="40px" className='imageOnImage1' onClick={()=>{
                    this.setState({
                      lightJetThirdImage: 1
                    })
                  }}></img>

                  <img src={right_arrow_inactive} width="40px" height="40px" className='imageOnImage'></img>

                  <img src={VVIP_Airliner_3_3} height="100%" width="100%" style={{borderRadius: "20px"}}></img>  
                </div>
              )}
            </>
          )}
          
          </Grid>
          
          <Grid md={6} lg={6} sm={12} xs={12} >
            <div style={{marginTop: "30px", marginLeft: "30px"}}>
              <div className="tabScreenHeading">{this.props.tablet_section_3_heading}</div>

              <div style={{marginTop: "40px", marginLeft: "1px"}}>
                <Grid container>
                  
                <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSectionsBorder'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={passengers_icon} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>
                          <div className='headingTextTab'>Passengers</div>
                          <div className='contectTextTab'>{this.props.tablet_section_1_passengers} max*</div>
                          </div>
                        </Grid>
                    </Grid>                   
                  </Grid>
                  
                  <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSectionsBorder'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={max_range} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>
                          <div className='headingTextTab'>Max Range</div>
                          <div className='contectTextTab'>{this.props.tablet_section_1_max_range} mi</div>
                          </div>
                        </Grid>
                    </Grid>                   
                  </Grid>

                  <Grid md={4} lg={4} sm={12} xs={12} className='tabsMiniSections'>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4} xs={4}>
                        <img src={high_speed} height="45" width="45"></img>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} xs={8}>
                          <div style={{marginLeft: "5px", color: "#FCFCFD", marginTop: "5px"}}>
                          <div className='headingTextTab'>High Cruise Speed</div>
                          <div className='contectTextTab'>{this.props.tablet_section_1_speed} mph</div>
                          </div>
                        </Grid>
                    </Grid>
                  </Grid>



                  
                </Grid>
              </div>

              <div className="textTab1">{this.props.tablet_section_3_description}</div>
            </div>
          </Grid>



        </Grid>
        </>
  );
}
};

// Customizable Area End