import React, { useState, useEffect } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import MoreIcon from '@material-ui/icons/MoreVert';
var logo = require("./assets/Logo.png");
var profile_icon = require("./assets/Profile_icon_normal.png");
var profile_hovered = require("./assets/Profile_icon_hovered.png");



export default function HomeHeader(props: any) {
  const pathData = window.location.pathname
  const blogDetailIndex = pathData.split('/').findIndex((res => res === 'BlogDetail'))
  const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    homeHeaderMainContainer: {
      flexGrow: 1,
      backgroundColor: "rgba(138, 132, 143, 0.2)",
      opacity: "1",
      boxShadow: 'none',
      position: 'sticky',
      top: '0%',
      width: '100%',
      left: '0%',
      color: '#fff',
      backdropFilter: 'blur(14px)',
      zIndex: 1111,
      float: 'left'
    },
    headerMainGridContainer: {
      alignItems: 'center'
    },
    logoGridContainer: {
      justifyContent: 'flex-start'
    },
    tabGridContainer: {
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    btnGridContainer: {
      display: 'none',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    btnGridMainContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    homeHeaderGridContainer: {
      margin: 'auto',
      padding: '15px 0px'
    },
    homeHeaderSubMainContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    loginBtn: {
      background: 'transparent',
      color: '#fff',
      fontFamily: 'Libre Franklin',
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'capitalize',
      boxShadow: 'none',
      padding: '0px',
      marginRight: '49px',
      "&:hover": {
        background: 'transparent',
        boxShadow: 'none'
      },
    },
    signupBtn: {
      background: 'transparent',
      border: '2px solid #8A848F',
      borderRadius: 20,
      color: '#fff',
      fontFamily: 'Libre Franklin',
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'capitalize',
      boxShadow: 'none',
      padding: '6px 44px',
      "&:hover": {
        color: '#150A1F',
        background: '#C4C1C7',
      },
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    blogMenuItemBar: {
      display: 'flex',
      marginRight: "48px",
      fontFamily: "'Libre Franklin', sans-serif",
      color: window.location.pathname === '/BlogHome' || blogDetailIndex != -1 ? "#F2DCC4" : "rgba(255,255,255,0.6)",
      cursor: "pointer",
      fontSize: '14px',
    },
    aboutMenuItemBar: {
      display: 'flex',
      marginRight: "48px",
      fontFamily: "'Libre Franklin', sans-serif",
      color: window.location.pathname === '/aboutus' ? "#F2DCC4" : "rgba(255,255,255,0.6)",
      cursor: "pointer",
      fontSize: '14px',
    },
    contactMenuItemBar: {
      display: 'flex',
      fontFamily: "'Libre Franklin', sans-serif",
      color: window.location.pathname === '/Contact-us' ? "#F2DCC4" : "rgba(255,255,255,0.6)",
      cursor: "pointer",
      fontSize: '14px'
    },
    sectionDesktopMenu: {
      display: 'none',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      cursor: "pointer"
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    homeHeaderBoxConainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    burgerMenuGridContainer: {
      textAlign: 'end'
    },
    threeLineContainer: {
      display: 'none',
      cursor: "pointer",
      padding: '0px',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    threeLineBar1Box: {
      width: '18.75px',
      height: '2.5px',
      backgroundColor: '#8A848F',
      margin: '6px 0',
      transition: '0.4s',
    },
    threeLineBar3Box: {
      width: '18.75px',
      height: '2.5px',
      backgroundColor: '#8A848F',
      margin: '6px 0',
      transition: '0.4s',
    },
    threeLineBar2Box: {
      width: '18.75px',
      height: '2.5px',
      backgroundColor: '#8A848F',
      margin: '6px 0',
      transition: '0.4s',
    },
    logoImageIconBtn: {
      "&:hover": {
        background: 'transparent',
        boxShadow: 'none'
      },
    }
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isUserLoggedIn, setUserLoggedIn] = useState(false)
  const [isBurgerMenuClicked, setIsBurgerMenuClicked] = useState(false)
  const [userData, setUserData] = useState(localStorage.getItem('userInfo'))
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    getUserData()
  }, [window.location.pathname, userData])
  const getUserData = () => {
    const userData = localStorage.getItem('userInfo')
    if (userData && userData != null) {
      setUserLoggedIn(true)
    }
    else {
      setUserLoggedIn(false)
    }
  }
  const burgerMenuClickHandler = () => {
    setIsBurgerMenuClicked(!isBurgerMenuClicked)
  }
  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <p>About Us</p>
      </MenuItem>
      <MenuItem>
        <p>Blogs</p>
      </MenuItem>
      <MenuItem>
        <p>Contact Us</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box className={`${classes.homeHeaderMainContainer}`}>
      <Grid container>
        <Grid item xs={10} lg={10} md={10} className={classes.homeHeaderGridContainer}>
          <Grid container className={classes.headerMainGridContainer}>
            <Grid item xs={7} lg={4} md={4} className={classes.logoGridContainer}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                disableRipple
                href={isUserLoggedIn ? '/HomeScreenCharterer' : '/'}
                className={classes.logoImageIconBtn}
              >
                <img src={logo} width="150px" />
              </IconButton>
            </Grid>
            <Grid item xs={4} lg={4} md={4} className={classes.tabGridContainer}>
              <div className={classes.sectionDesktopMenu}>
                <a href='/aboutus' style={{ textDecoration: 'none' }} className={classes.aboutMenuItemBar}>
                  About Us
                </a>
                <a href='/BlogHome' style={{ textDecoration: 'none' }} className={classes.blogMenuItemBar}>
                  Blogs
                </a>
                <a href='/Contact-us' style={{ textDecoration: 'none' }} className={classes.contactMenuItemBar}>
                  Contact Us
                </a>
              </div>
            </Grid>
            <Grid item xs={4} lg={4} md={4} className={classes.btnGridMainContainer}>
              {isUserLoggedIn
                ?
                <Box className={classes.btnGridContainer}>
                  <img src={profile_icon} width="40px" height="40px"
                    onMouseOver={e => (e.currentTarget.src = require("./assets/Profile_icon_hovered.png"))}
                    onMouseOut={e => (e.currentTarget.src = require("./assets/Profile_icon_normal.png"))}
                  />
                </Box>
                :
                <Box className={classes.btnGridContainer}>
                  <Button
                    variant="contained"
                    className={classes.loginBtn}
                    disableRipple
                    disableFocusRipple
                    href='/LandingPage/Login'
                  >
                    Log in
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.signupBtn}
                    disableRipple
                    href='/LandingPage/Signup'
                  >
                    Sign up
                  </Button>
                </Box>
              }
            </Grid>
            <Grid item xs={3} lg={4} md={4} className={classes.burgerMenuGridContainer}>
              <Button className={classes.threeLineContainer} onClick={burgerMenuClickHandler}>
                <Box className={classes.threeLineBar1Box}
                  style={{
                    transform: isBurgerMenuClicked ? 'rotate(-45deg) translate(-6px, 6px)' : 'rotate(0deg) translate(0px, 0px)'
                  }}
                ></Box>
                <Box
                  className={classes.threeLineBar2Box}
                  style={{ visibility: isBurgerMenuClicked ? 'hidden' : 'visible' }}
                >
                </Box>
                <Box
                  className={classes.threeLineBar3Box}
                  style={{ transform: isBurgerMenuClicked ? 'rotate(45deg) translate(-5px, -6px)' : 'rotate(0deg) translate(0px, 0px)' }}
                >
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}