// Customizable Area Start
import React from "react";
import HomePageController, { Props } from "./HomePageController.web";
import {
  tribeImg,
  joinourNewsletter,
  passengers_icon,
  luggage_icon,
  arrow_down_icon,
  Epitome,
  elevate_image,
  planeimg
} from "../assets";
import FooterHomePageWeb from "../FooterHomePage/FooterHomePage.web";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CallMadeIcon from "@material-ui/icons/CallMade";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ReasonComponentHome from "./reasonComponentHome.web";
import OperatorsReasonsComponent from "../HomePage/operatorsReasonsComponent";
import HomePageoneWayBoxComponent from "../homePageoneWayBoxComponent.web";
import LeftBlog from "../../../catalogue/src/BlogHome/leftBlog.web";
import "./HomePage.css";

function TabPanel(props: any) {
  const { value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === 0 && <HomePageoneWayBoxComponent />}

      {value === 1 && <HomePageoneWayBoxComponent />}

      {value === 2 && <HomePageoneWayBoxComponent />}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function BlogTabPanel(props: any) {
  const { value, index, ...other } = props;
  const { blogs } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === 0 && (
        <>
          {blogs.length != 0 ? (
            <LeftBlog
              title={blogs[0].attributes.title}
              description={
                blogs[0].attributes.content.substring(0, 300) + "..."
              }
              image={blogs[0].attributes.image}
              last_index={true}
              id={"/BlogDetail/" + blogs[0].id}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {value === 1 && (
        <>
          {blogs.length != 0 ? (
            <LeftBlog
              title={blogs[1].attributes.title}
              description={
                blogs[1].attributes.content.substring(0, 300) + "..."
              }
              image={blogs[1].attributes.image}
              last_index={true}
              id={"/BlogDetail/" + blogs[1].id}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {value === 2 && (
        <>
          {blogs.length != 0 ? (
            <LeftBlog
              title={blogs[2].attributes.title}
              description={
                blogs[2].attributes.content.substring(0, 300) + "..."
              }
              image={blogs[2].attributes.image}
              last_index={true}
              id={"/BlogDetail/" + blogs[2].id}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

BlogTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  blogs: PropTypes.any
};

export default class HomePage extends HomePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { homeData } = this.state;
    return (
      <Box>
        <Box style={{ backgroundColor: "#150A1F" }}>
          <Box className="image-containerLanding">
            <Grid container style={{ position: "relative" }}>
              <Grid
                item
                xs={10}
                lg={10}
                md={10}
                style={{ margin: "auto", zIndex: 22 }}
              >
                <Box className="mainBannerText">
                  <Box className="bannertxt1">
                    {homeData?.attributes?.main_heading}
                  </Box>
                  <Box className="bannertxt2">
                    {homeData?.attributes?.second_main_heading}
                  </Box>
                  <Box className="bannersubText">
                    {homeData?.attributes?.main_sub_heading}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={10}
                lg={10}
                md={10}
                style={{ margin: "auto", zIndex: 22 }}
              >
                <Box>
                  <Grid container style={{ position: "relative" }}>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      style={{ margin: "auto" }}
                    >
                      <Box className="mainBoxHome">
                        <Grid
                          container
                          style={{
                            alignItems: "center",
                            borderBottom: "1px solid #8a848f"
                          }}
                        >
                          <Grid item lg={4} md={6} sm={12}>
                            <Box>
                              <Grid container>
                                <AppBar
                                  position="static"
                                  className="homePageboxTab"
                                >
                                  <Tabs
                                    value={this.state.boxTabValue}
                                    onChange={this.handleChangeBoxTab}
                                    aria-label="simple tabs example"
                                    variant="fullWidth"
                                    style={{ borderBottom: "none" }}
                                    TabIndicatorProps={{
                                      style: {
                                        backgroundColor: "white",
                                        color: "white"
                                      }
                                    }}
                                  >
                                    <Tab
                                      disableRipple
                                      className="homePageTabExamples"
                                      label="One-way"
                                      onClick={() => {
                                        this.setState({ boxTabValue: 0 });
                                      }}
                                    />
                                    <Tab
                                      disableRipple
                                      className="homePageTabExamples"
                                      label="Round trip"
                                      onClick={() => {
                                        this.setState({ boxTabValue: 1 });
                                      }}
                                    />
                                    <Tab
                                      disableRipple
                                      className="homePageTabExamples"
                                      label="Multi-leg"
                                      onClick={() => {
                                        this.setState({ boxTabValue: 2 });
                                      }}
                                    />
                                  </Tabs>
                                </AppBar>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item lg={8} md={6} sm={11}>
                            <Grid container>
                              <Grid item md={2} sm={2} lg={6} />
                              <Grid
                                item
                                md={5}
                                sm={5}
                                lg={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}
                              >
                                <IconButton className="boxButton1">
                                  <img
                                    src={passengers_icon}
                                    height="20px"
                                    width={"20px"}
                                  />
                                  <span className="boxIcon">Passenger(s)</span>
                                  <img
                                    src={arrow_down_icon}
                                    height="10px"
                                    width={"10px"}
                                  />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                md={5}
                                sm={5}
                                lg={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <IconButton className="boxButton1">
                                  <img
                                    src={luggage_icon}
                                    height="20px"
                                    width={"20px"}
                                  />
                                  <span className="boxIcon">Luggage</span>
                                  <img
                                    src={arrow_down_icon}
                                    height="10px"
                                    width={"10px"}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box style={{ marginTop: "40px" }}>
                          <TabPanel value={this.state.boxTabValue} index={0}>
                            One-way
                          </TabPanel>
                          <TabPanel value={this.state.boxTabValue} index={1}>
                            Round trip
                          </TabPanel>
                          <TabPanel value={this.state.boxTabValue} index={2}>
                            Multi-leg
                          </TabPanel>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <img src={planeimg} alt="planeimg" className="homepageImage" />
          </Box>
        </Box>

        <Grid
          container
          style={{
            background: `url(${tribeImg}) center / 100% 75% no-repeat`,
            marginTop: "174px"
          }}
        >
          <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={6}>
                <Box className="benefitHeadings_AboutTribe">
                  {homeData?.attributes?.heading
                    ? homeData?.attributes?.heading.split(" ")[0]
                    : "About"}{" "}
                  <span style={{ color: "#f3dcc5" }}>
                    {homeData?.attributes?.heading
                      ? homeData?.attributes?.heading.split(" ")[1]
                      : "Tribe"}
                  </span>
                </Box>
                <Box className="benefitHeading1">
                  {homeData?.attributes?.sub_heading}
                  {homeData?.attributes?.sub_second_heading}
                </Box>
                <Box className="benefitTexts">
                  {homeData?.attributes?.heading_description}
                </Box>
                <Box className="benefitText1">
                  {homeData?.attributes?.sub_heading_description}
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    className="homePageGetStartedBtn"
                    disableRipple
                    href="/LandingPage/Signup"
                    style={{ marginTop: "64px" }}
                  >
                    <Box className="getStartedBtnBox">
                      <Typography
                        className="getStartedBtnTxt"
                        style={{ fontFamily: "Playfair Display" }}
                      >
                        Get started
                      </Typography>
                      <ArrowForwardIcon className="arrowForwardIcon" />
                    </Box>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} md={6} style={{ position: "relative" }}>
                <img
                  src={joinourNewsletter}
                  style={{
                    width: "100%",
                    objectFit: "fill",
                    position: "relative",
                    left: "5%",
                    maxHeight: "90%"
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <Grid container>
            <Grid
              item
              xs={10}
              lg={10}
              md={10}
              style={{ margin: "auto", marginBottom: "172px" }}
            >
              <OperatorsReasonsComponent
                data={homeData?.attributes}
                propsData={this.props}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Box style={{ margin: "0px 20px", opacity: 0.5 }}>
            <img
              src={Epitome}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>
          <Grid container>
            <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>
              <Box className="epitomeText">
                {homeData?.attributes?.image_heading} <br />
                {homeData?.attributes?.image_heading_1}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ margin: "172px 0px" }}>
          <Grid container>
            <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>
              <ReasonComponentHome
                data={homeData?.attributes}
                propsData={this.props}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Box style={{ margin: "0px 20px" }}>
            <img
              src={elevate_image}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>
          <Grid container>
            <Grid
              item
              xs={10}
              lg={10}
              md={10}
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Box className="elevateTexts">
                {homeData?.attributes?.image_heading2}
                <br />
                {homeData?.attributes?.image_heading2_1}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: "206px" }}>
          <Container style={{maxWidth:"85%"}}>
          <Box style={{ marginTop: "100px" }}>
            <BlogTabPanel
              value={this.state.blogTabValue}
              index={0}
              blogs={this.state.blogs}
            >
              Blog 1
            </BlogTabPanel>
            <BlogTabPanel
              value={this.state.blogTabValue}
              index={1}
              blogs={this.state.blogs}
            >
              Blog 2
            </BlogTabPanel>
            <BlogTabPanel
              value={this.state.blogTabValue}
              index={2}
              blogs={this.state.blogs}
            >
              Blog 3
            </BlogTabPanel>
          </Box>
          </Container>
          {this.state.blogs && (
            <Grid container className="blogTab">
              <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>
                <Grid container spacing={3}>
                  {this.state.blogs.map((blog_Data: any, index: number) => (
                    <Grid item xs={3} lg={3} md={3} key={index}>
                      <Box
                        style={{
                          marginBottom: "30px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          alignItems: "center"
                        }}
                        onClick={() => {
                          this.setState({ blogTabValue: index });
                        }}
                      >
                        <img
                          src={blog_Data.attributes.image}
                          width="48"
                          height="48"
                          style={{
                            borderRadius: "50%"
                          }}
                        />
                        <Typography className="homePageBlogTabExample">
                          {blog_Data.attributes.title.substring(0, 40) + "..."}
                        </Typography>
                      </Box>
                      <Box
                        className={`${this.state.blogTabValue === index &&
                          "blogTabGridBorder"}`}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={3} lg={3} md={3} style={{ textAlign: "end" }}>
                    <Button
                      variant="contained"
                      className="viewallBtn"
                      disableRipple
                      href="/BlogHome"
                    >
                      <Box className="viewallBtnBox">
                        <Typography className="viewallBtnTxt">
                          View all
                        </Typography>
                        <ArrowForwardIcon className="arrowForwardIcon" />
                      </Box>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box style={{ marginTop: "337px" }}>
          <Grid container>
            <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>
              <Box className="toGetDiv">
                <Box>
                  <Box
                    style={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      letterSpacing: 1.5,
                      fontSize: "34px",
                      marginBottom: "18px"
                    }}
                  >
                    {homeData?.attributes?.main_text}
                  </Box>
                  <Box className="toGetdivSubText">
                    {homeData?.attributes?.sub_main_text}
                  </Box>
                  <Box className="calltoaction_bttn">
                    <Box>
                      <Button
                        variant="contained"
                        className="homePageBookNowBtn"
                        disableRipple
                        style={{ width: "230px" }}
                        href="/ChartererReservation"
                      >
                        <Box className="bookNowBtnBox">
                          <Typography className="BookBtnTxt">
                            I’m a Charterer
                          </Typography>
                          <CallMadeIcon className="callMadeIcon" />
                        </Box>
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        className="homePageBookNowBtn"
                        disableRipple
                        style={{ width: "230px" }}
                        href="/OperatorReservationForm"
                      >
                        <Box className="bookNowBtnBox">
                          <Typography className="BookBtnTxt">
                            I’m an Operator
                          </Typography>
                          <CallMadeIcon className="callMadeIcon" />
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <FooterHomePageWeb />
      </Box>
    );
  }
}
// Customizable Area End
