export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const verified = require("../assets/verified.png");
export const watermark = require("../assets/watermark.png");
export const watermarkTribe = require("../assets/Watermark_Tribe.png");
export const backArrow = require("../assets/backarrow.png");
export const caution = require("../assets/caution.png");
export const resend = require("../assets/resend.png");
export const resend_disabled = require("../assets/image_PathCopy.png");
export const congratulations_log_in = require("../assets/congratulations_log_in.png");
export const congratulations_sign_up = require("../assets/congratulations_sign_up.png");