// Customizable Area Start
import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { containerImg }
  from "../assets";


export default function OperatorsReasonsComponent(props: any) {


  return (
    <>
      <div className="homePageTextLabel">
        <div>{props?.data?.heading2}</div>
        <div>{props?.data?.sub_heading2}</div>
        <div className="homePageTextLabel1">{props?.data?.heading2_text}</div>
      </div>
      <Grid container>
        <Grid md={6} lg={6} sm={6}>
          <div style={{ position: "relative", marginTop: '32px' }}>
            <div className='frame' style={{ zIndex: -1 }}></div>
            <div className='ReasonTextHeadingHome'>
              &nbsp;&nbsp;&nbsp;&nbsp;{props?.data?.reason_point1_heading}</div>
            <div className='ReasonTextHome'>{props?.data?.reason_point1_subheading}</div>
          </div>

          <div style={{ position: "relative", marginTop: '56px' }}>
            <div className='frame' style={{ zIndex: -1 }}></div>
            <div className='ReasonTextHeadingHome'>
              &nbsp;&nbsp;&nbsp;&nbsp;{props?.data?.reason_point2_heading}</div>
            <div className='ReasonTextHome'>{props?.data?.reason_point2_subheading}</div>
          </div>

          <div style={{ position: "relative", marginTop: '56px' }}>
            <div className='frame' style={{ zIndex: -1 }}></div>
            <div className='ReasonTextHeadingHome'>
              &nbsp;&nbsp;&nbsp;&nbsp;{props?.data?.reason_point3_heading}</div>
            <div className='ReasonTextHome'>{props?.data?.reason_point3_subheading}</div>
          </div>

          <div>
            <Button
              variant="contained"
              className="homePageGetStartedBtn"
              disableRipple
              href='/LandingPage/Signup'
              style={{ marginTop: '72px' }}
            >
              <Box className="getStartedBtnBox">
                <Typography className="getStartedBtnTxt" style={{ fontFamily: 'Playfair Display' }}>
                  Get started
                </Typography>
                <ArrowForwardIcon className="arrowForwardIcon" />
              </Box>
            </Button>
          </div>
        </Grid>
        <Grid md={6} lg={6} sm={12} style={{ position: 'relative' }}>
          <img src={containerImg} style={{ borderRadius: "10px", width: '100%', objectFit: 'fill', position: 'relative', left: '7%',maxHeight:'90%' }}></img>
        </Grid>
      </Grid >

    </>
  );
}
// Customizable Area End
