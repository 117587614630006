// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Blog {
  id: number,
  type: string,
  attributes: {
    content: string,
    title: string,
    image: string
  }  
}

interface S {
  // Customizable Area Start
  radioButton: string;
  boxTabValue: number;
  mainTabValue: number;
  blogTabValue: number;
  homeData:any;
  blogs : Array<Blog>
  blogTitle_1: string;
  blogImage_1: string;
  blogTitle_2: string;
  blogImage_2: string;
  blogTitle_3: string;  
  blogImage_3: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  homeScreenApiCallId:any 
  apiBlogsHomeScreen: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      radioButton: "",
      boxTabValue: 0,
      mainTabValue: 0,
      blogTabValue: 0,
      homeData :{},
      blogs: [],
      blogTitle_1: "",
      blogImage_1: "",
      blogTitle_2: "",
      blogImage_2: "",
      blogTitle_3: "",
      blogImage_3: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if(apiRequestCallId && responseJson){
        if(apiRequestCallId === this.homeScreenApiCallId){
          if(responseJson.data){
            return this.setState({homeData:responseJson?.data[0]})
          }
        }
      }

      if (apiRequestCallId === this.apiBlogsHomeScreen) {
        if (responseJson && responseJson.data) {
          const json_data = responseJson.data;
          const slicedArray = json_data.slice(0, 3);
          this.setState(
            {
              blogs : slicedArray
            }
          )
          try {
            this.setState({
              blogTitle_1: slicedArray[0].attributes.title,
              blogImage_1: slicedArray[0].attributes.image
            })
            this.setState({
              blogTitle_2: slicedArray[1].attributes.title,
              blogImage_2: slicedArray[1].attributes.image
            })
            this.setState({
              blogTitle_3: slicedArray[2].attributes.title,
              blogImage_3: slicedArray[2].attributes.image
            })
          } catch (error) {
            
          }
        }
      } else {
        // alert("Something went wrong Blog")

      }


    }
    // Customizable Area End
  }

  // Customizable Area Start


  handleChangeBoxTab = (val: any) => {
    this.setState({
      boxTabValue: val
    })
  }

  handleChangeMainTab = (val: any) => {
    this.setState({
      mainTabValue: val
    })
  }

  async componentDidMount() {
    this.getHomeDataLoadApi()  
    this.getBlogsInfo()
  }

  getHomeDataLoadApi = ()=> {

    const header = {
      "Content-Type": 'application/json'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.homeScreenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_landingpage/landingpages'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getBlogsInfo = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBlogsHomeScreen = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homeChartererBlogs
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}

// Customizable Area End