Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions";
exports.labelBodyText = "TermsAndConditions Body";

exports.getTermsAndConditions ="bx_block_privacy_settings/terms_and_conditions";
exports.getPrivacyPolicy ="bx_block_privacy_settings/privacy_policies";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End