// Customizable Area Start

import * as React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import {
  createTheme,
  ThemeProvider,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import ChartererLoginFormController, {
  Props,
} from "./ChartererLoginFormController.web";
import Select from "react-select";
import "./ChartererLoginForm.web.css";
import {
  backArrow,
  watermark,
  resend,
  caution,
  resend_disabled,
} from "../assets";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    color: "white",
    background: "#504857",
    padding: "10px",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    border: "white",
    fontSize: 16,
    height: "57px",
    width: "120px",
    // Removes weird border around container
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "red" : "blue",
    },
  }),
  input: (base: any) => ({
    ...base,
    color: "white",
    background: "#504857",
  }),
  menu: (base: any) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    color: "#FFFFFF",
  }),
  menuList: (base: any) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    background: "#504857",
    color: "#FFFFFF",
  }),
  option: (base: any) => ({
    ...base,
    color: "#FFFFFF",
    background: "#504857",
    display: "flex",
    alignItems: "center",
    justifyContents: "space-around",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#FFFFFF",
  }),
};
export const Svg = (p: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
export const BootstrapNumberInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      color: "#fcf6f0",
      position: "relative",
      backgroundColor: "#504857",
      border: "white",
      fontSize: 16,
      padding: "20px 20px 20px 10px",
      marginTop: "10px",
      width: "100%",
      // Use the system font instead of the default Roboto font.
      fontFamily: "Libre Franklin, sans-serif",

      "&:focus": {
        borderColor: "white",
      },
    },
  })
)(InputBase);
export const Menu = (props: any) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};

export const Blanket = (props: any) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
export const Dropdown = ({ children, isOpen, target, onClose }: any) => (
  <div
    style={{
      color: "white",
      background: "#504857",
      // match with the menu
      borderRadius: "3px 3px 0 0",
      // Overwrittes the different states of border
      border: "white",
      fontSize: 16,
      height: "59px",
      width: "40px",
      marginTop: "10px",
    }}
  >
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export const DropdownIndicator = () => (
  <div>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      color: "#fcf6f0",
      position: "relative",
      backgroundColor: "#504857",
      border: "white",
      fontSize: 16,
      width: "100%",
      height: "40px",
      padding: "8px 13px",
      marginTop: "10px",
      // Use the system font instead of the default Roboto font.
      fontFamily: "Libre Franklin, sans-serif",
      "&:focus": {
        borderColor: "white",
      },
    },
  })
)(InputBase);
const theme = createTheme();
// Customizable Area End
export function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const handlePrivacyChecked = (privacy: any) => {
  let privacyChecked = "checkfields";
  if (privacy) {
    privacyChecked = "checkfieldstrue";
  }
  return privacyChecked;
};
const handleOtperrorBackground = (err: any) => {
  let background = "#150a1f";
  if (err) {
    background = "#504857";
  }
  return background;
};
const handleOtperror = (err: any) => {
  let error = "login_otp_input_props";
  if (err) {
    error = "login_otp_input_props_error";
  }
  return error;
};
export default class ChartererLoginForm extends ChartererLoginFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { formNo, emailId, loginType } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ marginTop: "119px" }}>
          <div className="setBackgroundTribeLogo">

          <Grid
            item
            xs={8}
            style={{ margin: "auto" }}
          >
            {/* First screen element  */}
            {formNo === "0" && (
              <Box className="chartererLoginFormBox1">
                <Grid container spacing={1} className="firstFormGrid1">
                  <Grid item xs={12} sm={12}>
                    <Typography className="loginOperator">
                      Log In as a Charterer
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography className="fleetFont">
                      Get the full experience by Logging In
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="loginTypeButtonGrid"
                  >
                    <Button
                      id="number-btn"
                      disableRipple={true}
                      variant="contained"
                      className={
                        loginType === "number"
                          ? "loginOptionSelected"
                          : "loginOptionNotSelected"
                      }
                      onClick={() => this.handleChangeLoginType("number")}
                    >
                      Contact number
                    </Button>
                    <Button
                      id="email-btn"
                      disableRipple={true}
                      variant="contained"
                      className={
                        loginType === "email"
                          ? "loginOptionSelected"
                          : "loginOptionNotSelected"
                      }
                      onClick={() => this.handleChangeLoginType("email")}
                    >
                      Email
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={7} lg={5} style={{ margin: "auto" }}>
                    {loginType === "number" ? (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              className="inputLabelForms"
                              style={{
                                fontFamily: "Playfair Display",
                              }}
                            >
                              Contact number
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item container>
                              <Grid item lg={3} md={3} xs={3} sm={3}>
                                <Box className="customFlag_container">
                                  <Dropdown
                                    isOpen={this.state.isOpen}
                                    data-testid="close_dropdown"
                                    onClose={() =>
                                      this.setState({
                                        isOpen: false,
                                      })
                                    }
                                    target={
                                      <Button
                                        variant="contained"
                                        data-testid="dropdown-btn-countrycode"
                                        style={{
                                          color: "white",
                                          background: "#504857",
                                          borderRadius: "3px 3px 0 0",
                                          border: "white",
                                          fontSize: 16,
                                          height: "59px",
                                          width: "136px",
                                          justifyContent: "left",
                                          padding: "0px 10px",
                                        }}
                                        disableRipple={true}
                                        endIcon={<ExpandMoreOutlinedIcon />}
                                        onClick={() =>
                                          this.setState({
                                            isOpen: true,
                                          })
                                        }
                                      >
                                        {this.state.selectedCountry
                                          ? this.state.selectedCountry.label
                                          : "Select"}
                                      </Button>
                                    }
                                  >
                                    <Select
                                      autoFocus
                                      backspaceRemovesValue={false}
                                      components={{
                                        DropdownIndicator,
                                        IndicatorSeparator: null,
                                      }}
                                      controlShouldRenderValue={false}
                                      hideSelectedOptions={false}
                                      data-testid="country-check"
                                      isClearable={false}
                                      placeholder="Search..."
                                      menuIsOpen
                                      tabSelectsValue={false}
                                      styles={customStyles}
                                      options={this.state.dataSource}
                                      value={this.state.selectedCountry}
                                      onChange={(e) => {
                                        this.handleChange(e);
                                        this.setState({
                                          isOpen: false,
                                        });
                                      }}
                                    />
                                  </Dropdown>
                                </Box>
                              </Grid>
                              <Grid item lg={9} md={9} xs={9} sm={9}>
                                <Box className="numberInput">
                                  <Box>
                                    <div className="verticalLines">
                                      <div className="verticalLine2" />
                                    </div>
                                  </Box>
                                  <Box style={{ width: "100%" }}>
                                    <BootstrapNumberInput
                                      placeholder="Enter your contact number"
                                      autoComplete="given-name"
                                      data-testid="phone-check"
                                      name="contactNumber"
                                      fullWidth
                                      id="contactNumber"
                                      value={this.state.full_phone_number}
                                      style={{ width: "94%" }}
                                      onChange={(
                                        event: React.ChangeEvent<
                                          HTMLInputElement
                                        >
                                      ) => this.phoneFieldOnchange(event)}
                                      onBlur={() => this.phoneFieldOnBlur()}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            {this.state.full_phone_numberError && (
                              <Grid item xs={12}>
                                <FormControl className="widthSpec">
                                  <FormHelperText className="characterHelperTextFieldImage">
                                    <img
                                      src={caution}
                                      width="16px"
                                      height="16px"
                                    />{" "}
                                    <FormHelperText className="helperTextFieldText">
                                      {this.state.full_phone_numbererrorMes}
                                    </FormHelperText>
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              className="inputLabelForms"
                              style={{
                                fontFamily: "PlayfairDisplay",
                              }}
                            >
                              Email
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <BootstrapInput
                              data-testid="email-check"
                              placeholder="Enter registered company email address"
                              name="companyAddress"
                              fullWidth
                              id="companyAddress"
                              autoFocus
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => this.emailFieldOnchange(event)}
                              onBlur={() => this.emailFieldOnBlur()}
                            />
                          </Grid>
                          {this.state.emailIdError && (
                            <Grid item xs={12}>
                              <FormControl className="widthSpec">
                                <FormHelperText className="characterHelperTextFieldImage">
                                  <img
                                    src={caution}
                                    width="16px"
                                    height="16px"
                                  />{" "}
                                  <FormHelperText className="helperTextFieldText">
                                    {this.state.emailIdErrorMes}
                                  </FormHelperText>
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs = {3} lg={3} md={3} sm={3}></Grid>
                  <Grid item xs = {7} lg={7} md={7} sm={7}  style={{maxWidth: "43%", marginLeft: "40px"}}>
                    <Grid className="charterCheckBoxGrid">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          checked={this.state.privacyChecked}
                          className={
                            this.state.privacyChecked
                              ? "checkfieldtrue"
                              : "checkfield"
                          }
                          onChange={this.handleTandPChecker}
                          onBlur={() => {
                            if (this.state.privacyChecked === false) {
                              this.handlePrivacyErrorMes();
                            } else {
                              this.handlePrivacyError();
                            }
                          }}
                        />
                        {/* Terms and Privacy section Start */}
                        <Typography className="checkboxlLabelLoggin">
                          By logging in, I understand & agree to Tribe's{" "}
                          <span
                            data-testid="term-btn"
                            className="terms_and_privacys"
                            style={{ color: "#f2dcc4" }}
                            onClick={this.handleTermOpen}
                          >
                            terms of use
                          </span>{" "}
                          and{" "}
                          <span
                            className="terms_and_privacys"
                            style={{ color: "#f2dcc4" }}
                            onClick={this.handlePrivacyOpen}
                          >
                            privacy policy.
                          </span>
                        </Typography>
                        {/* Terms and Privacy section End */}
                      </div>                      
                    </Grid>
                    {this.state.privacyError && (
                      <Grid>
                        <FormHelperText className="helperTextFieldImageCheckErrorCharterer">
                          <img src={caution} width="18px" height="18px" />{" "}
                          <FormHelperText className="helperTextFieldText">
                            {this.state.privacyErrorMes}
                          </FormHelperText>
                        </FormHelperText>
                      </Grid>
                      )}
                    
                  </Grid>
                  <Grid item xs = {2} lg={2} md={2} sm={2}></Grid>
                
                </Grid>
                  
                
                
                <Grid container>
                  <Grid
                    style={{ margin: "auto" }}
                    className="EmailLoginOTPTextFieldDivBtn"
                  >
                    
                    <Button
                      data-testid="send-otp-btn"
                      onClick={(e: any) => this.handleSubmit(e)}
                      disableRipple={true}
                      variant="contained"
                      style={{ textTransform: "none" }}
                      className="sendOtpButton"
                      disabled={
                        this.state.privacyChecked === false &&
                        (this.state.emailIdError ||
                          this.state.full_phone_numberError ||
                          this.state.full_phone_number === "" ||
                          this.state.emailId === "")
                      }
                    >
                      Send OTP
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginTop: "40px", marginBottom: "40px" }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item className="terms">
                      Don't have an account?{" "}
                      <Link href="/ChartererReservation" variant="body2">
                        {" "}
                        <span className="termsOfUseText"> Sign Up </span>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* Second screen element  */}
            {formNo === "1" && (
              <>
              <Box className="firstFormBox1">
                <Grid
                  container
                  spacing={1}
                  className="OperatorLoginFirstFormGrid1"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    style={{ margin: "auto", position: "relative" }}
                  >
                    <img
                      onClick={this.handleForm0}
                      src={backArrow}
                      width="30px"
                      height="20px"
                      style={{
                        width: "30px",
                        height: "20px",
                        position: "absolute",
                        left: "0%",
                        top: "30%",
                      }}
                    />
                    <Typography className="loginOperator">
                      OTP Verification
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} style={{margin: "auto"}}>
                    <Typography className="fleetFont">
                      {this.state.resendEnabled ? (
                        <>Verification code sent to</>
                      ) : (
                        <>A new OTP has been sent successfully to</>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} className="verficationCodegrid">
                    <Typography
                      component="h1"
                      className="verficationOTPCodetext"
                    >
                      {loginType === "number" ? (
                        <>
                          +{this.state.selectedCountry.value}{" "}
                          {this.state.full_phone_number}
                        </>
                      ) : (
                        this.state.emailId
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ margin: "auto" }}
                  >
                    <Box
                      component="form"
                      // onSubmit={this.handleVerify}
                      style={{ width: "100%" }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} className="displayContents">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{
                              marginTop: "100px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography className="fleetFont">
                              <span style={{color: "white"}}>Enter the OTP</span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className="EmailLoginOTPTextFieldDiv"
                        >
                          <Grid
                            container
                            spacing={4}
                            className="signupForm"
                            // style={{
                            //   margin: "auto",
                            //   display: "flex",
                            //   justifyContent: "center",
                            // }}
                          >
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                
                                autoFocus={true}
                                type="tel"
                                placeholder="_"
                                name="otp1"
                                value={this.state.otp1}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange1}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props",
                                }}
                                onInput={this.handleOTPInputChekcer}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp1
                                      ? "#504857"
                                      : "#150a1f",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp2-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                
                                type="tel"
                                name="otp2"
                                placeholder="_"
                                value={this.state.otp2}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange2}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props",
                                }}
                                onInput={this.handleOTPInputChekcer}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp2
                                      ? "#504857"
                                      : "#150a1f",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp3-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                                                
                                type="tel"
                                name="otp3"
                                placeholder="_"
                                value={this.state.otp3}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange3}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props",
                                }}
                                onInput={this.handleOTPInputChekcer}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp3
                                      ? "#504857"
                                      : "#150a1f",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                              <TextField
                                data-testid="otp4-check"
                                id="standard-basic"
                                className="EmailChartersignupOtpForrmInputTextfield"
                                variant="outlined"                                
                                type="tel"
                                name="otp4"
                                placeholder="_"
                                value={this.state.otp4}
                                error={this.state.otperror}
                                onPaste={this.handlePaster}
                                onChange={this.otpFieldOnChange4}
                                onInput={this.handleOTPInputChekcer}
                                inputProps={{
                                  className: this.state.otpError
                                    ? "signup_otp_input_props_error"
                                    : "signup_otp_input_props",
                                }}
                                InputProps={{
                                  style: {
                                    backgroundColor: this.state.otp4
                                      ? "#504857"
                                      : "#150a1f",
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {this.state.otpError && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{ margin: "auto" }}
                          >
                            <FormHelperText className="helperTextFieldOTPImage">
                              <img src={caution} width="18px" height="18px" />{" "}
                              <FormHelperText className="helperTextFieldText">
                                {this.state.otpErrorMes}
                              </FormHelperText>
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                        {/* Resend OTP Section start */}
                        <Box className="OperatorLoginResendBox">
                            <Grid container spacing={4} className="signupForm">
                              <Grid item xs={6} sm={6} md={6} lg={6} style={{margin: "auto"}}>
                                {this.state.resendEnabled ? (
                                  <Typography className="ChartererLoginResendBoxFirstText">
                                    Didn't receive OTP?
                                  </Typography>
                                ) : (
                                  <Typography className="ChartererLoginResendBoxFirstTextValid">
                                    Valid for 10:00
                                  </Typography>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {this.state.resendEnabled ? (
                                  <>
                                    <img src={resend} className="resend-img" />{" "}
                                    <span
                                      className="resend_OTP"
                                      onClick={(e: any) => {
                                        this.handleResendClick(e);
                                      }}
                                    >
                                      Resend OTP
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={resend_disabled}
                                      className="resend-img"
                                    />{" "}
                                    <span className="resend_OTP_Disabled">
                                      Resend OTP in 00:{this.state.timeLeft}{" "}
                                    </span>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          {/* </Grid> */}
                        </Box>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className="EmailLoginOTPTextFieldDivVerifyBTN"
                        >
                          <Button
                            data-testid="verify-btn"
                            // type="submit"
                            variant="contained"
                            className="OperatorLoginVerifyButton"
                            style={{ textTransform: "none" }}
                            onClick={(e: any) => this.handleVerify(e)}
                            disabled={this.state.otpVerifyDisabled}
                            disableRipple
                          >
                            Verify and continue
                          </Button>
                        </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>              
                </>
            )}

            {/* Success Modal */}
            <Dialog
              fullScreen
              PaperProps={{
                style: {
                  backgroundColor: "#150a1f",
                },
              }}
              data-testid="sucess-modal-check"
              onClick={() => this.handleModalcheck}
              aria-labelledby="customized-dialog-title"
              open={this.state.successOpen}
            >
              <>
                <div className="watermarkVerified">
                  <img src={watermark} width="100%" height="250px" />
                </div>

                <div style={{ marginTop: "280px" }}>
                  <Typography className="textlabel_CongratulationsYou">
                    <Typography className="text-style-1">
                      Congratulations!
                    </Typography>
                    You have been successfully verified
                  </Typography>
                  <a href="/HomeScreenCharterer">
                    <Typography className="textlabel_LoggingYouIn">
                      Logging you in...
                    </Typography>
                  </a>
                </div>
              </>
            </Dialog>

            {/* terms of use modal */}
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "#f2dcc4",
                  minHeight: "80vh",
                  minWidth: "80vh",
                },
              }}
              aria-labelledby="customized-dialog-title"
              open={this.state.termsOpen}
            >
              <DialogContent className="modalBody">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.terms_and_conditions,
                  }}
                />
              </DialogContent>
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "20px",
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FormControlLabel
                      data-testid="formdetails3"
                      control={
                        <Checkbox
                          data-testid="term-checkbox"
                          checked={this.state.privacyChecked}
                          style={{ color: "black" }}
                          onChange={this.handleTandPChecker}
                        />
                      }
                      label={
                        <Typography className="emailLabelModal">
                          By accepting, I agree to the above terms of use and
                          privacy policy.{" "}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="modalButtonContainer">
                    <Button
                      data-testid="terms-decline-btn"
                      disableRipple={true}
                      variant="contained"
                      className="modalButton"
                      onClick={() =>
                        this.setState({
                          termsOpen: false,
                          privacyChecked: false,
                          privacyError: true,
                          privacyErrorMes:
                            "Please accept the Tribe's terms of use and privacy policy before continuing",
                        })
                      }
                    >
                      Decline
                    </Button>
                    <Button
                      data-testid="terms-accept-btn"
                      variant="contained"
                      disableRipple={true}
                      className="modalButtonAccept"
                      onClick={() =>
                        this.setState({
                          privacyChecked: true,
                          termsOpen: false,
                          privacyError: false,
                          privacyErrorMes: "",
                        })
                      }
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>

            {/* privacy modal */}
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "#f2dcc4",
                  minHeight: "80vh",
                  minWidth: "80vh",
                },
              }}
              aria-labelledby="customized-dialog-title"
              open={this.state.privacyOpen}
            >
              <DialogContent className="modalBody">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.privacy_policy,
                  }}
                />
              </DialogContent>
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "20px",
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FormControlLabel
                      data-testid="formdetails2"
                      control={
                        <Checkbox
                          checked={this.state.privacyChecked}
                          style={{ color: "black" }}
                          onChange={this.handleTandPChecker}
                        />
                      }
                      label={
                        <Typography className="emailLabelModal">
                          By accepting, I agree to the above terms of use and
                          privacy policy.{" "}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="modalButtonContainer">
                    <Button
                      data-testid="privacy-decline-btn"
                      disableRipple={true}
                      variant="contained"
                      className="modalButton"
                      onClick={this.handlePrivacyDecline}
                    >
                      Decline
                    </Button>
                    <Button
                      data-testid="privacy-accept-btn"
                      disableRipple={true}
                      variant="contained"
                      className="modalButtonAccept"
                      onClick={this.handlePrivacyAccept}
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          </Grid>
          </div>
        </Grid>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
