import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  
  // Customizable Area Start

  tablet_section_1_heading: string,
  tablet_section_1_description: string,
  tablet_section_1_passengers: string,
  tablet_section_1_max_range: string,
  tablet_section_1_speed: string,
  tablet_section_2_heading: string,
  tablet_section_2_description: string,
  tablet_section_2_passengers: string,
  tablet_section_2_max_range: string,
  tablet_section_2_speed: string,  
  tablet_section_3_heading: string,
  tablet_section_3_description: string,
  tablet_section_3_passengers: string,
  tablet_section_3_max_range: string,
  tablet_section_3_speed: string,

  
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  lightJetFirstImage: number;
  lightJetSecondImage: number;
  lightJetThirdImage: number;
  
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      lightJetFirstImage: 0,
      lightJetSecondImage: 0,
      lightJetThirdImage: 0,
      };


    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
