// Customizable Area Start
import * as React from "react";
import BlogHomeController, { Props } from "./blogHomeController.web";
import FooterHomePageWeb from "../../../landingpage/src/FooterHomePage/FooterHomePage.web";
import "./blogHome.web.css"
import Grid from "@material-ui/core/Grid";
import RightBlog from "./rightBlog.web";
import LeftBlog from "./leftBlog.web";

export default class BlogHome extends BlogHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <>
        <Grid container style={{ marginTop: "150px" }}>
          <Grid item xs={10} lg={10} md={10} style={{ margin: "auto" }}>        

          {this.state.blogs ?
            (
              <>
                {this.state.blogs.map((item, index) => {
                  let index_val = false
                  if (index === this.state.last_index_val - 1) {
                    index_val = true
                  }
                  if (index % 2 != 0) {
                    return <RightBlog
                      title={item.attributes.title}
                      description={item.attributes.content.substring(0, 300) + "..."}
                      image={item.attributes.image}
                      last_index={index_val}
                      id={"/BlogDetail/" + item.id}
                    />
                  }
                  else {
                    return <LeftBlog
                      title={item.attributes.title}
                      description={item.attributes.content.substring(0, 300) + "..."}
                      image={item.attributes.image}
                      last_index={index_val}
                      id={"/BlogDetail/" + item.id}
                    />
                  }

                })}
              </>
            ) :
            (<></>)}

          </Grid>
        </Grid>

        <div style={{ marginTop: '100px' }}></div>

        <FooterHomePageWeb />
      </>

    )
    // Customizable Area End
  }
}
